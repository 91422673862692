import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Progress, QRCode } from "antd";
import { motion as m, AnimatePresence } from "framer-motion";
import { useTranslation } from "react-i18next";

import Header2 from "../../components/Header2";
import LocationIcon from "../../assets/svg/location.svg";
import BarCodeMini from "../../assets/svg/barcode_min.svg";

import Sparkles from "../../assets/sparkles.svg";
import ChartNetwork from "../../assets/chart-network.svg";
import Catalog from "../../assets/svg/catalog.svg";
// import useSelection from "antd/es/table/hooks/useSelection";
import { useSelector } from "react-redux";
import Barcode from "react-barcode";

export default function MembershipScan() {
  const { t } = useTranslation();
  const [showScanner, setShowScanner] = useState(true);
  const navigate = useNavigate();
  const [progress, setProgress] = useState(0);
  const cardDetails = [
    { title: t("cardDetails.rewards"), image: LocationIcon },
    { title: t("cardDetails.transactions"), image: Sparkles },
    { title: t("cardDetails.benefits"), image: ChartNetwork },
    { title: t("cardDetails.catalog"), image: Catalog },
  ];
  const customerData = useSelector(
    (store) => store?.customerDetails?.customerData
  );
  useEffect(() => {
    if (customerData?.current_slab === "Diamond") {
      setProgress(100);
    } else {
      const currentPoints =
        customerData?.points_summaries?.points_summary?.[0]?.gap_to_upgrade
          ?.upgrade_strategy?.[0]?.customer_upgrade_entity_values
          ?.current_value;
      const thresholdValue =
        customerData?.points_summaries?.points_summary?.[0]?.gap_to_upgrade
          ?.upgrade_strategy?.[0]?.upgrade_threshold;
      const prog = (Number(currentPoints) / Number(thresholdValue)) * 100;
      setProgress(prog);
    }
  }, [customerData]);
  const pointSummery = () => {
    let sInfo = customerData?.points_summaries?.points_summary ?? [];
    if (sInfo?.length > 0) {
      return sInfo[0];
    }
    return {};
  };
  // const calculatePercentage = (currentPoints, totalPoints) => {
  //   if (totalPoints === 0) {
  //     return "Total points cannot be zero";
  //   }
  //   let percentage = (currentPoints / totalPoints) * 100;
  //   return percentage?.toFixed(2); // returning percentage with 2 decimal places
  // };
  const convLoyaltyPoints = () => {
    let pSumery = pointSummery();
    let conversionRate = pSumery?.program_points_to_currency_ratio || 1;
    let amountInRupees = pSumery?.loyaltyPoints;
    const amountInOtherCurrency = amountInRupees * conversionRate;
    return amountInOtherCurrency;
  };
  // useEffect(() => {
  //   let usr_loyalty_points = parseInt(customerData?.loyalty_points) || 0;
  //   let min_purchase_value_required =
  //     parseInt(
  //       customerData?.tier_update_criteria?.min_purchase_value_required
  //     ) || 0;

  //   let per = calculatePercentage(
  //     usr_loyalty_points,
  //     min_purchase_value_required
  //   );
  //   let cal_progress = parseInt(per) > 0 ? parseInt(per) : 0;
  //   setProgress(cal_progress);
  // }, []);
  const convEarnedPoints = () => {
    let pSumery = pointSummery();
    let conversionRate = pSumery?.program_points_to_currency_ratio || 1;
    let amountInRupees = pSumery?.lifetimePoints;
    const amountInOtherCurrency = amountInRupees * conversionRate;
    return amountInOtherCurrency;
  };
  const convSpentPoints = () => {
    let pSumery = pointSummery();
    let amountInRupees = pSumery?.redeemed;
    let conversionRate = pSumery?.program_points_to_currency_ratio || 1;
    const amountInOtherCurrency = amountInRupees * conversionRate;
    return amountInOtherCurrency;
  };
  const handleClick = (index) => {
    if (index === 0) navigate("/rewards-list");
    else if (index === 1) navigate("/points-history");
    else if (index === 2) navigate("/your-offers");
    else if (index === 3) navigate("/rewards-catalog");
  };
  return (
    <>
      <Header2 />
      <div className="p-2">
        <m.div
          initial={{ height: "240px" }}
          animate={{ height: "auto" }}
          className="bg-valiram2 rounded-lg p-4 pl-6 pb-16 overflow-hidden"
        >
          <div className="flex justify-between">
            <div>
              <h6 className="playfair-bold text-white text-lg mb-2">
                {t("loyaltyCard.one")}
              </h6>
              <h1 className="playfair-bold text-white text-5xl">
                V${convLoyaltyPoints()}
              </h1>
            </div>
            <div>
              <Button shape="round" className="text-[#707070] italic w-32 h-9">
                {customerData?.current_slab}
              </Button>
            </div>
          </div>
          <div className="flex justify-start gap-16 py-5">
            <h5 className="playfair text-white text-sm">
              {t("loyaltyCard.three", {
                earnedPoints: convEarnedPoints(),
              })}
            </h5>
            {/* <h5 className="playfair text-[#A7A7A7] text-sm">
                {t("loyaltyCard.four", { floatPoints: 1.25 })}
              </h5> */}

            <h5 className="playfair text-white text-sm">
              {t("loyaltyCard.five", {
                spendPoints: convSpentPoints(),
              })}
            </h5>
          </div>
          <div>
            <div>
              <Progress
                percent={progress}
                showInfo={false}
                strokeColor="#CB8500"
                trailColor="#ffffff"
              />
            </div>
            <div className="flex justify-end">
              <h6 className="playfair text-white text-xs italic">
                {customerData?.points_summaries?.points_summary[0]
                  ?.currentSlab === "Diamond" ? (
                  <h6 className="playfair text-white text-xs italic">
                    You have reached the upper slab!
                  </h6>
                ) : (
                  `Spend RM${
                    customerData?.tier_update_criteria
                      ?.min_purchase_value_required || 0
                  } to ${
                    customerData?.points_summaries?.points_summary[0]
                      ?.nextSlab || ""
                  }  Member Upgrade!`
                )}
              </h6>
            </div>
          </div>
          <AnimatePresence mode="wait">
            <div className="flex justify-center mt-6 h-[20vh] items-center">
              {showScanner && (
                // <m.img
                //   initial={{ x: "100%" }}
                //   animate={{ x: "0%" }}
                //   exit={{ x: "200%" }}
                //   src={Scanner}
                //   alt="Scanner"
                //   className="w-6/12"
                // />
                <QRCode
                  value={customerData?.mobile}
                  style={{ backgroundColor: "white" }}
                />
              )}
              {!showScanner && (
                <Barcode
                  width={2}
                  height={70}
                  value={customerData && customerData?.mobile}
                ></Barcode>
              )}
            </div>
          </AnimatePresence>
          <div className="text-center flex flex-col items-center mt-6 gap-1">
            <img
              src={BarCodeMini}
              alt="small bar code"
              onClick={() => setShowScanner((prev) => !prev)}
            />
            <p className="obviously text-white">{customerData?.mobile}</p>
          </div>

          <div className="flex flex-wrap gap-4 text-white justify-center my-6">
            {cardDetails?.map((btn, index) => (
              <div
                key={index}
                className="w-[47%] h-24 rounded-xl flex items-end justify-between p-3"
                style={{
                  backgroundImage:
                    "linear-gradient(rgb(3 72 153 / 69%), rgb(39 101 170 / 48%))",
                }}
                onClick={() => handleClick(index)}
              >
                <img src={btn?.image} alt="Sparkles icon" width={20} />

                <p className="obviously text-sm">{btn?.title}</p>
              </div>
            ))}
          </div>
        </m.div>
      </div>
    </>
  );
}
