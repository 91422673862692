import { createSlice } from "@reduxjs/toolkit";
import { merge, uniqBy } from "lodash";

const customerDetails = createSlice({
  name: "customerDetails",
  initialState: {
    customerData: {},
    offers: [],
    redeemData: [],
    vouchers: [],
    brandList: [],
    countryList: [],
  },
  reducers: {
    addCustomerData: (state, action) => {
      state.customerData = action.payload;
    },
    updateCustomerData: (state, action) => {
      const incomingData = action.payload;

      state.customerData = merge({}, state.customerData, incomingData);

      if (state.customerData.custom_fields?.field) {
        state.customerData.custom_fields.field = uniqBy(
          state.customerData.custom_fields.field,
          "name"
        );
      }
    },
    addOffers: (state, action) => {
      state.offers = action.payload;
    },
    addRedeemData: (state, action) => {
      state.redeemData = action.payload;
    },
    addVouchers: (state, action) => {
      state.vouchers = action.payload;
    },
    addBrandList: (state, action) => {
      state.brandList = action.payload;
    },
    addCountryList: (state, action) => {
      state.countryList = action.payload;
    },
  },
});

export const {
  addCustomerData,
  addOffers,
  addRedeemData,
  addVouchers,
  addBrandList,
  updateCustomerData,
  addCountryList,
} = customerDetails.actions;
export default customerDetails.reducer;
