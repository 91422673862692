import React, { useState } from "react";

const OTPInput = ({ onOtpSubmit, setError }) => {
  const [otp, setOtp] = useState(Array(6).fill(""));

  const handleChange = (index, value) => {
    setError("");
    if (isNaN(value - "1")) return;
    const newOtp = [...otp];
    newOtp[index] = value.replace(/[^0-9]/g, "");
    setOtp(newOtp);
    if (value && index < 5) {
      document.getElementById(`otp${index + 1}`).focus();
    }
    const combinedOtp = newOtp.join("");
    if (combinedOtp.length === 6) {
      onOtpSubmit(combinedOtp);
    }
  };

  const handleBackspace = (index, event) => {
    if (event.key === "Backspace" && !otp[index]) {
      if (index > 0) {
        const prevInput = document.getElementById(`otp${index - 1}`);
        prevInput.focus();
      }
    }
  };

  return (
    <div
      className="otp-input"
      style={{
        display: "flex",
        justifyContent: "center",
        padding: "10px",
        width: "70vmin",
      }}
    >
      {Array.from({ length: 6 }).map((_, index) => (
        <input
          key={index}
          type="text"
          inputMode="numeric"
          pattern="[0-9]*"
          id={`otp${index}`}
          maxLength={1}
          value={otp[index]}
          onChange={(e) => handleChange(index, e.target.value)}
          onKeyUp={(e) => handleBackspace(index, e)}
          style={{
            fontSize: "20px",
            margin: "5px",
            width: "15px",
            textAlign: "center",
            border: "none",
            borderBottom: "2px solid #ccc",
            backgroundColor: "transparent",
            outline: "none",
          }}
          onFocus={(e) => (e.target.style.borderBottom = "2px solid #007BFF")}
          onBlur={(e) => (e.target.style.borderBottom = "2px solid #ccc")}
          autoComplete="off"
        />
      ))}
    </div>
  );
};

export default OTPInput;
