import axios from "axios";
import AuthServiceInstance from "./auth-service";

const apiWrapper = async (url, method, body = null, headers = {}) => {
  try {
    const options = {
      method,
      url: url,
      headers: headers,
      data: body,
    };
    // console.log("Request URL:", url);
    // console.log("Request Options:", options);
    const res = await axios(options);
    if (res?.data?.status?.code === 401) {
      const newTokenRes = await AuthServiceInstance.tokenRegenerate();

      if (newTokenRes.status.success) {
        const retryOptions = {
          method,
          url: url,
          headers: headers,
          data: body,
        };
        const retryRes = await axios(retryOptions);
        return retryRes.data;
      } else {
        console.error("Token refresh failed, logging out...");
        handleTokenRefreshFailure();
        return null;
      }
    }
    return res.data;
  } catch (error) {
    console.error("API call failed", error);
    throw error;
  }
};
const handleTokenRefreshFailure = () => {
  // Redirect user to login page
  window.location.href = "#/loginpage";
};

export default apiWrapper;
