import { Link, useNavigate } from "react-router-dom";
import { Spin } from "antd";
import { useTranslation } from "react-i18next";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

import { addCustomerData } from "../../redux/slices/customerDetails.js";

import "./styles.css";
import OTPInput from "../../components/OTPInput";
import useOtpTimer from "../../hooks/useOtpTimer";
import backgroundVideo from "../../assets/videos/background-video.mp4";
import CustomerService from "../../utils/customer-service.js";
import AuthService from "../../utils/auth-service.js";
import customerService from "../../utils/customer-service.js";
import backIcon from "../../assets/backIcon.svg";
import { toast } from "react-toastify";
import { useAuth } from "../../utils/AuthProvider.js";

// const lngs = [
//   ["en", "English"],
//   ["ms", "Malay"],
// ];

function SignUpTwo({ setShowNav }) {
  const { t } = useTranslation();
  // const handleChange = (value) => {
  //   i18n.changeLanguage(value);
  // };
  const { setIsLoggedIn } = useAuth();
  const [otp, setOtp] = useState(null);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const { timeLeft, resetTimer } = useOtpTimer();
  const videoRef = useRef(null);
  const [videoLoaded, setVideoLoaded] = useState(false);

  const navigate = useNavigate();
  const mobileNumber = sessionStorage.getItem("mobile");
  const dispatch = useDispatch();
  useEffect(() => {
    setShowNav(false);
    // eslint-disable-next-line
  }, []);

  const otpGenerate = async () => {
    // await AuthService.getRecaptchaToken();
    setLoading(true);
    try {
      await AuthService.generateOTP();

      // toast.success("OTP sent successfully!");
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    otpGenerate();
  }, []);

  const handleOtpSubmit = (otp) => {
    setOtp(otp);
  };

  const handleOtpLoginClick = async (e) => {
    e.preventDefault();

    if (!otp) {
      setError("Please enter the correct OTP");
      return;
    }
    setError("");
    setLoading(true);
    try {
      const res = await AuthService.validateOTP(otp);
      if (res.status.success) {
        const updateStatus =
          sessionStorage.getItem("updatePassword") === "true";

        if (updateStatus || sessionStorage.getItem("crm") === "true") {
          sessionStorage.removeItem("crm");
          sessionStorage.removeItem("updatePassword");
          navigate("/loginpage");
          return;
        }
        const addCustomerRes = await CustomerService.addCustomer();

        // Check for duplicate email warning first
        if (
          addCustomerRes?.customers?.customer[0]?.item_status?.warnings?.warning.includes(
            "91006"
          )
        ) {
          toast.warning("Duplicate Email ID", {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: true,
            closeButton: true,
          });
          // navigate("/loginpage");
          return; // Exit early to avoid further processing
        }

        const successBoolean = addCustomerRes?.status?.success === "true"; //

        if (successBoolean) {
          try {
            const getCustomer = await customerService.getCustomer();
            // console.log(getCustomer);

            if (getCustomer?.status?.success === "true") {
              dispatch(addCustomerData(getCustomer?.customers?.customer[0]));
              setIsLoggedIn(true);
              navigate("/hello-screen");
            } else {
              console.log(getCustomer.status.message);
              setError(getCustomer.status.message);
            }
          } catch (error) {
            setError("Failed to get Customer data: " + error.message);
          }
        } else {
          setError(
            addCustomerRes?.status?.message || "Customer addition failed"
          );
        }
      } else {
        setError(res.status.message);
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const video = videoRef.current;
    // const video = document.querySelector(".qVaWx1ia4MK_oyPXz791");

    if (video) {
      video.load();
      video.play().catch((error) => {
        console.log("Video play failed:", error);
      });
    }
    setVideoLoaded(true);
    return () => {
      // Optionally store the current video state before unmounting
      if (video) {
        setVideoLoaded(false);
      }
    };
  }, []);
  const handleResendClick = () => {
    otpGenerate();
    // toast.info("A new OTP has been sent to your mobile number.", {
    //   position: "top-right",
    //   autoClose: 4000,
    //   hideProgressBar: true,
    //   closeButton: true,
    // });
    resetTimer();
    setOtp(null);
    toast.info("A new OTP has been sent to your mobile number.");
  };
  if (loading)
    return (
      <div className="loading-div">
        <Spin size="large" />
      </div>
    );

  return (
    <div className="signup2-container p-4">
      {/* <video autoPlay muted loop className="background-video">
        <source src={backgroundVideo} type="video/mp4"></source>
      </video> */}
      <div className="video-background">
        <video
          className="qVaWx1ia4MK_oyPXz791"
          src={backgroundVideo}
          preload="auto"
          autoPlay={videoLoaded}
          loop
          muted
          ref={videoRef}
          playsInline
        ></video>
      </div>
      {/* <video id="background-video" class="qVaWx1ia4MK_oyPXz791" src={backgroundVideo} preload="auto" autoPlay loop muted playsinline></video> */}
      <div className="back-button-container justify-between items-center">
        <button className="back-button" onClick={() => navigate(-1)}>
          <img src={backIcon} alt="back-button"></img>
        </button>
        {/* <Select
          defaultValue={i18n.resolvedLanguage}
          style={{ width: 150 }}
          onChange={handleChange}
          options={lngs.map((lng) => {
            return { value: lng[0], label: lng[1] };
          })}
        /> */}
      </div>
      <div className="overlay"></div>
      <form className="signup2-content" onSubmit={handleOtpLoginClick}>
        <p className="title playfair">Enter OTP</p>
        <p className="subtitle playfair">
          Please enter the OTP sent to +{mobileNumber?.slice(0, -4) + "XXXX"}
        </p>
        <Link to="/signup">{t("signup2.link")}</Link>
        <div className="input-div flex justify-center">
          <OTPInput onOtpSubmit={handleOtpSubmit} setError={setError} />
        </div>
        {timeLeft === 0 ? (
          <div className="flex items-center justify-center">
            <button onClick={handleResendClick} disabled={loading}>
              <Link>Resend</Link>
            </button>
          </div>
        ) : (
          // {t("signup2.timer", { timeLeft })}
          <p className="subtitle">{`Request for a new OTP in ${timeLeft} seconds`}</p>
        )}
        {/* {error && <p style={{ color: "red", textAlign: "center" }}>{error}</p>} */}
        {error && (
          <p
            style={{
              color: "white",
              textAlign: "center",
              fontFamily: "Obviously",
            }}
            className="text-[10.5px] leading-3 mb-3"
          >
            <span
              style={{
                borderBottom: "2px solid #A00808",
                textDecoration: "none",
              }}
            >
              {error}
            </span>
          </p>
        )}
        <div className="mt-24 flex justify-center items-center">
          <button type="submit" className="button" disabled={!!error}>
            Next
          </button>
        </div>
      </form>
    </div>
  );
}

export default SignUpTwo;
