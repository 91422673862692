import { GoogleMap, DirectionsRenderer } from "@react-google-maps/api";
import { Button } from "antd";
import BackIcon from "../assets/backIcon.svg";

const center = {
  lat: 3.133564,
  lng: 101.957466,
};

const Map2 = ({ setView, isLoaded, directions, error, view }) => {
  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <GoogleMap
      center={center}
      zoom={12}
      mapContainerStyle={{ width: "100%", height: "95vh" }}
      options={{
        mapTypeControl: false, // Disable map type control (satellite button)
      }}
    >
      <div
        className="flex items-center mt-8 pl-2 absolute"
        onClick={() => setView(!view)}
      >
        <img
          src={BackIcon}
          alt="back icon"
          // className="rounded-full shadow-[0_0_8px_#0000006b]"
        />
      </div>
      {directions && <DirectionsRenderer directions={directions} />}

      {error && <div>{error}</div>}
      {/* <div className="text-center absolute left-[37%] bottom-[115px]">
        <Button
          shape="round"
          className="bg-black text-white border-0 playfair italic btn w-28 h-9"
          onClick={() => setView((prev) => !prev)}
        >
          Back to Stores
        </Button>
      </div> */}
    </GoogleMap>
  );
};

export default Map2;
