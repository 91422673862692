import { Navigate } from 'react-router-dom';
import { useAuth } from './AuthProvider'; 

// Protected Route Component (for logged-in user routes)
export const ProtectedRoute = ({ children }) => {
  const { isLoggedIn } = useAuth(); 
  if (!isLoggedIn) {
    return <Navigate to="/loginpage" />; // Redirect to login if not logged in
  }
  return children;
};

// Public Route Component (for login/signup routes)
export const PublicRoute = ({ children }) => {
  
  const { isLoggedIn } = useAuth();

  if (isLoggedIn) {
    return <Navigate to="/valiram-card" />; // Redirect to home if logged in
  }
  return children;
};
