import React from "react";
import { Button } from "antd";
import { Typography } from "antd";
import { useTranslation } from "react-i18next";

import "./styles.css";
import Header2 from "../../components/Header2";
import Reward from "../../assets/reward.svg";

const { Paragraph } = Typography;

export default function ReferAFriend() {
  const { t } = useTranslation();
  return (
    <div className="min-h-dvh bg-white">
      <div className="pl-2">
        <Header2 />
      </div>
      <div className="obviously py-3 text-xl pl-5 mb-4">
        <h2>{t("refer.title")}</h2>
      </div>
      <div className="px-3">
        <div className="flex justify-center items-center mt-8  mb-4">
          <img src={Reward} alt="reward icon" />
        </div>
        <div className="obviously flex flex-col items-center gap-8 p-8 text-center">
          <h3>{t("refer.desc1")}</h3>
          <p className="playfair italic text-sm">{t("refer.desc2")}</p>
          <div className="w-4/5 py-2 px-4 shadow-[0_0_5px_#00000080] rounded-full para">
            <Paragraph
              copyable={{ text: "V0128656453" }}
              className="flex justify-between items-center"
            >
              <p className="mb-0">V0128656453</p>
            </Paragraph>
          </div>
          <Button className="playfair italic bg-[#B78600] rounded-full text-white px-6 py-4">
            {t("refer.share")}
          </Button>
        </div>
        <div>
          <div className="obviously py-3 text-xl pl-5">
            <h2>{t("refer.myReferals")}</h2>
          </div>
          <div className="flex mb-28">
            <div className="basis-1/2 flex flex-col gap-4 justify-center items-center py-4 border-r-[2px] border-solid border-[#7070707a]">
              <h1 className="text-3xl text-[#303445] obviously font-bold">
                12
              </h1>
              <p className="playfair italic text-xs">
                {t("refer.successfulReferrals")}*
              </p>
            </div>
            <div className="basis-1/2 flex flex-col gap-4 justify-center items-center">
              <h1 className="text-3xl text-[#303445] obviously font-bold">
                V$30
              </h1>
              <p className="playfair italic text-xs">
                {t("refer.rewardsEarned")}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
