import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";

import "./styles.css";
import backIcon from "../../assets/backIcon.svg";
import backgroundVideo from "../../assets/videos/background-video.mp4";
import MobileNumberInput from "../../components/MobileNumberInput";

// const lngs = [
//   ["en", "English"],
//   ["ms", "Malay"],
// ];

function ForgotPassword({ setShowNav }) {
  const { t } = useTranslation();
  // const handleChange = (value) => {
  //   i18n.changeLanguage(value);
  // };

  const [mobile, setMobile] = useState(sessionStorage.getItem("phone"));
  const [mobileError, setMobileError] = useState("");
  const [countryCode, setCountryCode] = useState(
    sessionStorage.getItem("countryCode")
  );
  const [phoneRegex, setPhoneRegex] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const videoRef = useRef(null);
  const [videoLoaded, setVideoLoaded] = useState(false);
  useEffect(() => {
    setShowNav(false);
    // eslint-disable-next-line
  }, []);
  const handleResetButton = () => {
    const regex = new RegExp(phoneRegex);
    if (!mobile) {
      setMobileError("Please enter the number");

      return;
    } else if (!regex?.test(mobile)) {
      setMobileError("Enter a valid phone number");
      return;
    }
    const fullNo = countryCode + mobile;
    sessionStorage.setItem("mobile", fullNo);
    navigate("/updatePassword");
  };

  useEffect(() => {
    // const video = document.querySelector(".qVaWx1ia4MK_oyPXz791");
    const video = videoRef.current;
    if (video) {
      video.load();
      video.play().catch((error) => {
        console.log("Video play failed:", error);
      });
    }
    setVideoLoaded(true);
    return () => {
      // Optionally store the current video state before unmounting
      if (video) {
        setVideoLoaded(false);
      }
    };
  }, []);

  return (
    <div className="forgot-container">
      {/* <video autoPlay muted loop className="background-video">
        <source src={backgroundVideo} type="video/mp4"></source>
      </video> */}
      <div className="video-background">
        <video
          className="qVaWx1ia4MK_oyPXz791"
          src={backgroundVideo}
          preload="auto"
          autoPlay={videoLoaded}
          ref={videoRef}
          loop
          muted
          playsInline
        ></video>
      </div>
      {/* <video class="qVaWx1ia4MK_oyPXz791" id="background-video" src={backgroundVideo} preload="auto" autoPlay loop muted playsinline></video> */}
      <div className="back-button-container justify-between items-center">
        <button className="back-button" onClick={() => navigate(-1)}>
          <img src={backIcon} alt="back-button"></img>
        </button>
        {/* <Select
          defaultValue={i18n.resolvedLanguage}
          style={{ width: 150 }}
          onChange={handleChange}
          options={lngs.map((lng) => {
            return { value: lng[0], label: lng[1] };
          })}
        /> */}
      </div>
      <div className="overlay"></div>
      <form className="forgot-content">
        <h1 className="title"> {t("loginpage.forgot")}?</h1>
        <p className="subtitle">{t("forgotPassword.desc1")}.</p>
        <MobileNumberInput
          setError={setMobileError}
          phoneNo={mobile}
          setPhoneNo={setMobile}
          setCountryCode={setCountryCode}
          setPhoneRegex={setPhoneRegex}
        />
        {/* {mobileError && (
          <p className="text-[#ff0000] text-center mt-3 mb-2">{mobileError}</p>
        )} */}
        {mobileError && (
          <p
            style={{
              color: "white",
              textAlign: "center",
              fontFamily: "Obviously",
            }}
            className="text-[10.5px] leading-3 mb-3"
          >
            <span
              style={{
                borderBottom: "2px solid #A00808",
                textDecoration: "none",
              }}
            >
              {mobileError}
            </span>
          </p>
        )}
        {/* {error && (
          <p className="text-[#ff0000] text-center mt-3 mb-2">{error}</p>
        )} */}
        {error && (
          <p
            style={{
              color: "white",
              textAlign: "center",
              fontFamily: "Obviously",
            }}
            className="text-[10.5px] leading-3 mb-3"
          >
            <span
              style={{
                borderBottom: "2px solid #A00808",
                textDecoration: "none",
              }}
            >
              {error}
            </span>
          </p>
        )}

        <p className="subtitle">{t("forgotPassword.desc2")}.</p>
        <div
          className="mt-24 flex justify-center items-center"
          onClick={handleResetButton}
        >
          <button
            type="submit"
            className="button"
            disabled={!!mobileError || !!error}
          >
            {t("forgotPassword.reset")}
          </button>
        </div>
      </form>
    </div>
  );
}
export default ForgotPassword;
