import React, { useEffect, useState } from "react";
import { Spin } from "antd";
// import { motion as m } from "framer-motion";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { getTiers } from "../../APIs/api";

import Header2 from "../../components/Header2";
import PremierImg from "../../assets/svg/premier_tier.svg";
import GoldImg from "../../assets/svg/gold_tier.svg";
import PlatinumImg from "../../assets/svg/platinum_tier.svg";
import DiamondImg from "../../assets/svg/dimond_tier.svg";
import CheckTick from "../../assets/check-circle.svg";
import { useNavigate } from "react-router-dom";

export default function TierInformation() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const customerData = useSelector(
    (store) => store.customerDetails.customerData
  );
  const userTier = customerData?.current_slab;
  const [tiers, setTiers] = useState([]);
  const tiersOpt = [
    {
      name: t("tierInformation.Premier"),
      isSelected: true,
      icon: PremierImg,
    },
    {
      name: t("tierInformation.Gold"),
      isSelected: false,
      icon: GoldImg,
    },
    {
      name: t("tierInformation.Platinum"),
      isSelected: false,
      icon: PlatinumImg,
    },
    {
      name: t("tierInformation.Diamond"),
      isSelected: false,
      icon: DiamondImg,
    },
  ];
  useEffect(() => {
    async function getData() {
      setLoading(true);
      const dataObj = await getTiers();
      const dataArray =
        dataObj?.data?.[0]?.attributes?.data?.abconfig?.slabInfo;
      if (dataArray !== undefined) {
        setTiers(dataArray);
      } else {
        setTiers(tiersOpt);
      }
      setLoading(false);
    }
    getData();
    // eslint-disable-next-line
  }, []);

  if (loading)
    return (
      <div className="loading-div">
        <Spin size="large" />
      </div>
    );

  return (
    <>
      <div className="pt-4 fixed top-0 bg-black text-white z-20 w-full">
        <Header2 bgColor="black" textColor="white" />
        <div className="px-4">
          <div className="obviously py-2 text-xl flex justify-between">
            <h2>{t("sideMenu.tierInformation")}</h2>
            <h3 className="text-[#D5D5D5]" onClick={() => navigate("/faq")}>
              FAQ
            </h3>
          </div>
          <div className="p-2 mt-4">
            {tiers.map((tier, index) => (
              <div
                key={index}
                className="flex justify-between items-center mb-4"
              >
                <div className="obviously flex items-center gap-4">
                  <div className="w-16 h-16">
                    <img src={tier.icon} alt="tier img" className="" />
                  </div>
                  <h1 className="text-2xl">{tier.name}</h1>
                </div>
                {tier.name === userTier && (
                  <div>
                    <img src={CheckTick} alt="Check icon" />
                  </div>
                )}
              </div>
            ))}
            {/* <div className="mb-2">
              <m.div initial={{ width: "0" }} animate={{ width: "100%" }}>
                <Progress
                  percent={20}
                  showInfo={false}
                  strokeColor="#CB8500"
                  trailColor="#ffffff"
                />
              </m.div>
              <div className="flex justify-end">
                <h6 className="playfair text-white text-xs italic">
                  {t("tierInformation.pointsAway", { pointsAway: "80,000" })}
                </h6>
              </div>
            </div> */}
          </div>
        </div>
      </div>

      <div className="mt-[450px] px-6 py-28 bg-black text-white flex flex-col gap-2 text-sm ">
        <p>{t("tierInformation.para1")}</p>
        <p>{t("tierInformation.para2")}</p>
        <p>{t("tierInformation.para3")}</p>
        <p>{t("tierInformation.para4")}</p>
        <p>{t("tierInformation.para5")}</p>
        <p>{t("tierInformation.para6")}</p>
        <p>{t("tierInformation.para7")}</p>
        <p>{t("tierInformation.para8")}</p>
        <p>{t("tierInformation.para9")}</p>
        <p>{t("tierInformation.para10")}</p>
        <p>{t("tierInformation.para11")}</p>
        <p>{t("tierInformation.para12")}</p>
        <p>{t("tierInformation.para13")}</p>
        <p>{t("tierInformation.para14")}</p>
        <p>{t("tierInformation.para15")}</p>
        <p>{t("tierInformation.para16")}</p>
        <p>{t("tierInformation.para17")}</p>
      </div>
    </>
  );
}
