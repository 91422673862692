import React from "react";
import { useNavigate } from "react-router-dom";

import SearchIcon from "../assets/search-icon.svg";
// import MessageIcon from "../assets/message-icon.svg";
import HomeIcon from "../assets/home-icon.svg";
import MenuIcon from "../assets/menu-icon.svg";
import rewardIcon from "../assets/sparkles1.svg";
import profile from "../assets/profile.svg";

export default function BottomNav({ showNav }) {
  const navigate = useNavigate();
  return (
    <>
      {showNav && (
        <div className="nav-container z-10">
          <div
            className="flex flex-col items-center justify-center gap-1.5"
            onClick={() => navigate("/brands")}
          >
            <img src={SearchIcon} alt="Search icon" />
            <p className="text-white text-xs playfair ">BRANDS</p>
          </div>

          <div
            className="flex flex-col items-center justify-center gap-1.5"
            onClick={() => navigate("/your-offers")}
          >
            <img src={rewardIcon} alt="message icon" />
            <p className="text-white text-xs">OFFERS</p>
          </div>
          <div className="home-icon" onClick={() => navigate("/valiram-card")}>
            <img
              src={HomeIcon}
              alt="home icon"
              className="relative top-[-22px] left-1 "
            />
          </div>
          <div
            onClick={() => navigate("/profile-update")}
            className="flex flex-col items-center justify-center gap-1.5"
          >
            <img
              src={profile}
              alt="profile icon"
              // onClick={() => navigate("/rewards-list")}
            />
            <p className="text-white text-xs playfair ">PROFILE</p>
          </div>
          <div onClick={() => navigate("/side-menu")} className="mb-4">
            <img src={MenuIcon} alt="menu icon" />
          </div>
        </div>
      )}
    </>
  );
}
