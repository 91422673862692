import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { format, parse } from "date-fns";

import "./styles.css";
import Header from "../../components/Header";
// import Filters from "../../components/Filters";

import HeartIcon from "../../assets/heart.svg";

import DownIcon from "../../assets/down.svg";
import HeartEmptyIcon from "../../assets/heart-empty.svg";
import { Button, Drawer, Spin } from "antd";
import CustomerServiceInstance from "../../utils/customer-service";
import { addCustomerData } from "../../redux/slices/customerDetails";
import ritualImg from "../../assets/ritual.svg";
import Filters from "../../components/Filters";
import { useNavigate } from "react-router-dom";
import { TruncateSentence } from "../../utils/truncate-func";

export default function MyFavourites({ setStoreIds }) {
  const { t } = useTranslation();
  const offersData = useSelector((store) => store.customerDetails.offers);
  const [offerDetails, setOfferDetails] = useState({});
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [renderedOffers, setRenderedOffers] = useState(offersData.offers);
  const navigate = useNavigate();
  const offerCategories = offersData.categories;

  const dispatch = useDispatch();
  const onClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    // Disable scrolling when modal is open
    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [open]);
  const getOfferId = (id) => {
    const idStr = String(id);

    const offersObject = customerData?.custom_fields?.field?.find(
      (item) => item.name === "favourite_offers"
    );

    const myFav = offersObject ? offersObject?.value : "";

    let data = myFav?.split(",")?.filter(Boolean)?.map(String);

    data = Array.from(new Set(data));

    if (data?.includes(idStr)) {
      data = data?.filter((item) => item !== idStr);
    } else {
      data?.push(idStr);
    }

    // console.log("Updated data:", data);

    return data?.join(",");
  };
  const customerData = useSelector(
    (store) => store?.customerDetails?.customerData
  );
  // const exProperties = customerData?.extended_fields?.field?.reduce(
  //   (acc, field) => {
  //     acc[field?.name] = field?.value;
  //     return acc;
  //   },
  //   {}
  // );
  useEffect(() => {
    setRenderedOffers(offersData?.offers);
  }, [customerData]);
  const handleLikeButton = async (offer_id) => {
    const myFavs = getOfferId(offer_id);

    const body = {
      root: {
        customer: [
          {
            mobile: sessionStorage.getItem("mobile"),
            firstname: customerData?.firstname,
            lastname: customerData?.lastname,
            email: customerData?.email,
            custom_fields: {
              field: [
                // {
                //   name: "salutation",
                //   value: customProperties?.salutation,
                // },
                // {
                //   name: "wedding_anniversary",
                //   value: customProperties?.wedding_anniversary,
                // },
                // {
                //   name: "bra_fit_band",
                //   value: customProperties?.bra_fit_band,
                // },
                // {
                //   name: "bra_fit_cup",
                //   value: customProperties?.bra_fit_cup,
                // },
                // {
                //   name: "shoe_size",
                //   value: customProperties?.shoe_size,
                // },
                // {
                //   name: "top_size",
                //   value: customProperties?.top_size,
                // },
                // {
                //   name: "botton_size",
                //   value: customProperties?.botton_size,
                // },
                // {
                //   name: "brands_you_love",
                //   value: customProperties?.brands_you_love,
                // },
                {
                  name: "favourite_offers",
                  value: myFavs,
                },
              ],
            },
            // extended_fields: {
            //   field: [
            //     {
            //       name: "dob",
            //       value: exProperties?.dob,
            //     },
            //     {
            //       name: "gender",
            //       value: exProperties?.gender,
            //     },
            //     {
            //       name: "nationality",
            //       value: exProperties?.country_of_residence,
            //     },
            //     {
            //       name: "city",
            //       value: exProperties?.city,
            //     },
            //   ],
            // },
          },
        ],
      },
    };

    try {
      setLoading(true);
      const res = await CustomerServiceInstance.updateCustomer(body);
      if (res?.status?.success) {
        // toast.success("Added to favourites successfully", {
        //   position: "bottom-right",
        //   closeButton: true,
        //   hidePrgressBar: false,
        //   autoClose: 4000,
        // });
        try {
          const getCustomer = await CustomerServiceInstance.getCustomer();
          if (getCustomer.status.success) {
            dispatch(addCustomerData(getCustomer?.customers?.customer[0]));
          } else {
            console.log(getCustomer.status.message);
          }
        } catch (e) {
          console.log("Error fetching customer data", e);
        }

        setOpen(false);
        // navigate("/my-favourites");
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  const handleOfferClick = (offer) => {
    setOfferDetails(offer);
    setOpen(true);
  };

  const customProperties = customerData?.custom_fields?.field?.reduce(
    (acc, field) => {
      acc[field?.name] = field?.value;
      return acc;
    },
    {}
  );
  const formatDate = (expiryDate) => {
    if (expiryDate) {
      const parsedDate = parse(expiryDate, "yyyy-MM-dd HH:mm:ss", new Date());

      const formattedDate = format(parsedDate, "MMMM yyyy");

      return formattedDate;
    }
  };
  const MyFavArr = renderedOffers?.filter((off) =>
    customProperties?.favourite_offers?.includes(off?.offer_id?.toString())
  );

  // const favourites = [
  //   {
  //     name: "Dine In Deal",
  //     distance: "1.2KM •  Victorias Secret",
  //     date: "June 2024",
  //     image: DineInDeal,
  //   },
  //   {
  //     name: "Welnes For You",
  //     distance: "3KM • Michael Kors",
  //     date: "June 2024",
  //     image: WelnesForyou,
  //   },
  // ];
  const handleFindStoreClick = (offer) => {
    setStoreIds(offer?.store_ids);
    navigate("/brands-store-listing");
  };
  if (loading)
    return (
      <div className="loading-div">
        <Spin size="large" />
      </div>
    );
  return (
    <div className="min-h-dvh">
      <Header showLogo />
      <div className="pl-3 pr-3 pb-28">
        <div className="obviously py-2 text-lg pl-3">
          <h2>{t("sideMenu.favourites")}</h2>
        </div>
        {/* <Filters /> */}
        <Filters
          categoryList={offerCategories}
          setRenderedBrands={setRenderedOffers}
          brands={offersData?.offers}
        />
        <div className="mt-4 flex flex-col gap-4 ">
          {MyFavArr?.length > 0 ? (
            MyFavArr?.map((favourite, index) => (
              <div
                key={index}
                className={`text-white relative h-48 flex flex-col justify-between px-4 py-4 rounded-xl bg-cover`}
                style={{
                  backgroundImage: `url(${favourite?.imageUrl})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
                onClick={() => handleOfferClick(favourite)}
              >
                <h2
                  className={`text-2xl [text-shadow:_0_3px_6px_${
                    index === 0 ? "black" : "white"
                  }] playfair italic  "text-[${favourite?.txt_clr}]"}`}
                >
                  {favourite?.title}
                </h2>
                <div className="flex items-center justify-between">
                  <div className=" w-32 h-9 bg-white text-black rounded-3xl flex justify-center items-center obviously text-sm p-5">
                    {formatDate(favourite?.expiry)}
                  </div>
                  <p className={`text-white playfair text-lg`}>
                    {TruncateSentence(favourite?.desc, 50)}
                  </p>
                </div>
                <div
                  className="absolute right-4"
                  onClick={() => handleLikeButton(favourite?.offer_id)}
                >
                  <img src={HeartIcon} alt="Heart icon" />
                </div>
              </div>
            ))
          ) : (
            <div className="flex items-center justify-center flex-col">
              <img src={ritualImg} alt="ritual-img"></img>
              <p className="text-2xl text-center  font-[400] italic">
                No Favourites found!
              </p>
            </div>
          )}
        </div>
      </div>
      {/* <div className="drawer-cont h-dvh absolute w-full top-14 pointer-events-none bottom-0"> */}
      <Drawer
        placement="bottom"
        closable={false}
        onClose={onClose}
        open={open}
        getContainer={false}
        maskClosable={false} // Allows closing the drawer by clicking outside
        // width="100%"
        // height="90%"
        style={{
          borderTopLeftRadius: 20,
          borderTopRightRadius: 20,
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 10,
          width: "95%",
          height: "97%",
          transform: "translate(10px, 0px)",
          transition: "all 0.3s ease",
        }}
        // rootStyle={{ width: "90%", margin: "0 auto", outline: "none" }}
        rootClassName="hell"
        styles={{ body: { backgroundColor: "black" } }}
      >
        <div
          className="absolute inset-0 h-44 bg-cover w-full rounded-t-2xl"
          style={{
            backgroundImage: `url(${offerDetails?.imageUrl})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          {/* <img
              src={HeartEmptyIcon}
              alt="Heatv icon"
              className="absolute right-3 top-3"
            /> */}
          <div
            className="absolute right-4 top-4"
            onClick={() => handleLikeButton(offerDetails.offer_id)}
          >
            <img
              src={
                customProperties?.favourite_offers?.includes(
                  String(offerDetails?.offer_id)
                )
                  ? HeartIcon
                  : HeartEmptyIcon
              }
              alt="Heart icon"
            />
          </div>
        </div>
        <div className="mt-44 text-white obviously">
          <h2 className="playfair italic text-4xl">{offerDetails?.title}</h2>
          <p className="playfair text-base mt-6 mb-8">
            {offerDetails?.full_desc}
          </p>
          <div className="text-xs" style={{ fontSize: "12px" }}>
            {/* <p className="mb-6">Limited Time.</p>
              <p className="mb-1">$25 Sexy T and Bralettes</p>
              <p className="mb-1">
                Select styles. Excludes PINK, third-party styles,
              </p>
              <p className="mb-1"> and clearance. Priced as marked.</p> */}
            {/* <p>{offerDetails?.full_desc}</p> */}
          </div>
        </div>
        <div className="text-white flex flex-col my-11 items-center gap-4 obviously mt-32">
          <div className="bg-white text-black px-4 py-2 text-xs rounded-full w-4/5 flex items-center justify-center">
            Valid Thru : {formatDate(offerDetails?.expiry)}
          </div>
          <Button
            className="h-9 bg-[#B78600] border-0 rounded-full text-white py-4 px-11"
            onClick={() => handleFindStoreClick(offerDetails)}
          >
            Find Stores
          </Button>
        </div>

        <div className="flex justify-center mb-4">
          <img src={DownIcon} alt="Down icon" onClick={() => setOpen(false)} />
        </div>
      </Drawer>
      {/* </div> */}
    </div>
  );
}
