import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
// import { Select } from "antd";

import "./styles.css";
import logo from "../../assets/svg/logo.svg";

import backgroundVideo from "../../assets/videos/background-video.mp4";

// const lngs = [["en","English"], ["ms", "Malay"]];

function LoginTwo({ setShowNav }) {
  const { t } = useTranslation();
  const videoRef = useRef(null);
  const [videoLoaded, setVideoLoaded] = useState(false);
  // const handleChange = (value) => {
  //   i18n.changeLanguage(value);
  // };

  const navigate = useNavigate();
  useEffect(() => {
    setShowNav(false);
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    // const video = document.querySelector(".qVaWx1ia4MK_oyPXz791");
    const video = videoRef.current;

    if (video) {
      video.load();
      video.play().catch((error) => {
        console.log("Video play failed:", error);
      });
    }
    setVideoLoaded(true);
    return () => {
      // Optionally store the current video state before unmounting
      if (video) {
        setVideoLoaded(false);
      }
    };
  }, []);
  return (
    <div className="login2-container">
      {/* <video autoPlay muted loop className="background-video">
        <source src={backgroundVideo} type="video/mp4"></source>
      </video> */}
      <div className="video-background">
        <video
          className="qVaWx1ia4MK_oyPXz791"
          src={backgroundVideo}
          preload="auto"
          autoPlay={videoLoaded}
          ref={videoRef}
          loop
          muted
          playsInline
        ></video>
      </div>
      {/* <div className="back-button-container justify-between items-center">
        <button className="back-button" onClick={() => navigate(-1)}>
          <img src={backIcon} alt="back-button"></img>
        </button>
        <Select
          defaultValue={i18n.resolvedLanguage}
          style={{ width: 150 }}
          onChange={handleChange}
          options={lngs.map((lng) => {
            return { value: lng[0], label: lng[1] };
          })}
        />
      </div> */}
      <div className="overlay"></div>
      <div className="login2-content">
        <div className="logo-container">
          <img src={logo} className="logo" alt="logo"></img>
        </div>

        <h1 className="heading italic">The Best of Retail Starts here</h1>
        <p className="subtitle">{t("landing.desc")}</p>
        <div className="button-wrapper">
          <button className="button signup" onClick={() => navigate("/signup")}>
            {t("login2.button1")}
          </button>
          <button
            className="button login"
            onClick={() => navigate("/loginpage")}
          >
            Log In
          </button>
        </div>
        {/* <Link className="face-id"> {t("login2.button3")}</Link> */}
      </div>
    </div>
  );
}
export default LoginTwo;
