import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Input, Radio, Flex, Select } from "antd";
import { useTranslation } from "react-i18next";

import "./styles.css";
import backIcon from "../../assets/backIcon.svg";
import backgroundVideo from "../../assets/videos/background-video.mp4";
import eyeIcon from "../../assets/eyebrow.svg";
import warning from "../../assets/svg/warning.svg";
import eyeOpenIcon from "../../assets/open-eye.svg";
// import useOtpTimer from "../../hooks/useOtpTimer";

// import MobileNumberInput from "../../components/MobileNumberInput";

import { useSelector } from "react-redux";
const { Option } = Select;

// const lngs = [
//   ["en", "English"],
//   ["ms", "Malay"],
// ];

function SignUpThree({ setShowNav }) {
  // const [mobileError, setMobileError] = useState("");
  const { t } = useTranslation();

  // const handleChangeLng = (value) => {
  //   i18n.changeLanguage(value);
  // };
  const navigate = useNavigate();
  const [gender, setGender] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [countryCode, setCountryCode] = useState(null);
  const [maxDate, setMaxDate] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const videoRef = useRef(null);
  const [videoLoaded, setVideoLoaded] = useState(false);

  const [errors, setErrors] = useState({
    firstname: "",
    lastname: "",
    email: "",
    dob: "",
    password: "",
    gender: "",
    nationality: "",
  });
  // const [options, setOptions] = useState([]);
  const phone =
    "+" +
    (sessionStorage.getItem("countryCode") || "") +
    " " +
    (sessionStorage.getItem("phone") || "");
  const dataObj = JSON.parse(sessionStorage.getItem("user"));
  const [formData, setFormData] = useState({
    firstName: dataObj && dataObj?.firstName,
    lastName: dataObj && dataObj?.lastName,
    email: dataObj && dataObj?.email,
    mobile: phone || "",

    gender: dataObj && dataObj?.gender,
    dob: dataObj && dataObj?.dob,
    country_of_residence: dataObj && dataObj?.country_of_residence,
    password: dataObj && dataObj?.password,
  });

  const nationalityOptions = useSelector(
    (store) => store.customerDetails.countryList
  );
  useEffect(() => {
    setShowNav(false);
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    const currentDate = new Date();
    currentDate.setFullYear(currentDate.getFullYear() - 5);
    const formattedDate = currentDate.toISOString().split("T")[0]; // Format to YYYY-MM-DD
    setMaxDate(formattedDate);
    // setFormData((prev) => ({ ...prev, dob: formattedDate }));
  }, []);

  const handleChange = (e) => {
    setErrors("");
    let value;

    if (e && e.target) {
      const { name, value: inputValue } = e.target;

      if (name === "firstName" || name === "lastName") {
        // For firstName: Allow letters and a single space

        value = inputValue
          .replace(/[^a-zA-Z ]/g, "") // Allow only letters and spaces
          .replace(/\s\s+/g, " ") // Replace multiple consecutive spaces with one space
          .trimStart(); // Prevent leading spaces

        // if only one space or no space add input value
        const spaceCount = (value.match(/ /g) || []).length;

        if (spaceCount <= 1) {
          value = value;
        } else value = value.replace(/\s+/g, " ").trim();
      } else if (name === "password") {
        // For password: Disallow spaces
        value = inputValue.replace(/\s/g, ""); // Remove all spaces
      } else {
        value = inputValue; // Handle other fields if necessary
      }

      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    } else {
      // This handles the date picker case
      setFormData((prevFormData) => ({
        ...prevFormData,
        dob: e, // e is the date object from react-datepicker
      }));
    }
  };
  const handleToggle = () => {
    setShowPassword((prev) => !prev);
  };
  const handleSelectChange = (value, fieldName) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: value,
    }));
  };
  const handleRadioChange = (e) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      gender: e.target.value,
    }));
  };

  const validate = () => {
    let isValid = true;
    let newErrors = {
      firstname: "",
      lastname: "",
      email: "",
      password: "",
      gender: "",
      nationality: "",
      dob: "",
    };

    // if (!formData.firstName) {
    //   newErrors.firstname = "Please enter valid First Name.";
    //   isValid = false;
    // }
    // if (!formData.lastName) {
    //   newErrors.lastname = "Please enter valid Last Name.";
    //   isValid = false;
    // }
    if (!formData.firstName) {
      newErrors.firstname = "Please enter valid First Name";
      isValid = false;
    }
    if (!formData.lastName) {
      newErrors.lastname = "Please enter valid Last Name";
      isValid = false;
    }

    if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Please enter valid email address";
      isValid = false;
    }
    if (!formData.dob) {
      newErrors.dob = "Please enter date of birth";
      isValid = false;
    }

    if (!formData.country_of_residence) {
      newErrors.nationality = "Nationality is required";
      isValid = false;
    }
    if (!formData.gender) {
      newErrors.gender = "Gender is required";
      isValid = false;
    }
    // Password validation
    const passwordCriteria =
      /^(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^])[a-zA-Z\d!@#$%^]{8,}$/;

    if (!passwordCriteria.test(formData.password)) {
      newErrors.password =
        "Password must be at least 8 characters long, include at least one lowercase letter, one number, and one special character from the following: !@#$%^.";
      isValid = false;
    }

    setErrors(newErrors);

    return isValid;
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    const fullNo = countryCode + formData.mobile;

    if (validate()) {
      const updatedFormData = {
        ...formData,
        mobile: fullNo,
      };

      sessionStorage.setItem("user", JSON.stringify(updatedFormData));

      navigate("/terms&Condition");
    }
  };

  useEffect(() => {
    const video = videoRef.current;
    // const video = document.querySelector(".qVaWx1ia4MK_oyPXz791");

    if (video) {
      video.load();
      video.play().catch((error) => {
        console.log("Video play failed:", error);
      });
    }
    setVideoLoaded(true);
    return () => {
      // Optionally store the current video state before unmounting
      if (video) {
        setVideoLoaded(false);
      }
    };
  }, []);
  useEffect(() => {
    if (isDropdownOpen) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
    // Cleanup on unmount
    return () => document.body.classList.remove("no-scroll");
  }, [isDropdownOpen]);

  // const { timeLeft } = useOtpTimer();
  return (
    <div className="signup3-container py-32">
      {/* <video autoPlay muted loop className="background-video">
        <source src={backgroundVideo} type="video/mp4"></source>
      </video> */}
      <div className="video-background">
        <video
          className="qVaWx1ia4MK_oyPXz791"
          src={backgroundVideo}
          preload="auto"
          autoPlay={videoLoaded}
          loop
          muted
          ref={videoRef}
          playsInline
        ></video>
      </div>
      {/* <video id="background-video" class="qVaWx1ia4MK_oyPXz791" src={backgroundVideo} preload="auto" autoPlay loop muted playsinline></video> */}
      <div className="back-button-container justify-between items-center">
        <button
          className="back-button"
          onClick={() => {
            sessionStorage.removeItem("user");
            navigate(-1);
          }}
        >
          <img src={backIcon} alt="back-button"></img>
        </button>
        {/* <Select
          defaultValue={i18n.resolvedLanguage}
          style={{ width: 150 }}
          onChange={handleChangeLng}
          options={lngs.map((lng) => {
            return { value: lng[0], label: lng[1] };
          })}
        /> */}
      </div>
      <div className="overlay"></div>
      <div className="signup3-content">
        <h1 className="title">{t("signup3.title")}</h1>
        <p className="subtitle">Enter Your Personal Information</p>
        <form
          className="form-container"
          onSubmit={handleSubmit}
          autoComplete="off"
        >
          <Input
            placeholder={t("signup3.placeholder1")}
            className="input-field"
            name="firstName"
            value={formData.firstName}
            onChange={(e) => handleChange(e)}
            autoComplete="off"
            // style={{
            //   border: errors.firstname ? "1px solid #FF0000" : "none",
            // }}
          />

          <Input
            placeholder={t("signup3.placeholder2")}
            className="input-field"
            name="lastName"
            onChange={(e) => handleChange(e)}
            value={formData.lastName}
            autoComplete="off"
            // style={{
            //   border: errors.lastname ? "1px solid #FF0000" : "none",
            // }}
          />

          <Input
            type="text"
            placeholder={t("signup3.placeholder3")}
            className="input-field"
            name="email"
            onChange={(e) => handleChange(e)}
            value={formData.email}
            autoComplete="off"
            // style={{
            //   border: errors.email ? "1px solid #FF0000" : "none",
            // }}
          />

          {/* <Input
            type="tel"
            placeholder="+60 12 385 XXXX"
            className="input-field"
            name="mobile"
            onChange={(e) => handleChange(e)}
            value={sessionStorage.getItem("mobile")}
          /> */}

          {/* <MobileNumberInput
            setError={setMobileError}
            phoneNo={formData.mobile}
            setPhoneNo={(newMobile) =>
              setFormData((prevFormData) => ({
                ...prevFormData,
                mobile: newMobile,
              }))
            }
            setCountryCode={setCountryCode}
            inputWidth={"85vmin"}
            autoComplete="off"
            disabled={true}
          />
          {mobileError && (
            <p className="text-[#ff0000] text-center mt-3 mb-2">
              {mobileError}
            </p>
          )} */}

          <Input
            placeholder="+60 12 385 XXXX"
            name="mobile"
            className="input-field"
            value={formData.mobile}
            style={{ backgroundColor: "white", color: "rgb(0,0,0)" }}
            autoComplete="off"
            disabled
          />

          {/* <Input
            placeholder="Date of Birth"
            type="date"
            name="dob"
            className="input-field focus:outline-none focus:border-transparent"
            onChange={(e) => handleChange(e)}
            value={formData.dob}
            autoComplete="off"
            max={maxDate}
            required
          /> */}
          <div className="relative">
            <label
              htmlFor="dob"
              className={`absolute font-[Obviously]  left-4 top-4 transition-all duration-200  z-10 text-[15px] pl-2 `}
              style={{
                color: "#00000057",
                display: formData.dob ? "none" : "",
              }}
            >
              Date of Birth
            </label>
            <Input
              id="dob"
              type="date"
              name="dob"
              className="input-field focus:outline-none focus:border-transparent pl-2 pt-4 pb-1"
              onChange={(e) => handleChange(e)}
              value={formData.dob}
              autoComplete="off"
              max={maxDate}
            />
          </div>

          {/* <Input placeholder={t("signup3.placeholder5")} className="input-field"  name="nationality"
            onChange={(e) => handleChange(e)}
            value={formData.nationality} /> */}

          <Select
            placeholder={
              <span
                style={{
                  fontFamily: "Obviously",
                  fontSize: "15px",
                  color: "#00000057",
                }}
              >
                Nationality
              </span>
            }
            showSearch
            onDropdownVisibleChange={(open) => setIsDropdownOpen(open)}
            name="country_of_residence"
            style={{
              width: "100%",
              marginBottom: "10px",
            }}
            onChange={(value) =>
              handleSelectChange(value, "country_of_residence")
            }
            value={formData.country_of_residence}
            className="rounded-full nation h-14"
          >
            {nationalityOptions?.map((option) => (
              <Option
                key={option?.country_name}
                value={option?.country_name}
                style={{}}
              >
                {option?.country_name}
              </Option>
            ))}
          </Select>

          <Flex vertical gap="middle" className="h-14 mb-3">
            <Radio.Group
              defaultValue=""
              buttonStyle="solid"
              onChange={handleRadioChange}
              value={formData.gender}
              className="h-full flex"
            >
              <Radio.Button
                value="Male"
                style={{
                  width: "50%",
                  borderRadius: "30px 0px 0px 30px",
                  backgroundColor:
                    formData.gender === "Male" ? "#b78600" : "white",
                  border: "none",
                  padding: "25px",
                  fontSize: "15px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                  fontFamily: "Obviously",
                  color: formData.gender === "Male" ? "#ffffff" : "#00000057",
                }}
                className="flex justify-start items-center"
                onClick={() => setGender(true)}
              >
                {t("signup3.placeholder7")}
              </Radio.Button>
              <Radio.Button
                value="Female"
                style={{
                  width: "50%",
                  borderRadius: "0px 30px 30px 0px",

                  marginBottom: "10px",
                  backgroundColor:
                    formData.gender === "Female" ? "#B78600" : "white",
                  border: "none",
                  padding: "25px",
                  fontSize: "15px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                  fontFamily: "Obviously",
                  color: formData.gender === "Female" ? "#ffffff" : "#00000057",
                }}
                className="flex justify-start items-center"
                onClick={() => setGender(false)}
              >
                {t("signup3.placeholder6")}
              </Radio.Button>
            </Radio.Group>
          </Flex>

          <div className="relative">
            <Input
              type={showPassword ? "text" : "password"}
              placeholder={t("signup3.placeholder8")}
              className="input-field"
              name="password"
              onChange={(e) => handleChange(e)}
              value={formData.password}
              autoComplete="off"
            />
            <button
              type="button"
              onClick={handleToggle}
              className="toggle-button"
            >
              {!showPassword ? (
                <img src={eyeIcon} alt="eye icon"></img>
              ) : (
                <img src={eyeOpenIcon} alt="eye icon"></img>
              )}
            </button>
          </div>
          <div
            className=" flex justify-around items-center gap-4 w-[85vmin] text-[10.5px] leading-[18px]"
            style={{ fontFamily: "Obviously" }}
          >
            {Object.values(errors).some((error) => error) && (
              <img
                src={warning}
                alt="warning-img"
                className="self-start pl-2"
              />
            )}
            <div>
              {errors.firstname && (
                <p
                  style={{
                    color: "white",
                    // textAlign: "start",
                  }}
                >
                  <span
                    style={{
                      borderBottom: "2px solid #A00808",
                      textDecoration: "none",
                    }}
                  >
                    {errors.firstname}
                  </span>
                </p>
              )}
              {errors.lastname && (
                <p
                  style={{
                    color: "white",
                    // textAlign: "start",
                  }}
                >
                  <span
                    style={{
                      borderBottom: "2px solid #A00808",
                      textDecoration: "none",
                    }}
                  >
                    {errors.lastname}
                  </span>
                </p>
              )}
              {errors.email && (
                <p
                  style={{
                    color: "white",
                    // textAlign: "start",
                  }}
                >
                  <span
                    style={{
                      borderBottom: "2px solid #A00808",
                      textDecoration: "none",
                    }}
                  >
                    {" "}
                    {errors.email}
                  </span>
                </p>
              )}
              {errors.dob && (
                <p
                  style={{
                    color: "white",
                    // textAlign: "start",
                  }}
                >
                  <span
                    style={{
                      borderBottom: "2px solid #A00808",
                      textDecoration: "none",
                    }}
                  >
                    {errors.dob}
                  </span>
                </p>
              )}
              {errors.nationality && (
                <p
                  style={{
                    color: "white",
                    // textAlign: "start",
                  }}
                >
                  <span
                    style={{
                      borderBottom: "2px solid #A00808",
                      textDecoration: "none",
                    }}
                  >
                    {errors.nationality}
                  </span>
                </p>
              )}
              {errors.gender && (
                <p
                  style={{
                    color: "white",
                    // textAlign: "start",
                  }}
                >
                  <span
                    style={{
                      borderBottom: "2px solid #A00808",
                      textDecoration: "none",
                    }}
                  >
                    {errors.gender}
                  </span>
                </p>
              )}
              {errors.password && (
                <p
                  style={{
                    color: "white",
                    // textAlign: "start",
                  }}
                >
                  <span
                    style={{
                      borderBottom: "2px solid #A00808",
                      textDecoration: "none",
                    }}
                  >
                    {errors.password}
                  </span>
                </p>
              )}
            </div>
          </div>

          {/* <p className="otp-msg">Request for a new otp in {timeLeft} seconds</p> */}
          <div className="flex justify-center items-center mt-10">
            <button
              type="submit"
              className="button"
              // disabled={
              //   errors.firstname ||
              //   errors.lastname ||
              //   !!errors.password ||
              //   !!errors.email
              // }
              // onClick={() => navigate("/terms&Condition")}
            >
              Next
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default SignUpThree;
