import { Input, Select } from "antd";
import React, { useEffect, useState } from "react";

import EditIcon from "../assets/svg/edit_icon.svg";

export default function ProfileInfo({
  readOnly,
  setShowModal,
  inputs,
  setInputs,
  selectedCountry,
  countries,
  maxDate,
}) {
  // const countries = useSelector(store=>store.customerDetails.countryList);
  // const selectedCountry = properties?.country_of_residence;

  // const handleChange = (e, val) => {
  //   setInputs((prev) => {
  //     const newInputs = prev?.map((input) => {
  //       if (input.key === val.key) {
  //         const value = e?.target?.value;
  //         const newInput = { ...input, value };
  //         return newInput;
  //       } else {
  //         return input;
  //       }
  //     });
  //     return newInputs;
  //   });
  // };
  const { Option } = Select;
  const currentDate = new Date();
  currentDate.setFullYear(currentDate?.getFullYear() - 5);
  const formattedDate = currentDate?.toISOString()?.split("T")[0]; // Format to YYYY-MM-DD
  // console.log("format", formattedDate);
  const mDate = formattedDate;
  const handleChange = (e, val) => {
    setInputs((prev) => {
      const newInputs = prev?.map((input) => {
        if (input?.key === val?.key) {
          let value = e?.target?.value;

          if (e && e?.target) {
            const { name } = e?.target;

            if (val?.key === "firstName" || val?.key === "lastName") {
              // Allow letters and a single space
              value = value
                ?.replace(/[^a-zA-Z ]/g, "") // Allow only letters and spaces
                ?.replace(/\s\s+/g, " ") // Replace multiple consecutive spaces with one space
                ?.trimStart(); // Prevent leading spaces

              // Ensure only one space between words
              const spaceCount = (value?.match(/ /g) || [])?.length;
              if (spaceCount > 1) {
                value = value?.replace(/\s+/g, " ").trim();
              }
            }
          } else if (val?.key === "date" || val?.key === "wedding") {
            const dateTime = new Date(value);
            const formattedDate = dateTime.toISOString().split("T")[0];
            value = formattedDate;
          }

          return { ...input, value }; // Update the input value
        } else {
          return input; // Return unchanged input
        }
      });
      return newInputs;
    });
  };

  const handleCountryChange = (value) => {
    // console.log(value);
    setInputs((prev) => {
      return prev.map((input) => {
        if (input.key === "country_of_residence") {
          return { ...input, value };
        }
        return input;
      });
    });
  };
  return (
    <div className="my-6 flex flex-wrap gap-3">
      <div className="flex w-full justify-between">
        <h2 className="obviously mb-4 ml-2">Profile Info</h2>
        <div>
          {readOnly && (
            <img
              src={EditIcon}
              alt="edit icon"
              onClick={() => setShowModal(true)}
            />
          )}
        </div>
      </div>

      {inputs.map((val, index) => {
        if (val.key === "country_of_residence") {
          return (
            <div
              className={`w-[48%] p-1 px-3 shadow-[0_0_3px_#00000080] rounded-3xl phone bg-white flex items-center  gap-1 h-9`}
              style={{ fontSize: "12px" }}
              key={index}
            >
              <label
                htmlFor="country_of_residence"
                className=" phone font-bold roboto text-[14px]"
              >
                {val.title}
              </label>
              {readOnly ? (
                <Input
                  key={index}
                  placeholder={val.placeholder}
                  value={val.value}
                  className={`w-[48%] p-1   phone bg-white focus:outline-none focus:border-transparent custom-select border-none`}
                  readOnly={readOnly}
                />
              ) : (
                <Select
                  name="country_of_residence"
                  className="w-1/2 bg-white text-[11.3px] outline-none sel select-no-toggle gap-2  border-none"
                  disabled={readOnly}
                  value={val.value}
                  placeholder={val.placeholder}
                  onChange={handleCountryChange}
                  removeIcon={null}
                  // suffixIcon={null}
                >
                  {/* <Option value={selectedCountry}>{selectedCountry}</Option> */}
                  {countries.map((country, index) => (
                    <Option
                      key={index}
                      value={country?.country_name}
                      className=" text-sm obviously w-full "
                    >
                      {country?.country_name}
                    </Option>
                  ))}
                </Select>
              )}
            </div>
          );
        } else if (val.key === "dob" || val.key === "wedding") {
          return (
            <Input
              key={index}
              type="date"
              id="date"
              max={mDate}
              name={val.key}
              value={val.value}
              className={`w-[48%] shadow-[0_0_3px_#00000080] rounded-3xl phone bg-white overflow-hidden focus:outline-none focus:border-transparent`}
              readOnly={readOnly}
              onChange={(e) => handleChange(e, val)}
              addonBefore={val.title}
            />
            // <div
            //   className={` w-[48%] p-1 px-3 shadow-[0_0_3px_#00000080] rounded-3xl phone bg-white flex items-center obviously h-9 ${
            //     val.key === "wedding" ? "gap-[2px]" : "gap-4"
            //   }`}
            // >
            //   <label
            //     htmlFor={val.key}
            //     className={` font-[Obviously]  text-[11.3px] pl-1 text-black font-extrabold`}
            //   >
            //     {val.title}
            //   </label>

            //   <Input
            //     id={val.key}
            //     type="date"
            //     name={val.key}
            //     className=" focus:outline-none focus:border-transparent pl-0 bg-white border-none"
            //     onChange={(e) => handleChange(e, val)}
            //     value={val.value}
            //     readOnly={readOnly}
            //     max={mDate}
            //   />
            // </div>
          );
        } else {
          return (
            <Input
              key={index}
              addonBefore={val.title}
              placeholder={val.placeholder}
              value={val.value}
              className={`w-[48%] p-1 shadow-[0_0_3px_#00000080] rounded-3xl phone bg-white focus:outline-none focus:border-transparent custom-select`}
              readOnly={readOnly}
              onChange={(e) => handleChange(e, val)}
            />
          );
        }
      })}
    </div>
  );
}
