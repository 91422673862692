import React, { useState } from "react";
import { Button, Input } from "antd";
import { useTranslation } from "react-i18next";

import CustomModel from "../../components/CustomModel";
import Header from "../../components/Header";
import Wallet from "../../assets/wallet.svg";
import valiramLogo2 from "../../assets/valiram2.svg";
import GiftCard3 from "../../assets/svg/gift-card2.svg";
import GiftCard1Logo from "../../assets/gift-card1-logo.png";
import GiftCard1BG from "../../assets/gift-card1-bg.png";
import crossIcon from "../../assets/crossIcon.svg";
import infoIcon from "../../assets/svg/info.svg";
import rightIcon from "../../assets/right.svg";
import closeIcon from "../../assets/closeIcon.png";

export default function GiftCard() {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);

  function makeDate(isBordered) {
    return (
      <div
        className={`w-28 h-8 text-sm obviously absolute bottom-3 right-2 bg-white text-black rounded-full flex items-center justify-center ${
          isBordered ? "border-[1px] border-solid border-black" : ""
        }`}
      >
        June 2024
      </div>
    );
  }
  return (
    <>
      <div className="px-2 pt-4 fixed top-0 bg-white z-20 w-full">
        <Header showLogo />
        <div className="obviously py-2 px-3 text-xl flex justify-between">
          <h2>{t("giftCard.title")}</h2>
          <img
            src={Wallet}
            alt="Wallet icon"
            onClick={() => setShowModal(true)}
          />
        </div>
      </div>
      <div className="mt-40 mb-16 p-4">
        {Array.from({ length: 2 }).map((_, index) => (
          <div key={index}>
            <div className="h-52 rounded-2xl mb-4 border-[1px] border-solid border-black relative flex flex-col">
              <div
                className=" w-full h-10 rounded-t-2xl bg-center"
                style={{ backgroundImage: `url(${GiftCard1BG})` }}
              ></div>
              <div className="flex flex-col items-start justify-between text-2xl h-[10.4rem] pt-0 p-4 bg-white rounded-b-2xl flex-grow">
                <img src={GiftCard1Logo} alt="Bsth and Body logo" />
                <h1 className="obviously text-[#005598]">RM100</h1>
              </div>
              {makeDate(true)}
            </div>

            <div className="h-52 bg-valiram rounded-2xl p-4  mb-4 flex flex-col items-start justify-between text-2xl relative">
              <img src={valiramLogo2} alt="valiram logo" />
              <h1 className="obviously text-white">RM100</h1>
              {makeDate()}
            </div>
            <div
              className="h-52 rounded-2xl p-4 mb-4 bg-cover flex justify-start items-end text-2xl relative"
              style={{ backgroundImage: `url(${GiftCard3})` }}
            >
              <h1 className="obviously text-white">RM100</h1>
              {makeDate()}
            </div>
          </div>
        ))}
      </div>

      {showModal && (
        <CustomModel>
          <div className="flex flex-col text-black items-center p-4 px-16">
            <div>
              <h1 className="playfair italic text-2xl">
                {t("giftCard.model1.title")}
              </h1>
            </div>
            <button
              className="button  flex items-center justify-center bgBlack my-20"
              onClick={() => {
                setShowModal(false);
                setShowModal2(true);
              }}
            >
              <span className="flex-1 text-center">
                + {t("giftCard.model1.button")}
              </span>
            </button>
            <div className="text-center">
              <p className="playfair italic text-base">
                {t("giftCard.model1.desc")}
              </p>
            </div>
          </div>
          <button
            className="absolute bottom-16"
            onClick={() => setShowModal(false)}
          >
            <img src={crossIcon} alt="icon"></img>
          </button>
        </CustomModel>
      )}
      {showModal2 && (
        <CustomModel>
          <div className="bg-white w-11/12 p-4 rounded-xl obviously flex flex-col gap-4 shadow-[0_0_5px_#0000008a]">
            <div className="px-2">
              <h5 className="">{t("giftCard.model2.title")}</h5>
            </div>
            <Input
              className="rounded-full h-11 shadow-[0_0_1px_#00000080] px-4"
              placeholder="ID"
            />
            <div className="flex items-center gap-2">
              <Input
                className="w-1/2 rounded-full h-11 shadow-[0_0_1px_#00000080] px-4"
                placeholder="PIN"
              />
              <img src={infoIcon} alt="info icon" />
            </div>
            <div className="px-2 my-2">
              <p style={{ fontSize: "8px", lineHeight: "1.2" }}>
                {t("giftCard.model2.desc")}
              </p>
            </div>
            <div>
              <div className="w-full flex  gap-2">
                <Button
                  className="basis-1/2 rounded-full h-11 bg-black text-white flex justify-between px-4 py-6"
                  onClick={() => setShowModal2(false)}
                >
                  <img src={closeIcon} alt="close icon" />
                  <p>{t("giftCard.model2.button1")}</p>
                </Button>
                <Button className="basis-1/2 rounded-full h-11 bg-[#B78600] text-white flex justify-between px-4 py-6">
                  <img src={rightIcon} alt="right icon" />
                  <p>{t("giftCard.model2.button2")}</p>
                </Button>
              </div>
            </div>
          </div>
          <button
            className="absolute bottom-16"
            onClick={() => setShowModal2(false)}
          >
            <img src={crossIcon} alt="icon"></img>
          </button>
        </CustomModel>
      )}
    </>
  );
}
