import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { motion as m } from "framer-motion";
import { useTranslation } from "react-i18next";
import "./styles.css";

import { useAuth } from "../../utils/AuthProvider";

import Header from "../../components/Header";
import AuthService from "../../utils/auth-service";
import { Modal, Spin } from "antd";
import CustomModel from "../../components/CustomModel";

export default function SideMenu({ setBrand }) {
  const navigate = useNavigate();
  const { setIsLoggedIn } = useAuth();

  const staticDivRef = useRef(null);
  const [staticDivHeight, setStaticDivHeight] = useState(0);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  // const handleChange = () => {
  //   if (i18n.resolvedLanguage === "en") {
  //     i18n.changeLanguage("ms");
  //   } else {
  //     i18n.changeLanguage("en");
  //   }
  // };
  useEffect(() => {
    setBrand({});
    const updateAbsoluteDivHeight = () => {
      if (staticDivRef.current) {
        setStaticDivHeight(staticDivRef.current.offsetHeight);
      }
    };
    updateAbsoluteDivHeight();
    window.addEventListener("resize", updateAbsoluteDivHeight);
    return () => {
      window.removeEventListener("resize", updateAbsoluteDivHeight);
    };
    // eslint-disable-next-line
  }, []);
  const handleOpenLogoutModal = () => {
    setOpen(true);

    // Modal.confirm({
    //   title: "Are you sure, you want to log out?",
    //   // content: "You will be redirected to the login page.",
    //   okText: "Logout",
    //   cancelText: "Cancel",
    //   icon: <></>,
    //   style: {
    //     top: "50%",
    //     transform: "translateY(-50%)",
    //   },

    //   className: "custom-modal-class",
    //   onOk: async () => {
    //     setLoading(true);
    //     try {
    //       const res = await AuthService.tokenExpire();
    //       if (res.status.success) {
    //         sessionStorage.removeItem("token");
    //         sessionStorage.removeItem("sessionId");
    //         sessionStorage.removeItem("key");
    //         sessionStorage.removeItem("mobile");
    //         setIsLoggedIn(false);
    //         navigate("/loginpage");
    //       }
    //     } catch (err) {
    //       console.log(err);
    //     } finally {
    //       setLoading(false);
    //     }
    //   },
    //   onCancel() {
    //     // No action needed if the user cancels
    //     return;
    //   },
    // });
  };
  const handleLogoutClick = async () => {
    setLoading(true);
    try {
      const res = await AuthService.tokenExpire();
      if (
        res.status.success ||
        res.status.message.includes("The Token has expired on")
      ) {
        sessionStorage.clear();
        setIsLoggedIn(false);
        navigate("/loginpage");
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  if (loading)
    return (
      <div className="loading-div">
        <Spin size="large" />
      </div>
    );
  return (
    <m.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: "0.3" }}
      ref={staticDivRef}
    >
      <Header showLogo bgColor="#08264A" textColor="#ffffff" />
      <div className="bg-transparent text-white obviously h-[100vh] navigation-bg-image">
        <div className="py-11 px-8">
          <ul className="flex flex-col gap-4 text-base">
            <li onClick={() => navigate("/profile-update")}>
              {t("sideMenu.profileCentre")}
            </li>
            <li onClick={() => navigate("/points-history")}>
              {t("sideMenu.transactionHistory")}
            </li>
            <li onClick={() => navigate("/rewards-list")}>
              {t("sideMenu.rewards")}
            </li>
            {/* <li onClick={() => navigate("/gift-card")}>
              {t("sideMenu.wallet")}
            </li> */}
            <li onClick={() => navigate("/my-favourites")}>
              {t("sideMenu.favourites")}
            </li>
            {/* <li onClick={() => navigate("/notifications")}>
              {t("sideMenu.notifications")}
            </li> */}
            <li onClick={() => navigate("/tier-information")}>
              {t("sideMenu.tierInformation")}
            </li>
            <li onClick={() => navigate("/brands")}>{t("sideMenu.brands")}</li>
            <li onClick={() => navigate("/brands-store-listing")}>
              {t("sideMenu.storeLocations")}
            </li>
            <li onClick={() => navigate("/your-offers")}>Offers</li>
            {/* <li onClick={() => navigate("/refer-a-friend")}>
              {t("sideMenu.refer")}
            </li>
            <li onClick={() => navigate("/valiram-card")}>
              {t("sideMenu.checkIn")}
            </li> */}
            {/* <li onClick={handleChange}>{t("sideMenu.changeLanguage")}</li> */}
          </ul>
        </div>
        <div className="px-4">
          <hr className="border-[#707070]" />
        </div>
        <div className="p-8 pb-20 text-[#F5B916]">
          <ul className="flex flex-col gap-4">
            <li onClick={() => navigate("/customer-support")}>
              {t("sideMenu.help")}
            </li>
            <li onClick={() => navigate("/customer-support")}>
              {t("sideMenu.support")}
            </li>
            {/* <li onClick={() => navigate("/valiram-card")}>
              {t("sideMenu.feedback")}
            </li> */}
            <li onClick={handleOpenLogoutModal}>{t("sideMenu.logout")}</li>
          </ul>
        </div>
        <div
          className="absolute w-full z-[-1] top-0"
          style={{
            height: `${staticDivHeight + 10}px`,
          }}
        >
          <div className="bg-[#08264A] h-[50%]"></div>
          <div className="bg-valiram h-[50%] relative bottom-1 bg-cover"></div>
        </div>
      </div>
      {open && (
        <div className="modalComp">
          <CustomModel>
            <div className="modal-container w-[90%] h-[25%] bg-gray-900 flex flex-col items-center justify-center rounded-2xl">
              <p className="text-slate-300  p-5 playfair italic text-center text-lg">
                Are you sure, you want to log out?
              </p>
              <button
                className="text-white  playfair italic text-lg py-2 px-8 rounded-3xl hover:bg-[#b78600]"
                onClick={handleLogoutClick}
              >
                Log out
              </button>
              <button
                className="text-white  playfair italic bg-blue-600 py-2 px-8 rounded-3xl mt-4 text-lg"
                onClick={() => setOpen(false)}
              >
                Cancel
              </button>
            </div>
          </CustomModel>
        </div>
      )}
    </m.div>
  );
}
