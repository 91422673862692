import React from "react";
import { Avatar } from "antd";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import valiramLogo from "../assets/valiram.svg";
import valiramLogo2 from "../assets/valiram2.svg";

// import userPic from "../assets/user.png";

import { useSelector } from "react-redux";

export default function Header({ bgColor, textColor, showLogo }) {
  const customerData = useSelector(
    (store) => store.customerDetails.customerData
  );
  const navigate = useNavigate();
  const { t } = useTranslation();
  let styles = {};
  if (bgColor || textColor) {
    styles = { ...styles, backgroundColor: bgColor, color: textColor };
  }
  return (
    <div
      className={`${
        showLogo && "flex"
      } items-center justify-between pb-3 pt-8 px-3`}
      style={styles}
    >
      <div className="flex items-center gap-2">
        <Avatar size={50} className="bg-gray-600" />
        <h5
          className={`playfair-bold italic text-lg text-[${
            textColor || "#707070"
          }]`}
          onClick={() => navigate("/profile-update")}
        >
          {t("valiramCard.greeting", { user: customerData.firstname })}
        </h5>
      </div>
      <div>
        {showLogo && (
          <img
            src={bgColor ? valiramLogo2 : valiramLogo}
            alt="valiram logo"
            onClick={() => navigate("/valiram-card")}
          />
        )}
      </div>
    </div>
  );
}
