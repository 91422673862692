import axios from "axios";

const baseUrl = process.env.REACT_APP_StrapiUrl;

// https://d29l6pux0tl1f7.cloudfront.net/api/transcategories

const getTourScreens = async () => {
  try {
    const response = await axios.get(baseUrl + "tutorials");
    if (response.status === 200) {
      const dataObj = response.data;
      return dataObj;
    } else {
      console.log("request failed");
      return [];
    }
  } catch (e) {
    console.log(e);
  }
};

const getHome = async () => {
  try {
    const response = await axios.get(baseUrl + "homes");
    if (response.status === 200) {
      const dataObj = response.data;
      return dataObj;
    } else {
      console.log("request failed");
      return [];
    }
  } catch (e) {
    console.log(e);
  }
};

const getOffers = async () => {
  try {
    const response = await axios.get(baseUrl + "offers");
    if (response.status === 200) {
      const dataObj = response.data;
      return dataObj;
    } else {
      console.log("request failed");
      return [];
    }
  } catch (e) {
    console.log(e);
  }
};

const getTiers = async () => {
  try {
    const response = await axios.get(baseUrl + "abconfigs");
    if (response.status === 200) {
      const dataObj = response.data;
      return dataObj;
    } else {
      console.log("request failed");
      return [];
    }
  } catch (e) {
    console.log(e);
  }
};

const getBrands = async () => {
  try {
    const response = await axios.get(baseUrl + "brandslists");
    if (response.status === 200) {
      const dataObj = response.data;

      return dataObj;
    } else {
      console.log("request failed");
      return [];
    }
  } catch (e) {
    console.log(e);
  }
};

const getBrandByIdAndName = async (id) => {
  // https://d29l6pux0tl1f7.cloudfront.net/api/brandslists?filters[brand_id][$eq]=2
  try {
    const response = await axios.get(
      `${baseUrl}brandslists?filters[brand_id][$eq]=${id}`
    );
    if (response.status === 200) {
      const dataObj = response.data;

      return dataObj;
    } else {
      console.log("request failed");
      return [];
    }
  } catch (e) {
    console.log(e);
  }
};
const getBrandsCategory = async () => {
  try {
    const response = await axios.get(baseUrl + "brandcategories");
    if (response.status === 200) {
      const dataObj = response.data.data[0].attributes.data;

      return dataObj;
    } else {
      console.log("request failed");
      return [];
    }
  } catch (e) {
    console.log(e);
  }
};
const getRewardCatalogCategory = async () => {
  try {
    const response = await axios.get(baseUrl + "marvalcategories");
    if (response.status === 200) {
      const dataObj = response.data.data[0].attributes.data;

      return dataObj;
    } else {
      console.log("request failed");
      return [];
    }
  } catch (e) {
    console.log(e);
  }
};
const getTransactionCategory = async () => {
  try {
    const response = await axios.get(baseUrl + "transcategories");
    if (response.status === 200) {
      const dataObj = response.data.data[0].attributes.data;

      return dataObj;
    } else {
      console.log("request failed");
      return [];
    }
  } catch (e) {
    console.log(e);
  }
};
const getStoresCategory = async () => {
  try {
    const response = await axios.get(baseUrl + "storecategories");
    if (response.status === 200) {
      const dataObj = response.data.data[0].attributes.data;

      return dataObj;
    } else {
      console.log("request failed");
      return [];
    }
  } catch (e) {
    console.log(e);
  }
};

const getTermsAndConditions = async () => {
  try {
    const response = await axios.get(baseUrl + "termsandconditions");
    if (response.status === 200) {
      const dataObj = response.data;
      return dataObj;
    } else {
      console.log("request failed");
      return [];
    }
  } catch (e) {
    console.log(e);
  }
};

const getStoreImages = async () => {
  try {
    const response = await axios.get(baseUrl + "storeimagelists");
    if (response.status === 200) {
      const dataObj = response.data;
      return dataObj;
    } else {
      console.log("request failed");
      return [];
    }
  } catch (e) {
    console.log(e);
  }
};
const getCountryCodeList = async () => {
  try {
    const res = await axios.get(baseUrl + "countrylists");
    if (res.status === 200) {
      const dataObj = res.data;
      return dataObj;
    } else {
      console.log("request failed");
      return [];
    }
  } catch (e) {
    console.log(e);
  }
};
const getFaqs = async () => {
  try {
    const response = await axios.get(baseUrl + "faqs");
    if (response.status === 200) {
      const dataObj = response.data;
      return dataObj;
    } else {
      console.log("request failed");
      return [];
    }
  } catch (e) {
    console.log(e);
  }
};

export {
  getTourScreens,
  getHome,
  getOffers,
  getTiers,
  getBrands,
  getBrandByIdAndName,
  getBrandsCategory,
  getTermsAndConditions,
  getStoreImages,
  getCountryCodeList,
  getTransactionCategory,
  getStoresCategory,
  getFaqs,
  getRewardCatalogCategory,
};
