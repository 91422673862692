import React, { useEffect } from "react";
import { Button, Spin } from "antd";
import bg from "../assets/svg/navigation_bg.svg";

import BackIcon from "../assets/backIcon.svg";

export default function TourGuide({
  tourPage,
  setTourPage,
  tourScreens,
  tourScreensLoading,
}) {
  // useEffect(() => {
  //   if (!tourScreensLoading) {
  //     let timer;
  //     timer = setTimeout(() => {
  //       setTourPage((prev) => prev + 1);
  //     }, 5000);

  //     return () => {
  //       clearTimeout(timer);
  //     };
  //   }
  //   // eslint-disable-next-line
  // }, [tourPage, tourScreensLoading]);

  return (
    <>
      {tourScreensLoading ? (
        <div className="loading-div">
          <Spin size="large" />
        </div>
      ) : (
        <div
          className="fixed w-dvw h-dvh inset-0 z-30 bg-cover bg-center"
          style={{
            backgroundImage: `url(${
              tourScreens?.[tourPage]?.bg_imageURL &&
              tourScreens?.[tourPage]?.bg_imageURL
            })`,
            backgroundPositionY: "-1px",
          }}
        >
          <div className="absolute bottom-0 z-40 text-white p-8 min-w-[100vw]">
            <p className="obviously">{tourScreens?.[tourPage]?.title}</p>
            <p className="playfair italic text-sm mt-8">
              {tourScreens?.[tourPage]?.desc}
            </p>
            <div className="flex justify-between mt-16 items-end">
              <img
                src={BackIcon}
                alt="Back icon"
                onClick={() =>
                  setTourPage((prev) => {
                    if (prev === 0) return 0;
                    return prev - 1;
                  })
                }
              />
              <div className="flex w-1/2 justify-center gap-2">
                {Array.from({ length: 6 }).map((_, index) => (
                  <span
                    key={index}
                    className={`${index === tourPage ? "text-gray-600" : ""}`}
                  >
                    _
                  </span>
                ))}
              </div>
              <Button
                className="playfair italic rounded-full text-[#707070]"
                onClick={() => setTourPage((prev) => prev + 1)}
              >
                Next
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
