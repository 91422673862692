import React from "react";

import LockIcon from "../assets/svg/lock.svg";
import { TruncateSentence } from "../utils/truncate-func";

export default function RewardCatalog({ reward, index, open }) {
  return (
    <>
      <div className="flex z-10 justify-between">
        <h3
          className={`playfair text-3xl italic ${
            index === 0 ? "text-black" : "text-white"
          }`}
          style={{ textShadow: "0 2px 2px #00000026" }}
        >
          {reward.name}
        </h3>
        <div className="flex gap-2">
          {reward.isLocked && (
            <div className="flex items-center z-40">
              <img src={LockIcon} alt="Lock icon" />
            </div>
          )}
          <div
            className="w-20 h-full bg-white text-black rounded-full obviously flex items-center justify-center"
            style={{ fontSize: "11px" }}
          >
            {"V$" + reward.intouchPoints}
          </div>
        </div>
      </div>

      <div className="z-10">
        <p className="playfair mb-2 text-lg text-white">{reward.brandName}</p>
        <p className="obviously text-white" style={{ fontSize: "10px" }}>
          {!open
            ? TruncateSentence(reward?.description, 100)
            : reward?.description}
        </p>
      </div>
    </>
  );
}
