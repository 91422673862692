import { useEffect } from "react";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import "./styles.css";
import valiramLogo2 from "../../assets/valiram2.svg";
import backgroundVideo from "../../assets/videos/background-video.mp4";

function HelloScreen({ setShowNav }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  useEffect(() => {
    setShowNav(false);
    // eslint-disable-next-line
  }, []);

  const customerData = useSelector(
    (store) => store?.customerDetails?.customerData
  );

  useEffect(() => {
    const video = document.getElementById("background-video");

    if (video) {
      video.play().catch((error) => {
        console.log("Video play failed:", error);
      });
    }
  }, []);

  return (
    <div className="helloScreen-container">
      {/* <video autoPlay muted loop className="background-video">
        <source src={backgroundVideo} type="video/mp4"></source>
      </video> */}
      <div className="video-background">
        <video
          className="qVaWx1ia4MK_oyPXz791"
          src={backgroundVideo}
          preload="auto"
          autoPlay
          loop
          muted
          playsInline
        ></video>
      </div>
      {/* <video class="qVaWx1ia4MK_oyPXz791" id="background-video" src={backgroundVideo} preload="auto" autoPlay loop muted playsinline></video> */}
      <div className="overlay"></div>
      <div className="z-[2] flex flex-col items-center gap-24">
        <h1 className="playfair text-[#fff] italic text-4xl">
          {/* {t("helloScreen.hello", { user: customerData.firstname })}! */}
          Hello, {customerData.firstname}!
        </h1>
        <div className="h-[229px] bg-valiram rounded-2xl p-4  mb-4 flex  items-start justify-between text-2xl relative w-[95vmin] flex-wrap">
          <img src={valiramLogo2} alt="valiram logo" className="mt-2" />

          <Button shape="round" className="text-[#707070] italic w-32 h-9">
            <span className="text-[15px] leading-5">
              {customerData["current_slab"]}
            </span>
          </Button>
          {/* <div className="text-[#fff] border border-solid w-[70vmin] "></div>
        <div className="flex flex-col">
          <p className="playfair text-lg text-[#fff] italic">Points Earned</p>
          <p className="text-[#fff] italic text-sm">642</p>
        </div> */}
        </div>
        <button
          type="button"
          className="ProceedButton"
          onClick={() => navigate("/valiram-card")}
        >
          Proceed
        </button>
      </div>
    </div>
  );
}
export default HelloScreen;
