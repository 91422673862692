import React, { useEffect } from "react";
import LogoVideo from "../assets/videos/valiram-logo-splash.mp4";

const SplashScreen = () => {
  useEffect(() => {
    const video = document.querySelector(".qVaWx1ia4MK_oyPXz791");

    if (video) {
      video.play().catch((error) => {
        console.log("Video play failed:", error);
      });
    }
  }, []);
  return (
    <div className="h-[100vh] flex justify-center items-center bg-black absolute z-20">
      <video
        autoPlay
        muted
        playsInline
        preload="auto"
        className="qVaWx1ia4MK_oyPXz791 w-3/5"
      >
        <source src={LogoVideo} type="video/mp4"></source>
      </video>
    </div>
  );
};

export default SplashScreen;
