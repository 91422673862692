import React, { forwardRef } from "react";

import reciptLogo from "../assets/recipt-logo.png";
import valiramLogo from "../assets/valiram-logo.png";

function Recipt(props, ref) {
  const { transaction, userId, userName, lineItems } = props;
  const gst_amt = () => {
    const gstObj = transaction?.custom_fields?.field?.find(
      (item) => item.name === "gst_amount"
    );
    if (gstObj) {
      return parseInt(gstObj.value);
    } else return 0;
  };
  return (
    <div className="pb-4" ref={ref}>
      <div className="flex items-center justify-center">
        <img src={reciptLogo} alt="brand logio" width={150} className="h-20" />
      </div>
      <hr />
      <div
        className="flex font-extrabold py-2"
        style={{ fontSize: "9px", lineHeight: "1.1" }}
      >
        <div className="basis-1/2">
          <p>{transaction?.store}</p>
          <p>{transaction?.store}</p>
          <p>KLIA</p>
          <p>SELANGOR</p>
        </div>
        <div className="basis-1/2 text-right">
          <p>VALIRAM GLOBAL CONCEPTS SDN BHD</p>
          <p>681813-H</p>
        </div>
      </div>
      <div className="mt-3" style={{ fontSize: "10px", lineHeight: "1.1" }}>
        <p>
          INVOICE NO:
          <span className="font-bold">{transaction?.number}</span>
        </p>
        <p>
          DATE:
          <span className="font-bold">{transaction?.billing_time}</span>
        </p>
        <p>
          CUSTOMER ID:<span className="font-bold">{userId}</span>
        </p>
        <p>
          CUSTOMER NAME:<span className="font-bold">{userName}</span>
        </p>
      </div>
      <div className="text-center my-2">
        <h2 className="font-bold" style={{ fontSize: "10px" }}>
          E-INVOICE
        </h2>
      </div>

      <div className="table-cont font-bold" style={{ fontSize: "9px" }}>
        <div className="flex justify-between text-center p-1 bg-[#02183d] text-white">
          <p className="basis-[35%] text-left">ITEM</p>
          <p className="basis-[15%]">QTY</p>
          <p className="basis-[25%]">UNIT PRICE (RM)</p>
          <p className="basis-[25%] text-right">AMOUNT (RM)</p>
        </div>

        {lineItems.map((lineItem, index) => (
          <div
            key={index}
            className="flex justify-between text-center py-2 px-1"
          >
            <div className="basis-[35%] text-left">
              <p>{lineItem?.description}</p>
              <p>{lineItem?.item_code || lineItem?.id}</p>
            </div>
            <p className="basis-[15%]">{lineItem?.qty}</p>
            <p className="basis-[25%]">{lineItem?.rate}</p>
            <p className="basis-[25%] text-right">{lineItem?.amount}</p>
          </div>
        ))}
        {lineItems.length === 0 && (
          <div className="h-6 flex justify-center items-center text-base">
            No line items Available
          </div>
        )}
        <div className="flex justify-between bg-[#02183d] p-1 text-white">
          <p>NET TOTAL</p>
          <p>{transaction?.amount}</p>
        </div>

        <div className="flex justify-between py-2 px-1">
          <p className="basis-[50%] text-left">GST(0%)</p>
          <p className="basis-[25%] text-center">RM</p>
          <p className="basis-[25%] text-right">{gst_amt()}</p>
        </div>
        <div className="flex justify-between py-2 px-1">
          <p className="basis-[50%] text-left">TOTAL AMOUNT</p>
          <p className="basis-[25%] text-center">RM</p>
          <p className="basis-[25%] text-right">{transaction?.amount}</p>
        </div>
      </div>

      <hr className="border-0 h-[1px] bg-black" />
      <div className="py-2" style={{ fontSize: "9px", lineHeight: "1.1" }}>
        <p>Please keep your invoice as proof of purchase.</p>
        <p>Goods sold are non refundable.</p>
        <p>No Exchange or refund on sale items.</p>
      </div>
      <div>
        <div className="flex justify-center items-center">
          <img src={valiramLogo} alt="logo" width={100} />
        </div>
        <div className="text-center text-[#836040]" style={{ fontSize: "8px" }}>
          <p>COPYRIGHT © 2020 VALIRAM. ALL RIGHTS RESERVED.</p>
        </div>
      </div>
    </div>
  );
}

export default forwardRef(Recipt);
