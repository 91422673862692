import { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { getCountryCodeList, getHome, getOffers } from "./APIs/api";
import {
  addCountryList,
  addCustomerData,
  addOffers,
} from "./redux/slices/customerDetails";

import customerService from "./utils/customer-service";
import { AuthProvider } from "./utils/AuthProvider";
import { ProtectedRoute, PublicRoute } from "./utils/RoutesGuard";
import "react-datepicker/dist/react-datepicker.css";

import "./App.css";
import Login from "./pages/login";
import LoginTwo from "./pages/login2";
import LoginPage from "./pages/loginPage";
import SignUpThree from "./pages/signUp3";
import SignUp from "./pages/signUp";
import SignUpTwo from "./pages/signup2";
import TermsAndCondition from "./pages/T&C";
import ForgotPassword from "./pages/forgotPassword";
import UpdatePassword from "./pages/updatePassword";

import HelloScreen from "./pages/hello-screen";
import ValiramCard from "./pages/valiram-card";
import BottomNav from "./components/BottomNav";
import SideMenu from "./pages/side-menu";
import Brands from "./pages/brands";
import BrandPage from "./pages/brand-page";
import PointsHistory from "./pages/points-history";
import MyFavourites from "./pages/my-favourites";
import ProfileUpdate from "./pages/profile-update";
import TierInformation from "./pages/tier-information";
import Notifications from "./pages/notifications";
import GiftCard from "./pages/gift-card";
import ReferAFriend from "./pages/refer-a-friend";
import MembershipScan from "./pages/membership-scan";
import RewardsList from "./pages/rewards-list";
import BrandsStoreListing from "./pages/brands-store-listing";
import StorePage from "./pages/store-page";
import RewardsCatalog from "./pages/rewards-catalog";
import YourOffers from "./pages/your-offers";
import CustomerSupport from "./pages/customer-support";
import CustomerFeedbackForm from "./pages/customer-feedback-from";
// import MKCardTwo from "./pages/mk-card2";
// import MKCard from "./pages/mk-cards";
// import NikeCard from "./pages/nike-card";
// import NewThemeBG from "./assets/new-theme.png"

import FallbackUI from "./components/FallbackUI";
import useIsMobile from "./hooks/useIsMobile";
import { Spin } from "antd";
import FAQ from "./pages/faq";

function App() {
  const customerData = useSelector(
    (store) => store.customerDetails.customerData
  );
  useEffect(() => {
    setCustData(customerData);
  }, [customerData]);
  const [custData, setCustData] = useState(customerData);
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const [showNav, setShowNav] = useState(true);
  const [stores, setStores] = useState([]);
  const [brands, setBrands] = useState([]);
  const [category, setCategory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [storeIds, setStoreIds] = useState([]);
  const [storeImages, setStoreImages] = useState([]);

  // const AuthWrapper = () => {
  //   return isExpired(localStorage.getItem("token")) ? (
  //     <Navigate to="/login" replace />
  //   ) : (
  //     <Outlet />
  //   );
  // };

  const [brand, setBrand] = useState({});
  const [store, setStore] = useState([]);

  useEffect(() => {
    fetchCountries();
  }, []);

  async function fetchCountries() {
    setLoading(true);
    const dataObj = await getCountryCodeList();
    let dataArray;
    if (dataObj) {
      dataArray = dataObj?.data[0]?.attributes?.country?.country_details;
    }
    if (dataArray) {
      const sortedCountries = dataArray.sort((a, b) => a.order - b.order);
      dispatch(addCountryList(sortedCountries));
      // setCountries(sortedCountries);
      // setFilteredCountries(sortedCountries);
      // setSelectedCountry(sortedCountries[0]);
      // setPhoneRegex(sortedCountries[0]?.phoneregex);
    }
    setLoading(false);
  }

  // eslint-disable-next-line

  useEffect(() => {
    if (Object.keys(custData).length === 0) {
      setLoading(true);
      async function getCustomerData() {
        try {
          const getCustomer = await customerService.getCustomer();
          if (getCustomer?.status?.success) {
            dispatch(addCustomerData(getCustomer?.customers?.customer[0]));
          } else {
            // console.log("No user Found, Login Again!!!");
            // console.log(getCustomer.status.message);
          }
        } catch (e) {
          console.log(e);
        }
      }
      getCustomerData();
      setLoading(false);
    }
    async function getData2() {
      const dataObj = await getHome();
      try {
        const dataArray = dataObj?.data?.[0]?.attributes?.data;
        // console.log(dataArray);
        setStores(dataArray?.list?.[0]?.sub_items);
        setBrands(dataArray?.list?.[1]?.sub_items);
        setCategory(dataArray?.list[2]?.sub_items?.[0]?.bgImage);
      } catch (e) {
        console.log(e);
      }
    }
    getData2();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    async function getData() {
      const dataObj = await getOffers();
      const dataArray = dataObj?.data?.[0]?.attributes?.data;

      if (dataArray) {
        dispatch(addOffers(dataArray));
      } else {
      }
    }
    getData();
    // eslint-disable-next-line
  }, []);
  if (loading)
    return (
      <div className="loading-div">
        <Spin size="large" />
      </div>
    );
  return (
    <div
      className="App"
      //  style={{backgroundImage: `url(${NewThemeBG})`}}
    >
      {isMobile ? (
        <AuthProvider>
          <Routes>
            {/* public routes */}
            <Route
              path="/"
              element={
                <PublicRoute>
                  <Login setShowNav={setShowNav} />
                </PublicRoute>
              }
            />
            <Route
              path="/login2"
              element={
                <PublicRoute>
                  <LoginTwo setShowNav={setShowNav} />
                </PublicRoute>
              }
            />
            <Route
              path="/loginpage"
              element={
                <PublicRoute>
                  <LoginPage setShowNav={setShowNav} />
                </PublicRoute>
              }
            />
            <Route
              path="/forgotpassword"
              element={
                <PublicRoute>
                  <ForgotPassword setShowNav={setShowNav} />
                </PublicRoute>
              }
            />
            <Route
              path="/updatepassword"
              element={
                <PublicRoute>
                  <UpdatePassword setShowNav={setShowNav} />
                </PublicRoute>
              }
            />
            <Route
              path="/signup"
              element={
                <PublicRoute>
                  <SignUp setShowNav={setShowNav} />
                </PublicRoute>
              }
            ></Route>
            <Route
              path="/signup2"
              element={
                <PublicRoute>
                  <SignUpTwo setShowNav={setShowNav} />
                </PublicRoute>
              }
            />
            <Route
              path="/signup3"
              element={
                <PublicRoute>
                  <SignUpThree setShowNav={setShowNav} />
                </PublicRoute>
              }
            />
            <Route
              path="/terms&condition"
              element={
                <PublicRoute>
                  <TermsAndCondition setShowNav={setShowNav} />
                </PublicRoute>
              }
            ></Route>

            {/* protected routes */}
            <Route
              path="/faq"
              element={
                <ProtectedRoute>
                  <FAQ setShowNav={setShowNav} />
                </ProtectedRoute>
              }
            ></Route>
            <Route
              path="/hello-screen"
              element={
                <ProtectedRoute>
                  <HelloScreen setShowNav={setShowNav} />
                </ProtectedRoute>
              }
            ></Route>
            <Route
              path="/valiram-card"
              element={
                <ProtectedRoute>
                  <ValiramCard
                    setShowNav={setShowNav}
                    stores={stores}
                    brands={brands}
                    category={category}
                    setBrand={setBrand}
                    setStoreIds={setStoreIds}
                  />
                </ProtectedRoute>
              }
            />
            <Route
              path="/side-menu"
              element={
                <ProtectedRoute>
                  <SideMenu setBrand={setBrand} />
                </ProtectedRoute>
              }
            />
            <Route
              path="/brands"
              element={
                <ProtectedRoute>
                  <Brands setBrand={setBrand} />
                </ProtectedRoute>
              }
            />
            <Route
              path="/brand-page/:brandId"
              element={
                <ProtectedRoute>
                  <BrandPage setBrand={setBrand} setStoreIds={setStoreIds} />
                </ProtectedRoute>
              }
            />
            <Route
              path="/points-history"
              element={
                <ProtectedRoute>
                  <PointsHistory />
                </ProtectedRoute>
              }
            />
            <Route
              path="/my-favourites"
              element={
                <ProtectedRoute>
                  <MyFavourites
                    setStoreIds={setStoreIds}
                    setStoreImages={setStoreImages}
                    storeImages={storeImages}
                  />
                </ProtectedRoute>
              }
            />
            <Route
              path="/profile-update"
              element={
                <ProtectedRoute>
                  <ProfileUpdate />
                </ProtectedRoute>
              }
            />
            <Route
              path="/tier-information"
              element={
                <ProtectedRoute>
                  <TierInformation />
                </ProtectedRoute>
              }
            />
            <Route
              path="/notifications"
              element={
                <ProtectedRoute>
                  <Notifications />
                </ProtectedRoute>
              }
            />
            <Route
              path="/gift-card"
              element={
                <ProtectedRoute>
                  <GiftCard />
                </ProtectedRoute>
              }
            />
            <Route
              path="/refer-a-friend"
              element={
                <ProtectedRoute>
                  <ReferAFriend />
                </ProtectedRoute>
              }
            />
            <Route
              path="/membership-scan"
              element={
                <ProtectedRoute>
                  <MembershipScan />
                </ProtectedRoute>
              }
            />
            <Route
              path="/rewards-list"
              element={
                <ProtectedRoute>
                  <RewardsList />
                </ProtectedRoute>
              }
            />
            <Route
              path="/brands-store-listing"
              element={
                <ProtectedRoute>
                  <BrandsStoreListing
                    brand={brand}
                    setStore={setStore}
                    storeIds={storeIds}
                    setStoreImages={setStoreImages}
                    storeImages={storeImages}
                  />
                </ProtectedRoute>
              }
            />
            <Route
              path="/store-page"
              element={
                <ProtectedRoute>
                  <StorePage store={store} storeImages={storeImages} />
                </ProtectedRoute>
              }
            />
            <Route
              path="/rewards-catalog"
              element={
                <ProtectedRoute>
                  <RewardsCatalog />
                </ProtectedRoute>
              }
            />
            <Route
              path="/your-offers"
              element={
                <ProtectedRoute>
                  <YourOffers
                    setStoreIds={setStoreIds}
                    setStoreImages={setStoreImages}
                    storeImages={storeImages}
                  />
                </ProtectedRoute>
              }
            />
            <Route
              path="/customer-support"
              element={
                <ProtectedRoute>
                  <CustomerSupport />
                </ProtectedRoute>
              }
            />
            <Route
              path="/customer-feedback-form"
              element={
                <ProtectedRoute>
                  <CustomerFeedbackForm />
                </ProtectedRoute>
              }
            />
          </Routes>
          <BottomNav showNav={showNav} />
        </AuthProvider>
      ) : (
        <FallbackUI />
      )}
    </div>
  );
}

export default App;
