import React from "react";

import Filters from "../../components/Filters";
import Header2 from "../../components/Header2";
import HomeIcon from "../../assets/home-icon.svg";
import NotificationsIcon from "../../assets/notification.svg";

export default function Notifications() {
  // const { t } = useTranslation();
  const notifications = [
    // {
    //   title: "Valiram+ Cash Back",
    //   description:
    //     "You have earned V$10 from Victorias Secret Pavilion Kuala Lumpur",
    //   date: "July 24th, 2024",
    //   isRead: true,
    // },
    // {
    //   title: "Valiram+ Cash Back",
    //   description:
    //     "You have earned V$10 from Victorias Secret Pavilion Kuala Lumpur",
    //   date: "July 24th, 2024",
    //   isRead: true,
    // },
    // {
    //   title: "Valiram+ Cash Back",
    //   description:
    //     "You have earned V$10 from Victorias Secret Pavilion Kuala Lumpur",
    //   date: "July 24th, 2024",
    //   isRead: true,
    // },
    // {
    //   title: "Valiram+ Cash Back",
    //   description:
    //     "You have earned V$10 from Victorias Secret Pavilion Kuala Lumpur",
    //   date: "July 24th, 2024",
    //   isRead: false,
    // },
    // {
    //   title: "Valiram+ Cash Back",
    //   description:
    //     "You have earned V$10 from Victorias Secret Pavilion Kuala Lumpur",
    //   date: "July 24th, 2024",
    //   isRead: false,
    // },
    // {
    //   title: "Valiram+ Cash Back",
    //   description:
    //     "You have earned V$10 from Victorias Secret Pavilion Kuala Lumpur",
    //   date: "July 24th, 2024",
    //   isRead: false,
    // },
    // {
    //   title: "Valiram+ Cash Back",
    //   description:
    //     "You have earned V$10 from Victorias Secret Pavilion Kuala Lumpur",
    //   date: "July 24th, 2024",
    //   isRead: false,
    // },
  ];
  return (
    <div className="bg-white">
      <div className="fixed p-2 top-0 bg-white z-10 w-full">
        <Header2 />
        <div className="obviously py-2 text-lg pl-3">
          <h2>Activity</h2>
        </div>
        {notifications.length !== 0 && <Filters />}
      </div>
      <div className="p-2 mt-44">
        <div className="mb-20">
          {notifications?.map((notification, index) => (
            <div
              key={index}
              className={`flex h-24 rounded-2xl shadow-[0_3px_6px_#00000029] my-3 px-4 pb-2 pt-4 items-center gap-2 relative ${
                notification.isRead ? "text-black" : "text-[#A0A0A0]"
              }`}
            >
              <div className="basis-1/5">
                <div
                  className={`${
                    notification?.isRead ? "bg-[#08264A]" : "bg-[#CBCBCB]"
                  } rounded-full w-12 h-12 relative`}
                >
                  <img
                    src={HomeIcon}
                    alt="home icon"
                    className="w-8 relative left-[11px] bottom-[7px]"
                  />
                </div>
              </div>
              <div className="basis-4/5 obviously">
                <h5 className="text-sm">{notification.title}</h5>
                <p className="text-xs" style={{ fontSize: "9px" }}>
                  {notification?.description}
                </p>
              </div>
              <div className="flex absolute right-2 top-2 items-center gap-1">
                <p className="obviously" style={{ fontSize: "8px" }}>
                  {notification?.date}
                </p>
                <div
                  className={`${
                    notification?.isRead ? "bg-[#09264A]" : "bg-[#B0B1B2]"
                  } w-2 h-2 rounded-full`}
                ></div>
              </div>
            </div>
          ))}
        </div>
        {notifications?.length === 0 && (
          <div className="text-center">
            <img
              src={NotificationsIcon}
              alt="notification icon"
              className="mx-auto"
            />
            <h2 className="text-xl obviously mt-6 mb-4">
              No notifications yet.
            </h2>
            <p className="text-sm playfair italic mx-4">
              Your notifications will appear here once you have received them.
            </p>
          </div>
        )}
      </div>
    </div>
  );
}
