import { Drawer } from "antd";
import React, { useState } from "react";

import crossIcon from "../assets/crossIcon.svg";
import BlackCatalog from "../assets/catalog.svg";
// import Iframe from "react-iframe";
// import  WebView  from "react-webview";
import Frame from "react-frame-component";

export default function CatalogDrawer({
  open2,
  onClose2,
  brandName,
  cat_link,
}) {
  const [isLoaded, setIsLoaded] = useState(true);
  return (
    <Drawer
      placement="bottom"
      closable={false}
      onClose={onClose2}
      open={open2}
      getContainer={false}
      maskClassName={true}
      width={900}
      // style={{ height: "115%" }}
      // rootStyle={{ width: "94%", margin: "0 auto", outline: "none" }}
      // height="90.5vh"
      // // borderRadius="1rem"
      rootClassName="hell"
      style={{
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20,
        position: "fixed",
        bottom: 0,
        left: "50%",
        right: 0,
        zIndex: 10,
        width: "92%",
        height: "96.5%",
        transform: "translateX(-50%)",
        transition: "all 0.3s ease",
      }}
      rootStyle={{ width: "96%", margin: "0 auto", outline: "none" }}
      // height="98.5vh"
      borderRadius="1rem"
      styles={{
        body: { backgroundColor: "#fff" },
        mask: { pointerEvents: "auto" },
      }}
      className="drawerOverlay rounded-t-2xl rounded-tr-2xl"
      maskClosable={false}
    >
      <div className="bg-white h-full pt-4" style={{ borderRadius: "1rem" }}>
        <div className="absolute  right-2 top-2">
          <img src={crossIcon} alt="cross icon" onClick={onClose2} />
        </div>
        <div className="flex gap-4 items-center justify-between">
          <div className="flex gap-3">
            <img src={BlackCatalog} alt="Catalog icon" />
            <p className="text-base obviously">Catalog</p>
          </div>
          <p className="text-lg obviously ">{brandName}</p>
        </div>
        <div className="h-4/5 py-3">
          {!isLoaded && <p>Failed to load the website.</p>}
          <iframe
            className="h-full w-full"
            src={cat_link}
            title="brands website"
            onLoad={() => setIsLoaded(true)}
            onError={() => setIsLoaded(false)}
          ></iframe>
          {/* <WebView src={cat_link} /> */}
        </div>
      </div>
    </Drawer>
  );
}
