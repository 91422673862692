import { Button } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import EditIcon from "../assets/svg/edit_icon.svg";
import { useNavigate } from "react-router-dom";

export default function FieldSelection({
  fields,
  fieldsTitle,
  brandsYouLove,
  setShowCommunicationModal,
  setshowConnectedAccountsModal,
}) {
  const { t } = useTranslation();
  const [showMore, setShowMore] = useState(false);
  const navigate = useNavigate();
  const handleEditIcon = () => {
    if (fieldsTitle === "Brands You Love") {
      navigate("/brands");
    } else if (fieldsTitle === "Communication Preferences") {
      setShowCommunicationModal(true);
    } else if (fieldsTitle === "Connected Accounts") {
      setshowConnectedAccountsModal(true);
    }
  };

  const isBrandInLove = (brandName) => {
    return brandsYouLove?.some(
      (brand) =>
        brand?.name?.toLowerCase() === brandName?.toLowerCase() &&
        brand.value === "true"
    );
  };
  const handleMoreClick = () => {
    setShowMore((prev) => !prev);
  };
  const itemsToShow = showMore ? fields.length : 6;
  const conditionalClasses = (field) => {
    if (fieldsTitle === "Brands You Love") {
      return isBrandInLove(field?.attributes?.custom_f)
        ? "text-black"
        : "text-[#ADA1A1]";
    } else if (fieldsTitle === "Communication Preferences") {
      return field?.priority[0]?.user_preference === "SUBSCRIBED"
        ? "text-[#000]"
        : "text-[#ADA1A1]";
    } else if (fieldsTitle === "Connected Accounts") {
      return field?.value === "true" ? "text-black" : "text-[#ADA1A1]";
      // Default case if needed
    }
    return "text-[#ADA1A1]";
  };
  return (
    <>
      <div className="mt-6 flex flex-wrap gap-3">
        <div className="flex w-full justify-between">
          <h2 className="obviously mb-2 ml-2 w-full">{fieldsTitle}</h2>
          <div>
            <img src={EditIcon} alt="edit icon" onClick={handleEditIcon} />
          </div>
        </div>

        {fields?.slice(0, itemsToShow)?.map((field, index) => (
          <div
            key={index}
            className={`w-[48%] p-1 shadow-[0_0_3px_#00000080] rounded-3xl phone font-extrabold h-9 text-xs flex justify-center items-center obviously ${conditionalClasses(
              field
            )}
                  
                }`}
            style={{ fontSize: "10px" }}
          >
            {fieldsTitle === "Brands You Love"
              ? field?.attributes?.brandName
              : field.name}
          </div>
        ))}
      </div>
      {fields?.length > 6 && (
        <div className="text-center mt-3 mb-6">
          <button
            className=" w-28 p-3 playfair italic rounded-3xl bg-[#B78600] text-white"
            onClick={handleMoreClick}
          >
            {showMore ? "View Less" : t("profileUpdate.viewMore")}
          </button>
        </div>
      )}
    </>
  );
}
