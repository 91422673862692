import { configureStore } from "@reduxjs/toolkit";
import customerDetailsReducer from "./slices/customerDetails";

const appStore = configureStore({
  reducer: {
    customerDetails: customerDetailsReducer,
  },
});

export default appStore;
