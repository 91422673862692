import { useEffect, useRef, useState } from "react";
import { Spin } from "antd";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import AuthService from "../../utils/auth-service.js";

import "./styles.css";

import backIcon from "../../assets/backIcon.svg";
import backgroundVideo from "../../assets/videos/background-video.mp4";

import MobileNumberInput from "../../components/MobileNumberInput.js";
import axios from "axios";

// const lngs = [
//   ["en", "English"],
//   ["ms", "Malay"],
// ];

function SignUp({ setShowNav }) {
  const wrapperApi = process.env.REACT_APP_WrapperAPI;

  const { t } = useTranslation();
  // const handleChange = (value) => {
  //   i18n.changeLanguage(value);
  // };
  const navigate = useNavigate();
  const [mobile, setMobile] = useState("");
  const [countryCode, setCountryCode] = useState(null);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [phoneRegex, setPhoneRegex] = useState("");
  const videoRef = useRef(null);
  const [videoLoaded, setVideoLoaded] = useState(false);

  useEffect(() => {
    setShowNav(false);
    // eslint-disable-next-line
  }, []);
  function generatePassword(length = 6) {
    const characters = "abcdefghijklmnopqrstuvwxyz";
    let password = "";

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      password += characters[randomIndex];
    }

    return password;
  }
  async function functiontoNavigateUser(res) {
    setLoading(true);
    try {
      const tokenRes = await AuthService.tokenGenerate(generatePassword());
      setLoading(false);
      if (tokenRes.status.success) {
        if (tokenRes?.user?.userRegisteredForPassword && res.status) {
          navigate("/loginpage");
        } else if (!tokenRes?.user?.userRegisteredForPassword && !res.status) {
          navigate("/signup3");
        } else {
          sessionStorage.setItem("crm", true);
          navigate("/updatePassword");
        }
      }
    } catch (err) {
      console.log("Error while generating token", err);
    }
  }

  const handlePhoneSubmit = async (e) => {
    e.preventDefault();
    const regex = new RegExp(phoneRegex);
    if (mobile?.length === 0) {
      setError("Please enter the number");
      return;
    } else if (!regex?.test(mobile)) {
      setError("Enter a valid phone number");
      return;
    }
    sessionStorage.setItem("phone", mobile);
    const fullNum = countryCode + mobile;
    // console.log(mobile);
    sessionStorage.setItem("countryCode", countryCode);

    sessionStorage.setItem("mobile", fullNum);
    setLoading(true);
    try {
      const body = {
        mobile: fullNum,
      };
      const wrapperUrl = `${wrapperApi}wrapperToken`;
      const res = await axios.post(wrapperUrl, body);
      setLoading(false);
      if (res?.data?.token) {
        try {
          const headers = {
            Authorization: `Bearer ${res?.data?.token}`,
          };
          setLoading(true);
          const wrapperUrl = `${wrapperApi}check-user-exist`;
          const response = await axios.get(wrapperUrl, { headers });
          setLoading(false);
          functiontoNavigateUser(response.data);
        } catch (err) {
          console.log("Error checking user", err);
        }
      }
    } catch (error) {
      console.error("Error while generating token:", error);
      throw error;
    }
  };

  useEffect(() => {
    const video = videoRef.current;
    // const video = document.querySelector(".qVaWx1ia4MK_oyPXz791");

    if (video) {
      video.load();
      video.play().catch((error) => {
        console.log("Video play failed:", error);
      });
    }
    setVideoLoaded(true);
    return () => {
      // Optionally store the current video state before unmounting
      if (video) {
        setVideoLoaded(false);
      }
    };
  }, []);

  if (loading)
    return (
      <div className="loading-div">
        <Spin size="large" />
      </div>
    );
  return (
    <div className="signup-container p-2">
      {/* <video autoPlay muted loop className="background-video">
        <source src={backgroundVideo} type="video/mp4"></source>
      </video> */}
      <div className="video-background">
        <video
          className="qVaWx1ia4MK_oyPXz791"
          src={backgroundVideo}
          ref={videoRef}
          preload="auto"
          autoPlay={videoLoaded}
          loop
          muted
          playsInline
        ></video>
      </div>
      {/* <video id="background-video" class="qVaWx1ia4MK_oyPXz791" src={backgroundVideo} preload="auto" autoPlay loop muted playsinline></video> */}
      <div className="back-button-container justify-between items-center">
        <button className="back-button" onClick={() => navigate(-1)}>
          <img src={backIcon} alt="back-button"></img>
        </button>
        {/* <Select
          defaultValue={i18n.resolvedLanguage}
          style={{ width: 150 }}
          onChange={handleChange}
          options={lngs.map((lng) => {
            return { value: lng[0], label: lng[1] };
          })}
        /> */}
      </div>
      <div className="overlay"></div>
      <form className="signup-content" onSubmit={(e) => handlePhoneSubmit(e)}>
        <h1 className="title">Enter your mobile number</h1>
        {/* <Input
          type="tel"
          placeholder="+60 12 385 XXXX"
          className="input-field"
          value={mobile}
          onChange={(e) => setMobile(e.target.value)}
        /> */}
        <MobileNumberInput
          setError={setError}
          phoneNo={mobile}
          setPhoneNo={setMobile}
          setCountryCode={setCountryCode}
          setPhoneRegex={setPhoneRegex}
        />
        {/* {error && <p className="text-[#ff0000] text-center mt-3">{error}</p>} */}
        {error && (
          <p
            style={{
              color: "white",
              textAlign: "center",
              fontFamily: "Obviously",
            }}
            className="text-[10.5px] leading-3 mb-3"
          >
            <span
              style={{
                borderBottom: "2px solid #A00808",
                textDecoration: "none",
              }}
            >
              {error}
            </span>
          </p>
        )}
        <p className="subtitle ">
          For authentication purposes we will be sending you a SMS Verification
          code OTP.
        </p>
        <div className="mt-24 flex justify-center items-center">
          <button className="button" disabled={!!error}>
            {t("signup.next")}
          </button>
        </div>
      </form>
    </div>
  );
}

export default SignUp;
