import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Filters from "../../components/Filters";
// import Header2 from "../../components/Header2";
import { useDispatch, useSelector } from "react-redux";
import { getBrands, getBrandsCategory } from "../../APIs/api";
import { addBrandList } from "../../redux/slices/customerDetails";
import { Spin } from "antd";
import Header from "../../components/Header";
import Header2 from "../../components/Header2";

export default function Brands() {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const navigate = useNavigate();
  const brands = useSelector((store) => store?.customerDetails?.brandList);

  const [renderedBrands, setRenderedBrands] = useState(brands);

  useEffect(() => {
    setRenderedBrands(brands);
    // eslint-disable-next-line
  }, [brands]);

  const [categories, setCategories] = useState([]);

  // useEffect(() => {
  //   if (brands?.brand_list) {
  //     setRenderedBrands(brands.brand_list);
  //   }
  // }, [brands]);

  useEffect(() => {
    setLoading(true);
    async function getData() {
      const dataObj = await getBrands();
      setLoading(false);
      const dataArray = dataObj?.data;

      // console.log(dataArray);
      if (dataArray !== undefined) {
        dispatch(addBrandList(dataArray));
      } else {
        //brandopt
      }
    }
    getData();

    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    async function getBrandsCategoryData() {
      const dataObj = await getBrandsCategory();
      const dataArray = dataObj;

      // console.log(dataArray);
      if (dataArray !== undefined) {
        setCategories(dataArray);
      } else {
        //brandopt
      }
    }
    getBrandsCategoryData();
    // eslint-disable-next-line
  }, []);

  // const brandsopt = [
  //   {
  //     brandName: "Michael Kors",
  //     store_ids: [1, 2, 3],
  //     img: brand1Pic,
  //   },
  //   {
  //     brandName: "Tory Burch",
  //     store_ids: [1, 2, 3, 4],
  //     img: brand2Pic,
  //   },
  //   {
  //     brandName: "Victoria’s Secret",
  //     store_ids: [1, 3],
  //     img: brand3Pic,
  //   },
  //   {
  //     brandName: "Bath & Body Works",
  //     store_ids: [1, 2, 3],
  //     img: brand4Pic,
  //   },
  //   {
  //     brandName: "Godiva",
  //     store_ids: [1, 2, 3],
  //     img: brand5Pic,
  //   },
  // ];

  const handleClick = (brand) => {
    navigate("/brand-page/" + brand?.attributes?.brand_id);
  };
  if (loading)
    return (
      <div className="loading-div">
        <Spin size="large" />
      </div>
    );

  return (
    <div className="p-2 min-h-dvh">
      <Header2 />
      {/* <Header showLogo></Header> */}
      {/* <div className="obviously py-2 text-lg pl-3">
        <h2>{t("sideMenu.brands")}</h2>
      </div> */}
      <Filters
        categoryList={categories}
        setRenderedBrands={setRenderedBrands}
        brands={brands}
      />
      <div className="brandlist pb-20">
        <div className="brandlist-container pt-5">
          {renderedBrands?.map((brand, index) => (
            <div
              key={index}
              className="flex h-24 rounded-2xl shadow-lg mb-3 bg-white"
              onClick={() => handleClick(brand)}
            >
              <div className="basis-1/2 flex justify-center items-center">
                <img
                  src={brand?.attributes?.img}
                  alt="brand logo"
                  width={110}
                  className="max-h-[100%] "
                />
              </div>
              <div className="basis-1/2 flex justify-center items-start flex-col obviously">
                <h4 className="text-sm">{brand?.attributes?.brandName}</h4>
                <p className="text-xs" style={{ fontSize: "9px" }}>
                  {brand?.attributes?.store_ids?.store_ids?.length}{" "}
                  {t("brands.stores")}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
      {renderedBrands?.length === 0 && (
        <div className="flex justify-center">No Brands Available</div>
      )}
      {/* <div
        className="text-center mt-6 fixed bottom-24 left-1/2"
        style={{ transform: "translate(-50%,-50%)" }}
      >
        <Button
          shape="round"
          className="bg-black text-white border-0 playfair italic"
        >
          {t("brands.map")}
        </Button>
      </div> */}
    </div>
  );
}
