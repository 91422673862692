import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
// import { Select } from "antd";

import "./styles.css";
import SplashScreen from "../../components/SplashScreen";
import logo from "../../assets/svg/logo.svg";

import backgroundVideo from "../../assets/videos/background-video.mp4";

// const lngs = [
//   ["en", "English"],
//   ["ms", "Malay"],
// ];

function Login({ setShowNav }) {
  const { t } = useTranslation();
  const videoRef = useRef(null);
  const [videoLoaded, setVideoLoaded] = useState(false);
  // const { t, i18n } = useTranslation();
  // const handleChange = (value) => {
  //   i18n.changeLanguage(value);
  // };
  const [isLoading, setIsLoading] = useState(!sessionStorage.getItem("logo"));
  const navigate = useNavigate();
  useEffect(() => {
    setShowNav(false);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (sessionStorage.getItem("logo") === null) {
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
      }, 5000);
      sessionStorage.setItem("logo", true);
    }
    // return () => clearTimeout(timer);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // const video = document.querySelector(".qVaWx1ia4MK_oyPXz791");
    const video = videoRef.current;
    if (video) {
      video.load();
      video.play().catch((error) => {
        console.log("Video play failed:", error);
      });
    }
    setVideoLoaded(true);
    return () => {
      // Optionally store the current video state before unmounting
      if (video) {
        setVideoLoaded(false);
      }
    };
  }, []);

  return (
    <>
      {isLoading ? (
        <SplashScreen />
      ) : (
        <div className="login-container">
          {/* <video autoPlay muted loop className="background-video">
            <source src={backgroundVideo} type="video/mp4"></source>
          </video> */}
          <div className="video-background">
            <video
              className="qVaWx1ia4MK_oyPXz791"
              src={backgroundVideo}
              preload="auto"
              autoPlay={videoLoaded}
              loop
              muted
              ref={videoRef}
              playsInline
            ></video>
          </div>
          {/* <div className="back-button-container justify-between items-center">
            <button className="back-button" onClick={() => navigate(-1)}>
              <img src={backIcon} alt="back-button"></img>
            </button>
            <Select
              defaultValue={i18n.resolvedLanguage}
              style={{ width: 150 }}
              onChange={handleChange}
              options={lngs.map((lng) => {
                return { value: lng[0], label: lng[1] };
              })}
            />
          </div> */}
          <div className="overlay"></div>
          <div
            className="login-content h-4/5"
            onClick={() => navigate("/login2")}
          >
            <div className="logo-container">
              <img src={logo} className="logo" alt="logo"></img>
            </div>
            <h1 className="heading">The Best of Retail Starts here</h1>
            <p className="playfair italic text-[16px] text-[#E0DCDC] text-center leading-[18px] mt-[40px] ">
              {t("landing.desc")}
            </p>
            <div className="button-wrapper">
              <button
                className="button signup"
                onClick={() => navigate("/login2")}
              >
                {t("login2.button4")}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
export default Login;
