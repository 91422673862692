import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useState, useEffect, useRef } from "react";
import { Spin } from "antd";

import { getTermsAndConditions } from "../../APIs/api";
import AuthService from "../../utils/auth-service";

import "./styles.css";
import backIcon from "../../assets/backIcon.svg";
import backgroundVideo from "../../assets/videos/background-video.mp4";

// const lngs = [
//   ["en", "English"],
//   ["ms", "Malay"],
// ];

function TermsAndCondition({ setShowNav }) {
  const [htmlContent, setHtmlContent] = useState("");
  const { t } = useTranslation();
  const videoRef = useRef(null);
  const [videoLoaded, setVideoLoaded] = useState(false);

  // const handleChange = (value) => {
  //   i18n.changeLanguage(value);
  // };
  const navigate = useNavigate();
  useEffect(() => {
    setShowNav(false);
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    async function getData() {
      setLoading(true);
      const dataObj = await getTermsAndConditions();
      const dataArray = dataObj?.data?.[0]?.attributes?.contents;
      const bodyMatch = dataArray?.match(/<body[^>]*>([\s\S]*?)<\/body>/);
      const body = bodyMatch ? bodyMatch[1] : "";
      const cleanedBody = body?.replace(/\\n/g, "").replace(/\\"/g, '"');
      // Set the state
      setHtmlContent(cleanedBody);
      if (dataArray !== undefined) {
        // setBrands(dataArray);
      } else {
        // setBrands(brandsopt);
      }
      setLoading(false);
    }
    getData();
    // eslint-disable-next-line
  }, []);

  // console.log(htmlContent);

  // const [isPolicyAccepted, setIsPolicyAccepted] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const handleAcceptPolicy = async (e) => {
    e.preventDefault();
    // if (!isPolicyAccepted) {
    //   alert("It is required to accept the terms and Conditions");
    //   return;
    // }
    const updateStatus = sessionStorage.getItem("crm") === "true";
    if (updateStatus) {
      // sessionStorage.removeItem("crm");
      navigate("/signup2");
    } else {
      try {
        setLoading(true);
        const user = JSON.parse(sessionStorage.getItem("user"));

        const tokenRes = await AuthService.tokenGenerate(user.password);
        if (tokenRes.status.success) {
          sessionStorage.setItem("updatePassword", false);
          navigate("/signup2");
        } else {
          setError(tokenRes.status.message);
          console.log(tokenRes.status.message);
        }
      } catch (tokenErr) {
        console.log("Token Generation Failed", tokenErr.message);
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    // const video = document.querySelector(".qVaWx1ia4MK_oyPXz791");
    const video = videoRef.current;
    if (video) {
      video.load();
      video.play().catch((error) => {
        console.log("Video play failed:", error);
      });
    }
    setVideoLoaded(true);
    return () => {
      // Optionally store the current video state before unmounting
      if (video) {
        setVideoLoaded(false);
      }
    };
  }, []);

  if (loading)
    return (
      <div className="loading-div">
        <Spin size="large" />
      </div>
    );
  return (
    <div className="terms-container">
      {/* <video autoPlay muted loop className="background-video">
        <source src={backgroundVideo} type="video/mp4"></source>
      </video> */}
      <div className="video-background">
        <video
          className="qVaWx1ia4MK_oyPXz791"
          src={backgroundVideo}
          preload="auto"
          autoPlay={videoLoaded}
          loop
          muted
          ref={videoRef}
          playsInline
        ></video>
      </div>
      {/* <video id="background-video" class="qVaWx1ia4MK_oyPXz791" src={backgroundVideo} preload="auto" autoPlay loop muted playsinline></video> */}
      <div className="back-button-container justify-between items-center new">
        <button className="back-button" onClick={() => navigate(-1)}>
          <img src={backIcon} alt="back-button"></img>
        </button>
        {/* <Select
          defaultValue={i18n.resolvedLanguage}
          style={{ width: 150 }}
          onChange={handleChange}
          options={lngs.map((lng) => {
            return { value: lng[0], label: lng[1] };
          })}
        /> */}
      </div>
      <div className="overlay"></div>
      <div className="terms-content py-8 playfair italic">
        {/* Inject CSS into the head */}
        {/* {cssContent && <style>{cssContent}</style>} */}
        <h1 className="title ">{t("termsAndCondition.title")}</h1>

        {/* <p className="subtitle">{t("termsAndCondition.desc")}</p>
        <p>{t("termsAndCondition.para1")}</p> */}
        {/* Render the HTML content */}
        <div
          dangerouslySetInnerHTML={{ __html: htmlContent }}
          className="tNc"
        />

        {/* <p>{t("termsAndCondition.para2")}</p>
        <p>{t("termsAndCondition.para3")}</p>
        <p>{t("termsAndCondition.para4")}</p>
        <p>{t("termsAndCondition.para5")}</p>
        <p>{t("termsAndCondition.para6")}</p>
        <h4>{t("termsAndCondition.section1Title")}</h4>
        <h4>{t("termsAndCondition.section2Title")}</h4>
        <h4>{t("termsAndCondition.section3Title")}</h4>
        <h4>{t("termsAndCondition.section4Title")}</h4>
        <h4>{t("termsAndCondition.section5Title")}</h4>
        <h4>{t("termsAndCondition.section6Title")}</h4>
        <h4>{t("termsAndCondition.section7Title")}</h4>
        <p>{t("termsAndCondition.section1desc")}</p>
        <p>{t("termsAndCondition.section2desc")}</p>
        <p>{t("termsAndCondition.section3desc")}</p>
        <p>{t("termsAndCondition.section4desc")}</p>
        <p>{t("termsAndCondition.section5desc")}</p>
        <p>{t("termsAndCondition.section6desc")}</p>
        <p>{t("termsAndCondition.section7desc")}</p> */}
        {/* <button
          className={isPolicyAccepted ? "accepted" : "agree"}
          onClick={() => setIsPolicyAccepted(true)}
        >
          {t("termsAndCondition.agree")}
        </button> */}
        <button
          type="submit"
          className="button"
          onClick={(e) => handleAcceptPolicy(e)}
        >
          AGREE
        </button>
      </div>
    </div>
  );
}
export default TermsAndCondition;
