import React, { useState } from "react";
import { Button, Select } from "antd";
// import { useTranslation } from "react-i18next";
import TextArea from "antd/es/input/TextArea";
import "./styles.css";
import Header2 from "../../components/Header2";
import rightIcon from "../../assets/svg/rightIcon.svg";
import closeIcon from "../../assets/closeIcon.svg";

export default function CustomerFeedbackForm() {
  //   const { t } = useTranslation();

  // const onChange = (value) => {
  //     console.log(`selected ${value}`);
  // };

  // Handle change for issueType
  const handleIssueTypeChange = (value) => {
    setFormValues({
      ...formValues,
      issueType: value,
    });
  };

  // Handle change for brand
  const handleBrandChange = (value) => {
    setFormValues({
      ...formValues,
      brand: value,
    });
  };

  // const onSearch = (value) => {
  //     console.log("search:", value);
  // };

  const [formValues, setFormValues] = useState({
    brand: "",
    date: "",
    memberId: "",
    referenceId: "",
    issueType: "",
    remarks: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form Values:", formValues);
  };

  // Clear form fields
  const handleClear = () => {
    setFormValues({
      brand: "",
      date: "",
      memberId: "",
      referenceId: "",
      issueType: "",
      remarks: "",
    });
  };

  return (
    <div className="min-h-dvh bg-white">
      <div className="pl-2">
        <Header2 />
      </div>
      <div className="obviously py-3 text-xl pl-5 mb-4">
        <h2>Submit Ticket</h2>
      </div>
      <div className="obviously py-3 text-base pl-5 mb-4">
        <h2>General Support</h2>
      </div>
      <form className="px-3 pb-24">
        <Select
          showSearch
          placeholder="Brand"
          optionFilterProp="label"
          name="brand"
          // value={formValues.brand}
          value={formValues?.brand || undefined}
          onChange={handleBrandChange}
          options={[
            {
              value: "rolex",
              label: "Rolex",
            },
            {
              value: "victoria secret",
              label: "Victoria Secret",
            },
            {
              value: "godiva",
              label: "Godiva",
            },
          ]}
          className="w-1/2 brand h-12 round "
        />
        <input
          placeholder="Date"
          value={formValues?.date}
          onChange={handleChange}
          name="date"
          className={`w-full p-2 shadow-[0_0_3px_#00000080] rounded-3xl phone bg-white pl-4 text-base obviously my-4 h-12`}
          type="date"
        />
        <div className="flex justify-between">
          <input
            placeholder="Member ID"
            type="text"
            name="memberId"
            value={formValues?.memberId}
            onChange={handleChange}
            className={`w-[48%] p-1 shadow-[0_0_3px_#00000080] rounded-3xl phone bg-white pl-4 text-base obviously h-12`}
          />
          <input
            type="text"
            name="referenceId"
            placeholder="Reference ID"
            value={formValues?.referenceId}
            onChange={handleChange}
            className={`w-[48%] p-1 shadow-[0_0_3px_#00000080] rounded-3xl phone bg-white pl-4 text-base obviously h-12`}
          />
        </div>
        <Select
          name="issueType"
          placeholder="Issue Type"
          value={formValues?.issueType || undefined}
          onChange={handleIssueTypeChange}
          options={[
            {
              value: "technical",
              label: "Technical Issue",
            },
            {
              value: "billing",
              label: "Billing Issue",
            },
            {
              value: "general",
              label: "General Query",
            },
          ]}
          className="w-full brand h-12 my-4 round"
        />
        <TextArea
          rows={4}
          placeholder="Remarks"
          maxLength={6}
          name="remarks"
          value={formValues?.remarks}
          onChange={handleChange}
          className="rounded-3xl shadow-[0_0_3px_#00000080] p-4 text-base"
        />
        <div className="text-center mt-6 px-4">
          <p className="playfair italic ">
            By submitting this form you agree that the contents are genuine and
            there is no…….
          </p>
        </div>
        <div className="w-full flex gap-2 mt-10">
          <Button
            onClick={handleClear}
            className="basis-1/2 rounded-full h-11 bg-black text-white flex justify-between px-4 py-6"
          >
            <img src={closeIcon} alt="close icon" />
            <p>CLEAR</p>
          </Button>
          <Button
            onClick={(e) => handleSubmit(e)}
            type="submit"
            className="basis-1/2 rounded-full h-11 bg-[#B78600] text-white flex justify-between px-4 py-6"
          >
            <img src={rightIcon} alt="right icon" />
            <p>SUBMIT</p>
          </Button>
        </div>
      </form>
    </div>
  );
}
