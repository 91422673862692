import { Button, Progress } from "antd";
import { motion as m } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

export default function LoyaltyCard() {
  const [progress, setProgress] = useState(0);
  // console.log(progress);
  const customerData = useSelector(
    (store) => store.customerDetails.customerData
  );
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showScan, setShowScan] = useState(false);
  const handleClick = () => {
    navigate("/membership-scan");
  };
  const pointSummery = () => {
    let sInfo = customerData?.points_summaries?.points_summary ?? [];
    if (sInfo?.length > 0) {
      return sInfo[0];
    }
    return {};
  };
  const convLoyaltyPoints = () => {
    let pSumery = pointSummery();
    let conversionRate = pSumery?.program_points_to_currency_ratio || 1;
    let amountInRupees = pSumery?.loyaltyPoints;
    const amountInOtherCurrency = amountInRupees * conversionRate;
    return amountInOtherCurrency;
  };
  useEffect(() => {
    if (customerData?.current_slab === "Diamond") {
      setProgress(100);
    } else {
      const currentPoints =
        customerData?.points_summaries?.points_summary?.[0]?.gap_to_upgrade
          ?.upgrade_strategy?.[0]?.customer_upgrade_entity_values
          ?.current_value;
      const thresholdValue =
        customerData?.points_summaries?.points_summary?.[0]?.gap_to_upgrade
          ?.upgrade_strategy?.[0]?.upgrade_threshold;
      const prog = (Number(currentPoints) / Number(thresholdValue)) * 100;
      setProgress(prog);
    }
  }, [customerData]);
  const convEarnedPoints = () => {
    let pSumery = pointSummery();
    let conversionRate = pSumery?.program_points_to_currency_ratio || 1;
    let amountInRupees = pSumery?.lifetimePoints;
    const amountInOtherCurrency = amountInRupees * conversionRate;
    return amountInOtherCurrency;
  };
  const convSpentPoints = () => {
    let pSumery = pointSummery();
    let amountInRupees = pSumery?.redeemed;
    let conversionRate = pSumery?.program_points_to_currency_ratio || 1;
    const amountInOtherCurrency = amountInRupees * conversionRate;
    return amountInOtherCurrency;
  };
  return (
    <m.div
      initial={{ height: "0px" }}
      animate={{ height: "224px" }}
      className="h-56 bg-valiram rounded-2xl p-4 pl-6"
      onClick={handleClick}
      // style={{backgroundImage: `url(${NewThemeCard})`, backgroundSize: "cover", boxShadow: "none"}}
    >
      {/* shadow-[0_3px_6px_#08264a] */}
      <div className="flex justify-between pb-2">
        <div>
          <h6 className="playfair-bold text-white text-lg mb-2">
            {t("loyaltyCard.one")}
          </h6>
          <h1 className="playfair-bold text-white text-5xl">
            V${convLoyaltyPoints()}
          </h1>
        </div>
        <div>
          <Button shape="round" className="text-[#707070] italic w-32 h-9">
            {Object.keys(customerData).length === 0
              ? t("tierInformation.Premier")
              : t("tierInformation." + customerData["current_slab"])}
          </Button>
        </div>
      </div>
      <div className="flex justify-start gap-16 pt-4 items-end">
        <h5 className="playfair text-white text-sm">
          {t("loyaltyCard.three", {
            earnedPoints: convEarnedPoints(),
          })}
        </h5>
        <h5 className="playfair text-white text-sm">
          {/* {t("loyaltyCard.five", {
            spendPoints: convSpentPoints(),
          })} */}
          {"Spent -V$" + convSpentPoints()}
        </h5>
      </div>
      {/* <h5 className="playfair text-[#A7A7A7] text-sm pb-4">
        {t("loyaltyCard.four", { floatPoints: 1.25 })}
      </h5> */}

      <div className="pt-3">
        <m.div initial={{ width: "0" }} animate={{ width: "100%" }}>
          <Progress
            percent={progress}
            showInfo={false}
            strokeColor="#CB8500"
            trailColor="#ffffff"
          />
        </m.div>
        <div className="flex justify-end ">
          <h6 className="playfair text-white text-xs italic">
            {/* {t("loyaltyCard.six", { pointsNeeded: "9,400" })} */}
            {customerData?.points_summaries?.points_summary[0]?.currentSlab ===
            "Diamond" ? (
              <h6 className="playfair text-white text-xs italic">
                You have reached the upper slab!
              </h6>
            ) : (
              `Spend RM${
                customerData?.tier_update_criteria
                  ?.min_purchase_value_required || 0
              } to ${
                customerData?.points_summaries?.points_summary[0]?.nextSlab ||
                ""
              }  Member Upgrade!`
            )}
          </h6>
        </div>
      </div>
    </m.div>
  );
}
