import { useNavigate } from "react-router-dom";

function Cards({ cardDetails, bgColor, setOpen, setOpen2, brandDetails }) {
  const pageConfig = Object.keys(brandDetails?.page_cofig || {});

  const navigate = useNavigate();
  return (
    <div
      className={`flex flex-wrap gap-3 justify-center my-4 mx-3 pb-20`}
      style={{
        color:
          pageConfig?.length > 0 && brandDetails?.page_cofig?.cta_text_clr
            ? brandDetails.page_cofig.cta_text_clr
            : "white",
      }}
    >
      {cardDetails.map((btn, index) => (
        <div
          key={index}
          className="w-[48%] h-[119px] rounded-xl flex items-end justify-between p-3"
          style={{
            background:
              pageConfig?.length > 0 && brandDetails?.page_cofig?.cta_bg_clr
                ? brandDetails?.page_cofig?.cta_bg_clr
                : bgColor,
          }}
          onClick={() => {
            if (index === 0) navigate("/brands-store-listing");
            if (index === 1) navigate("/rewards-list");
            if (index === 2) setOpen(true);
            if (index === 3) setOpen2(true);
          }}
        >
          <img src={btn.image} alt="Sparkles icon" width={24} />
          <p className={`font-[Obviously] text-[15px] `}>{btn.title}</p>
        </div>
      ))}
    </div>
  );
}
export default Cards;
