import { GoogleMap, InfoWindow, Marker } from "@react-google-maps/api";
import React, { useEffect, useState } from "react";
import { Button } from "antd";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import CustomMarker from ".././assets/empty.png";

import BackIcon from "../assets/backIcon.svg";

export default function Map({ isLoaded, view, setView, stores }) {
  const [show, setShow] = useState(false);
  const { t } = useTranslation();
  const [activeMarker, setActiveMarker] = useState(null);

  const navigate = useNavigate();
  const newStores = stores?.map((store, index) => {
    return {
      id: index,
      title: store?.storeName,
      lat: store?.latitude,
      lng: store?.longitude,
    };
  });
  const containerStyle = {
    width: "100vw",
    height: "95vh",
  };

  const center = {
    lat: 3.133564,
    lng: 101.957466,
  };
  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 100);
  }, []);
  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <GoogleMap
      mapContainerStyle={containerStyle}
      onClick={() => setActiveMarker(null)}
      center={center}
      zoom={7}
      options={{
        mapTypeControl: false, // Disable map type control (satellite button)
      }}
    >
      <div
        className="flex items-center mt-8 pl-2 absolute"
        onClick={() => setView(!view)}
      >
        <img
          src={BackIcon}
          alt="back icon"
          // className="rounded-full shadow-[0_0_8px_#0000006b]"
        />
      </div>
      {show &&
        newStores.map((store) => (
          <Marker
            key={store.id}
            position={{ lat: store.lat, lng: store.lng }}
            icon={CustomMarker}
            onClick={() => setActiveMarker(store?.id)}
          >
            {activeMarker === store?.id && (
              <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                <div>
                  <h2 className="text-base">{store.title}</h2>
                  {/* <Link >Store page</Link> */}
                </div>
              </InfoWindow>
            )}
          </Marker>
        ))}

      {/* <div className="text-center absolute left-[36%] bottom-[115px]">
        <Button
          shape="round"
          className="bg-black text-white border-0 playfair italic btn w-28 h-9"
          onClick={() => setView(!view)}
        >
          {view ? t("brands.map") : t("brands.list")}
        </Button>
      </div> */}
    </GoogleMap>
  );
}
