import React, { useEffect, useState } from "react";
import { Button, Drawer, Spin } from "antd";
import { useNavigate } from "react-router-dom";
import { motion as m } from "framer-motion";
import { useTranslation } from "react-i18next";
import Voucher from "../../assets/voucher.svg";

import "./styles.css";

import Reward from "../../components/Reward";
import Sparkles from "../../assets/sparkles.svg";
import Down from "../../assets/down.svg";
import PlusIcon from "../../assets/plusIcon.svg";
import customerService from "../../utils/customer-service";
import CustomerServiceInstance from "../../utils/customer-service";
import Barcode from "react-barcode";
import { format, parse, parseISO } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { addRedeemData, addVouchers } from "../../redux/slices/customerDetails";
import Header2 from "../../components/Header2";
import ritualImg from "../../assets/ritual.svg";
// import Header from "../../components/Header";

export default function RewardsList() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [rewardsList, setRewardsList] = useState([]);
  const [reward, setReward] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const dispatch = useDispatch();
  const vouchersList = useSelector((store) => store.customerDetails.vouchers);
  const reedemedList = useSelector((store) => store.customerDetails.redeemData);

  useEffect(() => {
    fetchRewardsDetails();
    fetchVouchersDetails();
    fetchRedeemDetails();
    // eslint-disable-next-line
  }, []);

  const fetchRewardsDetails = async () => {
    try {
      setLoading(true);
      const rewards = await customerService.getusermarvelVouchers();

      if (rewards.status.success) {
        setRewardsList(rewards?.userVouchers);
        // console.log(rewards);
      }
    } catch (err) {
      console.log(err);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  const fetchVouchersDetails = async () => {
    try {
      setLoading(true);
      const rewardVouchers = await customerService.getVouchers();

      if (rewardVouchers?.status === "true") {
        dispatch(addVouchers(rewardVouchers?.data));
      }
    } catch (err) {
      console.log(err);
      setError(err);
    } finally {
      setLoading(false);
    }
  };
  const fetchRedeemDetails = async () => {
    setLoading(true);
    try {
      const res = await CustomerServiceInstance.getRedeemedCoupons();
      if (res?.status?.success) {
        // console.log(res?.customer?.redemptions.coupons.coupon);

        dispatch(addRedeemData(res?.customer?.redemptions?.coupons?.coupon));
      }
    } catch (err) {
      setError(err);
      console.log("Redeem Error", err);
    } finally {
      setLoading(false);
    }
  };
  const onClose = () => {
    setOpen(false);
  };
  // const rewards = [
  //   {
  //     title: "Happy Birthday",
  //     brand: "Rituals",
  //     description:
  //       "You have earned V$10 from Victorias Secret Pavilion Kuala Lumpur",
  //     validDate: "30/8/25",
  //     bgcolor: "bg-black",
  //   },
  //   {
  //     title: "You Have Turned One",
  //     brand: "Michael Kors",
  //     description:
  //       "Its been 1 Year since we connected. Here is a free gift for your loyalty.",
  //     validDate: "30/8/25",
  //     bgcolor: "bg-[#86560A]",
  //   },
  //   {
  //     title: "You Have Turned One",
  //     brand: "Michael Kors",
  //     description:
  //       "Its been 1 Year since we connected. Here is a free gift for your loyalty.",
  //     validDate: "30/8/25",
  //     bgcolor: "bg-[#002771]",
  //   },
  // ];
  // const vouchers = [
  //   {
  //     title: "FREE 3 Which Candle",
  //     brand: "Rituals",
  //     description:
  //       "You have earned V$10 from Victorias Secret Pavilion Kuala Lumpur",
  //     validDate: "30/8/25",
  //     bgcolor: "bg-black",
  //   },
  //   {
  //     title: "RM100 OFF with Shower",
  //     brand: "Bath&Body Works",
  //     description:
  //       "Its been 1 Year since we connected. Here is a free gift for your loyalty.",
  //     validDate: "30/8/25",
  //     bgcolor: "bg-[#86560A]",
  //   },
  //   {
  //     title: "Get Ice Cream",
  //     brand: "Tory Burch",
  //     description:
  //       "Its been 1 Year since we connected. Here is a free gift for your loyalty.",
  //     validDate: "30/8/25",
  //     bgcolor: "bg-black",
  //   },
  // ];
  useEffect(() => {
    // Disable scrolling when modal is open
    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [open]);
  const handleVouchersClick = () => {
    setTab("Vouchers");
  };
  const handleRedeemTabClick = async () => {
    setTab("Redeemed");
  };
  const formatDate = (expiryDate) => {
    if (expiryDate) {
      const parsedDate = parse(expiryDate, "yyyy-MM-dd HH:mm:ss", new Date());

      const formattedDate = format(parsedDate, "MMMM yyyy");

      return formattedDate;
    }
  };
  const formatDate1 = (expiryDate) => {
    if (expiryDate) {
      // Parse the ISO date string
      const parsedDate = parseISO(expiryDate);

      // Format the date to "MMMM yyyy"
      const formattedDate = format(parsedDate, "MMMM yyyy");

      return formattedDate;
    }
    return null; // Return null if no expiryDate is provided
  };
  // const redeemed = [...rewards, ...rewards, ...rewards];
  const handleAddButtonClick = () => {
    navigate("/rewards-catalog");
  };
  const [tab, setTab] = useState("Rewards");
  if (
    loading ||
    (loading && rewardsList.length === 0) ||
    (loading && vouchersList.length === 0) ||
    (loading && reedemedList.length === 0)
  )
    return (
      <div className="loading-div">
        <Spin size="large" />
      </div>
    );

  return (
    <>
      <Header2 />
      {/* <Header showLogo /> */}
      <div className="px-3 pt-2">
        <m.div
          initial={{ height: "240px" }}
          animate={{ height: "auto" }}
          className="bg-valiram2 rounded-2xl p-4 pb-16 overflow-hidden text-center flex flex-col items-center justify-between min-h-[90vh]"
        >
          <div className="w-full">
            <div className="obviously py-2 px-2 text-xl text-white text-left">
              <h2>{t("rewardsList." + tab)}</h2>
            </div>
            <div className="bg-white flex rounded-full px-4 py-3 mx-1 justify-between obviously mt-6 mb-7 text-sm">
              <div className="flex items-center flex-grow h-5">
                <p
                  className={`${
                    tab === "Rewards" ? "text-[#483D3D]" : "text-[#483D3D58]"
                  }`}
                  onClick={() => setTab("Rewards")}
                >
                  {t("rewardsList.Rewards")}
                </p>
              </div>
              <div className=" flex justify-center items-center flex-grow border-x-[1px] border-solid border-[#707070]">
                <p
                  className={`${
                    tab === "Vouchers" ? "text-[#483D3D]" : "text-[#483D3D58]"
                  }`}
                  onClick={handleVouchersClick}
                >
                  {t("rewardsList.Vouchers")}
                </p>
              </div>
              <div className="flex flex-grow justify-end items-center">
                <p
                  className={`${
                    tab === "Redeemed" ? "text-[#483D3D]" : "text-[#483D3D58]"
                  }`}
                  onClick={handleRedeemTabClick}
                >
                  {t("rewardsList.Redeemed")}
                </p>
              </div>
            </div>

            {tab === "Rewards" && (
              <div className="pb-16">
                {rewardsList?.length > 0 ? (
                  rewardsList?.map((item, index) =>
                    item.vouchers.map((reward) => (
                      <Reward
                        key={reward?.rewardId}
                        item={reward}
                        setOpen={setOpen}
                        sparkles
                        rewardDetail={item?.rewardDetails}
                        tab={"rewards"}
                        setReward={setReward}
                        open={open}
                      />
                    ))
                  )
                ) : (
                  <div className="flex items-center justify-center flex-col">
                    <img src={ritualImg} alt="ritual-img"></img>
                    <p className="text-2xl text-center  font-[400] italic text-white">
                      No Reward found!
                    </p>
                  </div>
                )}
              </div>
            )}
            {tab === "Vouchers" && (
              <div className="pb-10">
                {vouchersList?.length > 0 ? (
                  vouchersList.map((item, index) => (
                    <Reward
                      key={item.id}
                      item={item}
                      setOpen={setOpen}
                      tab={"vouchers"}
                      setReward={setReward}
                      open={open}
                    />
                  ))
                ) : (
                  <div className="flex items-center justify-center flex-col">
                    <img src={ritualImg} alt="ritual-img"></img>
                    <p className="text-2xl text-center  font-[400] italic text-white">
                      No Vouchers found!
                    </p>
                  </div>
                )}
              </div>
            )}
            {tab === "Redeemed" && (
              <div className="max-h-[70vh] overflow-scroll pb-20">
                {reedemedList?.length > 0 ? (
                  reedemedList?.map((item, index) => (
                    <Reward
                      key={item.id}
                      item={item}
                      setOpen={setOpen}
                      sparkles
                      redeemed
                      tab={"redeemed"}
                    />
                  ))
                ) : (
                  <div className="flex items-center justify-center flex-col">
                    <img src={ritualImg} alt="ritual-img"></img>
                    <p className="text-2xl text-center  font-[400] italic text-white">
                      No Redeemed data found!
                    </p>
                  </div>
                )}
              </div>
            )}
          </div>
          {tab === "Rewards" && (
            <Button
              className="bg-white flex items-center justify-center rounded-full obviously p-2 pr-4 fixed bottom-24"
              onClick={handleAddButtonClick}
            >
              <img src={PlusIcon} alt="plus icon" />
              <p className="font-bold text-xs">{t("rewardsList.add")}</p>
            </Button>
          )}
          <Drawer
            placement="bottom"
            closable={false}
            onClose={onClose}
            open={open}
            getContainer={false}
            maskClassName="bg-no"
            width={900}
            // style={{ height: "115%", borderRadius: "1rem" }}
            style={{
              borderTopLeftRadius: 20,
              borderTopRightRadius: 20,
              position: "fixed",
              bottom: 0,
              left: 0,
              right: 0,
              zIndex: 10,
              width: "95%",
              height: "97%",
              transform: "translate(10px, 0px)",
              transition: "all 0.3s ease",
            }}
            rootStyle={{ width: "96%", margin: "0 auto", outline: "none" }}
            height="98.5vh"
            borderRadius="1rem"
            rootClassName="hell"
            styles={{ body: { backgroundColor: "black" } }}
          >
            <div className="flex justify-between">
              <div className="flex gap-4">
                <img
                  src={tab === "Rewards" ? Sparkles : Voucher}
                  alt="sparkles icon"
                  className="h-7"
                />
                <p className="text-white text-base obviously">
                  {tab === "Rewards"
                    ? t(`${reward?.name}`)
                    : reward?.description}
                </p>
              </div>
              <div>
                <p className="playfair text-sm text-white self-start">
                  {tab === "Rewards"
                    ? t(`${reward?.label}`)
                    : reward?.customPropertyMap?.Brand}
                </p>
              </div>
            </div>
            <div className="px-6 pt-10 text-white obviously">
              <div className="flex justify-center items-center">
                <img
                  src={
                    tab === "Rewards"
                      ? reward?.imageUrl
                      : reward?.customPropertyMap?.standard_image_1
                  }
                  width={120}
                  height={110}
                  alt="brand logo"
                />
              </div>
              <div className="text-xs" style={{ fontSize: "10px" }}>
                <p className="text-base mt-6 mb-8">
                  {/* {t("rewardsList.drawer.gift")} */}
                  {reward?.customPropertyMap?.Greeting}
                </p>
                <h1 className="playfair italic text-4xl mb-8">
                  {/* {t("rewardsList.drawer.birthdayGreetings", { user: "Kelly" })} */}
                  {reward?.customPropertyMap?.Message}
                </h1>
                <div>
                  <p className="my-6">
                    {/* {t("rewardsList.drawer.birthdayGreetingslong")} */}
                    {tab === "Rewards"
                      ? reward?.description && t(`${reward?.description}` || "")
                      : reward?.customPropertyMap?.standard_description}
                  </p>
                  <p className="mb-4">
                    {reward?.customPropertyMap?.standard_terms_and_conditions &&
                      reward?.customPropertyMap?.standard_terms_and_conditions}
                  </p>
                  {/* <p className="mb-1">{t("rewardsList.drawer.wishes")}</p>
                  <p className="mb-6">Rituals Malaysia</p> */}
                </div>
              </div>
            </div>
            <div className="bg-white rounded-not flex items-center justify-center py-3 mb-6">
              <Barcode width={2} height={50} value={reward.code}></Barcode>
            </div>
            <div className="w-4/5 bg-white rounded-full p-2 mx-auto mb-16">
              {/* t("rewardsList.drawer.validity", {
                    date: formatDate(reward?.expiry),
                  }) */}
              Valid Thru:{" "}
              {tab === "Rewards"
                ? formatDate(reward?.expiry)
                : formatDate1(reward?.validTillDate)}
            </div>
            <div className="flex justify-center">
              <img src={Down} alt="down icon" onClick={onClose} />
            </div>
          </Drawer>
        </m.div>
      </div>
    </>
  );
}
