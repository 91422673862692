import React from "react";
import moment from "moment";
import { useSelector } from "react-redux";

const newformatDate = (dateString) => {
  const date = new Date(dateString);
  return moment(date).format("DD / MM / YYYY HH:mm:ss");
};

export default function Transaction({ transaction, onClick, onModel }) {
  const customerData = useSelector(
    (store) => store.customerDetails.customerData
  );
  const pointSummery = () => {
    let sInfo = customerData?.points_summaries?.points_summary ?? [];
    if (sInfo?.length > 0) {
      return sInfo[0];
    }
    return {};
  };

  const convEarnedPoints = (issuedPoints) => {
    let pSumery = pointSummery();
    let conversionRate = pSumery?.program_points_to_currency_ratio || 1;
    // let amountInRupees = pSumery?.issuedPoints;
    const amountInOtherCurrency = issuedPoints * conversionRate;
    return parseFloat(amountInOtherCurrency)?.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };
  return (
    <div
      className={`flex ${onModel ? "" : "px-4"} py-2 obviously`}
      onClick={() => onClick(transaction)}
    >
      <div className="basis-1/2 flex flex-col justify-evenly border-b-[1px] border-solid border-[#C7C1C1] py-4">
        <p className="text-xs text-[#483D3D]">
          {newformatDate(transaction?.billing_time)}
        </p>
        <h3 className="text-sm text-capitalize">{transaction?.store}</h3>
      </div>
      <div className="basis-1/2 flex flex-col justify-center items-end gap-1 border-b-[1px] border-solid border-[#C7C1C1] py-4">
        <h4 className="text-lg text-[#505050] playfair text-capitalize">
          {transaction?.store}
        </h4>
        <div className="text-end">
          <h3 className="text-sm text-[#483D3D] mb-2">
            RM{" "}
            {transaction?.amount &&
              parseFloat(transaction?.amount)?.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
          </h3>
          {(transaction?.points?.issued > 0 && (
            <p
              className="text-xs text-[#289A19] mt-[-5px]"
              style={{ fontSize: "10px" }}
            >
              + V$ {convEarnedPoints(transaction?.points?.issued)} pts
            </p>
          )) ||
            (transaction?.points?.returned > 0 && (
              <p
                className="text-xs text-red-700 mt-[-5px]"
                style={{ fontSize: "10px" }}
              >
                {" "}
                - V$ {convEarnedPoints(transaction?.points?.returned)} pts
              </p>
            ))}
        </div>
      </div>
    </div>
  );
}
