import axios from "axios";
import apiWrapper from "./api-wrapper";

axios.defaults.headers.common["Content-Type"] = "application/json";
const authUrl = process.env.REACT_APP_authURL;
const identifierType = process.env.REACT_APP_identifierType;
const brand = process.env.REACT_APP_brand;
const deviceId = process.env.REACT_APP_deviceId;
const mobileUrl = process.env.REACT_APP_MobileURL;

class AuthService {
  //TokenGenerate
  async tokenGenerate(password) {
    const idVal = sessionStorage.getItem("mobile");
    try {
      if ([undefined, null, "undefined", "null"].includes(idVal)) {
        throw new Error("id val not set");
      }
      const body = {
        identifierType: identifierType,
        identifierValue: idVal,
        brand: brand,
        deviceId: deviceId,
        password: password,
        confirmPassword: password,
      };

      const data = await axios.post(`${authUrl}token/generate`, body);
      sessionStorage.setItem("sessionId", data?.data?.user?.sessionId);
      // sessionStorage.setItem("token", data?.data?.auth?.token);
      return data?.data;
    } catch (error) {
      throw error;
    }
  }
  // async getRecaptchaToken() {
  //   // let grecaptcha;
  //   await new Promise((resolve, reject) => {
  //     let recaptchaSiteKey = "";
  //     recaptchaSiteKey = "6Ld0LgUqAAAAAE7aS93BEfuwu3NtQVgoCCOsvcLj";

  //     window.grecaptcha.enterprise.ready(async () => {
  //       const token = await window.grecaptcha.enterprise.execute(
  //         recaptchaSiteKey,
  //         {
  //           action: "LOGIN",
  //         }
  //       );
  //       if (token) {
  //         // console.log(token);
  //         localStorage.setItem("recaptcha-token", token);
  //         resolve();
  //       } else {
  //         reject(new Error("No token received."));
  //       }
  //     });
  //   });
  // }

  //GenerateOTP
  async generateOTP() {
    const idVal = sessionStorage.getItem("mobile");
    const body = {
      identifierType: identifierType,
      identifierValue: idVal,
      brand: brand,
      sessionId: sessionStorage.getItem("sessionId"),
      deviceId: deviceId,
    };
    const data = await axios.post(`${authUrl}otp/generate`, body);
    return data;
  }

  //ValidateOTP
  async validateOTP(otpVal) {
    // console.log(otpVal);
    const idVal = sessionStorage.getItem("mobile");
    const body = {
      identifierType: identifierType,
      identifierValue: idVal,
      brand: brand,
      sessionId: sessionStorage.getItem("sessionId"),
      deviceId: deviceId,
      otp: otpVal,
    };
    const data = await axios.post(`${authUrl}otp/validate`, body);
    if (data?.data?.status?.success) {
      sessionStorage.setItem("token", data?.data?.auth?.token);
    }
    return data?.data;
  }

  //passwordValidate
  async passwordValidate(password) {
    try {
      const idVal = sessionStorage.getItem("mobile");
      const body = {
        identifierType: identifierType,
        identifierValue: idVal,
        brand: brand,
        password: password,
        sessionId: sessionStorage.getItem("sessionId"),
        deviceId: deviceId,
      };
      const passwordValidateUrl = authUrl + "password/validate";
      const res = await axios.post(passwordValidateUrl, body);
      if (res.data.status.success) {
        sessionStorage.setItem("token", res?.data?.auth?.token);
        sessionStorage.setItem("key", res?.data?.auth?.key);
      }

      return res.data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  }
  async tokenExpire() {
    const idVal = sessionStorage.getItem("mobile");
    try {
      const headers = {
        cap_authorization: sessionStorage.getItem("token"),
        cap_brand: brand,
        cap_device_id: deviceId,
        cap_identifier_type: identifierType,
        cap_identifier_value: idVal,
      };

      const tokenExpireUrl = `${mobileUrl}token/expire`;
      const response = await axios.delete(tokenExpireUrl, { headers });

      return response.data;
    } catch (error) {
      console.error("Error expiring token:", error);
      throw error;
    }
  }
  async forgotPassword(pswd) {
    const mobile = sessionStorage.getItem("mobile");
    const sessionId = sessionStorage.getItem("sessionId");
    const url = authUrl + "password/forget";
    const body = {
      identifierType: identifierType,
      identifierValue: mobile,
      deviceId: deviceId,
      brand: brand,
      password: pswd,
      confirmPassword: pswd,
      sessionId: sessionId,
    };
    return apiWrapper(url, "POST", body);
  }

  async tokenRegenerate() {
    const idVal = sessionStorage.getItem("mobile");
    try {
      const body = {
        mobile: idVal,
        brand: brand,
        deviceId: deviceId,
        key: sessionStorage.getItem("key"),
      };

      const tokenRegenerateUrl = `${authUrl}token/regenerate`;
      const response = await axios.post(tokenRegenerateUrl, body);

      if (response?.data?.status?.success) {
        sessionStorage.setItem("token", response?.data?.auth?.token);
        window.location.reload();
      } else {
        console.error("Token regeneration failed:", response.data);
      }

      return response.data;
    } catch (error) {
      console.error("Error expiring token:", error);
      throw error;
    }
  }
}
const AuthServiceInstance = new AuthService();
export default AuthServiceInstance;
