import React, { useEffect, useState } from "react";
import Header2 from "../../components/Header2";
import { getFaqs } from "../../APIs/api";
import { Spin } from "antd";

const FAQ = ({ setShowNav }) => {
  const [openIndex, setOpenIndex] = useState(null);
  const [faqs, setFaqs] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    async function fetchFaq() {
      const dataObj = await getFaqs();
      setLoading(false);
      const dataArray = dataObj?.data;

      if (dataArray !== undefined) {
        // console.log(dataArray);
        setFaqs(dataArray);
      } else {
        //brandopt
      }
    }
    fetchFaq();
  }, []);

  // useEffect(() => {
  //   setShowNav(false);
  //   // eslint-disable-next-line
  // }, []);

  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };
  if (loading)
    return (
      <div className="loading-div">
        <Spin size="large" />
      </div>
    );
  return (
    <div className="bg-white h-[100vh] playfair">
      <Header2 textColor="black" />
      <h2 className="text-black text-xl p-3 font-bold ml-2">FAQ</h2>

      {faqs?.map((item, index) => (
        <div key={item?.id} className={`m-3 `}>
          <button
            onClick={() => toggleAccordion(item?.id)}
            style={{
              width: "100%",
              textAlign: "left",
              padding: "10px",

              border: "1px solid #ccc",

              marginBottom: openIndex === item?.id ? "0px" : "5px",
            }}
            className="leading-5 bg-gray-900 text-white rounded-t-lg"
          >
            {item?.attributes?.question}
          </button>
          {openIndex === item?.id && (
            <div
              style={{
                padding: "10px",

                marginBottom: "5px",
              }}
              className="text-black leading-5 border border-solid border-[bg-gray-900] border-t-0 rounded-b-lg "
            >
              {item?.attributes?.answer}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default FAQ;
