import React, { useEffect, useState } from "react";
import { Button, Input, Spin } from "antd";
import { useTranslation } from "react-i18next";

import "./styles.css";
import LoyaltyCard from "../../components/LoyaltyCard";
import CustomModel from "../../components/CustomModel";
import ProfileInfo from "../../components/ProfileInfo";
import Header2 from "../../components/Header2";
import FieldSelection from "../../components/FieldSelection";

import NoSign from "../../assets/no-sign.svg";
import rightIcon from "../../assets/right.svg";
import closeIcon from "../../assets/closeIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import customerService from "../../utils/customer-service";
import BackIcon from "../../assets/backIcon.svg";
import { parse, getYear } from "date-fns";

// import { useNavigate } from "react-router-dom";
import {
  addBrandList,
  addCustomerData,
  updateCustomerData,
} from "../../redux/slices/customerDetails";
import CustomerServiceInstance from "../../utils/customer-service";
import Barcode from "react-barcode";

import { toast } from "react-toastify";
import { getBrands } from "../../APIs/api";
import Header from "../../components/Header";
export default function ProfileUpdate() {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  // const [initDate, setInitDate] = useState("");
  const dispatch = useDispatch();
  const [showCommunicationModal, setShowCommunicationModal] = useState(false);
  const [showConnectedAccountsModal, setShowConnectedAccountsModal] =
    useState(false);
  const [subscriptionDetails, setSubscriptionDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  // const [maxDate, setMaxDate] = useState("");

  // const navigate = useNavigate();

  const customerData = useSelector(
    (store) => store.customerDetails.customerData
  );
  const [userDataObj, setUserDataObj] = useState({});

  const [brandList, setBrandList] = useState(
    useSelector((store) => store.customerDetails.brandList)
  );
  const nationalityOptions = useSelector(
    (store) => store.customerDetails.countryList
  );
  useEffect(() => {
    // Disable scrolling when modal is open
    if (showModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [showModal]);
  // useEffect(() => {
  //   if (showModal) {
  //     const currentDate = new Date();
  //     currentDate.setFullYear(currentDate.getFullYear() - 5);
  //     const formattedDate = currentDate.toISOString().split("T")[0]; // Format to YYYY-MM-DD
  //     // console.log("format", formattedDate);
  //     setMaxDate(formattedDate);
  //   }
  // }, [showModal]);
  useEffect(() => {
    setUserDataObj(customerData);
  }, [customerData]);
  useEffect(() => {
    if (brandList?.length === 0) {
      setLoading(true);
      async function getData() {
        const dataObj = await getBrands();
        setLoading(false);
        const dataArray = dataObj?.data;

        // console.log(dataArray);
        if (dataArray !== undefined) {
          setBrandList(dataArray);
          dispatch(addBrandList(dataArray));
        } else {
          //brandopt
        }
      }
      getData();
    }
  }, []);

  const exProperties = customerData?.extended_fields?.field?.reduce(
    (acc, field) => {
      acc[field.name] = field.value;
      return acc;
    },
    {}
  );
  const customProperties = customerData?.custom_fields?.field?.reduce(
    (acc, field) => {
      acc[field.name] = field.value;
      return acc;
    },
    {}
  );

  let accounts = customerData?.custom_fields?.field?.filter(
    (item) =>
      item.name === "facebook" ||
      item.name === "tiktok" ||
      item.name === "x" ||
      item.name === "instagram"
  );

  // if (customerData) {
  //   accounts = [
  //     { name: "instagram" },
  //     { name: "facebook" },
  //     { name: "x" },
  //     { name: "tiktok" },
  //   ];
  // }

  // const countries = nationalityOptions;
  // const selectedCountry = exProperties?.country_of_residence;
  const brandNames = brandList?.map((brand) =>
    brand?.attributes?.custom_f?.toLowerCase()
  ); // Extract custom_f values

  const brandsYouLove = customerData?.custom_fields?.field?.filter(
    (item) => brandNames.includes(item?.name?.toLowerCase()) // Check if item.name is in the brandNames array
  );

  const [inputs, setInputs] = useState([]);
  useEffect(() => {
    setInputs([
      {
        title: t("profileUpdate.profileInfo.firstName.title"),
        placeholder: t("profileUpdate.profileInfo.firstName.ph"),
        value: customerData?.firstname,
        key: "firstName",
      },
      {
        title: t("profileUpdate.profileInfo.lastName.title"),
        placeholder: t("profileUpdate.profileInfo.lastName.ph"),
        value: customerData?.lastname,
        key: "lastName",
      },
      {
        title: t("profileUpdate.profileInfo.salutation.title"),
        placeholder: t("profileUpdate.profileInfo.salutation.ph"),
        value: customProperties?.salutation,
        key: "salutation",
      },
      {
        title: t("profileUpdate.profileInfo.gender.title"),
        placeholder: t("profileUpdate.profileInfo.gender.ph"),
        value: exProperties?.gender,
        key: "gender",
      },
      {
        title: t("profileUpdate.profileInfo.nationality.title"),
        placeholder: t("profileUpdate.profileInfo.nationality.ph"),
        value: exProperties?.country_of_residence,
        key: "country_of_residence",
      },
      {
        title: "DOB",
        placeholder: "DOB",
        value: exProperties?.dob_date,
        key: "dob",
      },

      {
        title: t("profileUpdate.profileInfo.city.title"),
        placeholder: t("profileUpdate.profileInfo.city.ph"),
        value: exProperties?.city,
        key: "city",
      },
      {
        title: t("profileUpdate.profileInfo.wedding.title"),
        placeholder: t("profileUpdate.profileInfo.wedding.ph"),
        value: customProperties?.wedding_anniversary,
        key: "wedding",
      },
      {
        // t("profileUpdate.profileInfo.braFit.title1")
        // t("profileUpdate.profileInfo.braFit.ph1")
        title: "Bra Fit",
        placeholder: "Bra Fit",
        value: customProperties?.bra_fit,
        key: "braFit",
      },
      // {
      //   title: t("profileUpdate.profileInfo.braFit.title2"),
      //   placeholder: t("profileUpdate.profileInfo.braFit.ph2"),
      //   value: customProperties?.bra_fit_cup,
      //   key: "braFitCup",
      // },
      {
        title: t("profileUpdate.profileInfo.shoes.title"),
        placeholder: t("profileUpdate.profileInfo.shoes.ph") + "size",
        value: customProperties?.shoe_size,
        key: "shoeSize",
      },
      {
        title: t("profileUpdate.profileInfo.top.title"),
        placeholder: t("profileUpdate.profileInfo.top.ph") + " size",
        value: customProperties?.top_size,
        key: "topSize",
      },
      {
        title: t("profileUpdate.profileInfo.bottom.title"),
        placeholder: t("profileUpdate.profileInfo.bottom.ph") + "size",
        value: customProperties?.botton_size,
        key: "bottomSize",
      },
    ]);
    if (customerData?.subscription?.length) {
      const subscriptionDetail = customerData?.subscription[0]?.channel.filter(
        (item) => item.name === "SMS" || item.name === "EMAIL"
      );
      setSubscriptionDetails(subscriptionDetail);
    }
  }, [customerData]);
  const handleUpdateProfile = async () => {
    setLoading(true);
    try {
      const userData = inputs?.reduce((acc, input) => {
        acc[input.key] = input.value;
        return acc;
      }, {});
      // console.log(userData);
      const body = {
        root: {
          customer: [
            {
              mobile: sessionStorage.getItem("mobile"),
              firstname: userData.firstName,
              lastname: userData.lastName,
              email: customerData.email,
              custom_fields: {
                field: [
                  {
                    name: "salutation",
                    value: userData.salutation,
                  },
                  {
                    name: "wedding_anniversary",
                    value: userData.wedding,
                  },
                  {
                    name: "bra_fit",
                    value: userData.braFit,
                  },
                  // {
                  //   name: "bra_fit_cup",
                  //   value: userData.braFitCup,
                  // },
                  {
                    name: "shoe_size",
                    value: userData.shoeSize,
                  },
                  {
                    name: "top_size",
                    value: userData.topSize,
                  },
                  {
                    name: "botton_size",
                    value: userData.bottomSize,
                  },
                  {
                    name: "brands_you_love",
                    value: customProperties?.brands_you_love,
                  },
                  {
                    name: "favourite_offers",
                    value: customProperties?.favourite_offers,
                  },
                ],
              },
              extended_fields: {
                field: [
                  {
                    name: "dob_date",
                    value: userData.dob,
                  },
                  {
                    name: "gender",
                    value: userData.gender,
                  },
                  {
                    name: "country_of_residence",
                    value: userData.country_of_residence
                      ?.replace(/\s*\(.*?\)\s*/g, "")
                      ?.trim(),
                  },
                  {
                    name: "city",
                    value: userData.city,
                  },
                ],
              },
            },
          ],
        },
      };
      const updateProfileRes = await customerService.updateCustomer(body);

      if (updateProfileRes?.status?.success === "true") {
        // console.log("Data", updateProfileRes?.customers?.customer[0]);
        dispatch(updateCustomerData(updateProfileRes?.customers?.customer[0]));

        setShowModal(false);
        toast.success("Profile Updated Successfully", {
          position: "bottom-right",
          autoClose: 4000,
          hideProgressBar: true,
          closeButton: true,
        });
      }
    } catch (error) {
      console.log("En Error occurred while updating profile", error);
    } finally {
      setLoading(false);
    }
  };
  const getChannelStatus = (status) => {
    return status === "SUBSCRIBED" ? "0" : "1";
  };

  const handleUpdateChannel = async (subscribeStatus, channel) => {
    setLoading(true);
    const filterAndGetInitialData = (channelName) => {
      const channelItem = subscriptionDetails?.find(
        (item) => item.name.toUpperCase() === channelName.toUpperCase()
      );

      if (channelItem) {
        const priorityStatus = channelItem.priority.find(
          (priority) => priority.name === "BULK"
        );

        return priorityStatus && priorityStatus.user_preference === "SUBSCRIBED"
          ? "1"
          : "0";
      }
      return "0";
    };

    try {
      const data = getChannelStatus(subscribeStatus);

      const subscriptions = [
        {
          priority: "TRANS",
          scope: "all",
          is_subscribed: "0",
          channel: "email",
        },
        {
          priority: "BULK",
          scope: "all",
          is_subscribed: filterAndGetInitialData("email"),
          channel: "email",
        },
        {
          priority: "TRANS",
          scope: "all",
          is_subscribed: "0",
          channel: "sms",
        },
        {
          priority: "BULK",
          scope: "all",
          is_subscribed: filterAndGetInitialData("sms"),
          channel: "sms",
        },
      ];

      const updatedSubscriptions = subscriptions.map((sub) => {
        if (sub.channel === channel && sub.priority === "BULK") {
          return { ...sub, is_subscribed: data.toString() };
        }
        return sub;
      });

      const body = {
        root: {
          customer: [
            {
              mobile: sessionStorage.getItem("mobile"),
              firstname: customerData.firstName,
              lastname: customerData.lastName,
              email: customerData.email,
              subscriptions: {
                subscription: updatedSubscriptions,
              },
            },
          ],
        },
      };

      const updateProfileRes = await CustomerServiceInstance.updateCustomer(
        body
      );

      if (updateProfileRes?.status?.success === "true") {
        toast.success("Profile Updated Successfully", {
          position: "bottom-right",
          closeButton: true,
          autoClose: 4000,
          hideProgressBar: false,
        });
        try {
          const getCustomer = await CustomerServiceInstance.getCustomer();
          if (getCustomer.status.success) {
            dispatch(addCustomerData(getCustomer?.customers?.customer[0]));
          } else {
            console.log(getCustomer.status.message);
          }
        } catch (e) {
          console.log("Error fetching customer data", e);
        }
        setShowCommunicationModal(false);
      }
    } catch (error) {
      console.log("An error occurred while updating profile", error);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateAccount = async (item) => {
    const updatedata = {
      ...item,
      value: item.value === "true" ? "false" : "true",
    };
    const updatedAccounts = accounts.map((i) => {
      if (i.name === item.name) return updatedata;
      else return i;
    });
    const body = {
      root: {
        customer: [
          {
            mobile: sessionStorage.getItem("mobile"),
            firstname: customerData.firstname,
            lastname: customerData.lastname,
            email: customerData.email,
            custom_fields: {
              field: updatedAccounts,
            },
          },
        ],
      },
    };
    try {
      const res = await CustomerServiceInstance.updateCustomer(body);
      if (res.status.success) {
        dispatch(updateCustomerData(res?.customers?.customer[0]));
        setShowConnectedAccountsModal(false);
      }
    } catch (err) {
      console.log("Error while updating", err);
    }
  };
  if (loading)
    return (
      <div className="loading-div">
        <Spin size="large" />
      </div>
    );
  return (
    <div className={``}>
      <div className={`pt-1 `}>
        <Header2 />
        {/* <Header showLogo></Header> */}
      </div>
      <div className={`px-2.5 w-full  `}>
        {/* <div className="obviously py-1 mb-2 px-1 text-xl flex justify-between">
          <h2>{t("profileUpdate.title")}</h2>
          <div>
            <img src={CameraIcon} alt="Camera icon" />
          </div>
        </div> */}
        <div>
          <LoyaltyCard />
        </div>
      </div>
      <div className={`p-2.5 pb-32`}>
        <div>
          <div className="bg-valiram bg-center text-white px-6 py-4 flex rounded-2xl ">
            <div className="basis-1/2  roboto text-left py-3">
              <h2 className="font-bold text-xl">
                {customerData?.firstname + " " + customerData?.lastname}
              </h2>
              <p className="text-sm">{customerData?.email}</p>
            </div>
            <div className="basis-1/2 flex justify-center  items-end flex-col playfair italic ">
              <p className="text-xl">{t("profileUpdate.member")}</p>
              <div className="border-t-[1px] border-solid border-[#B1B1B1] w-24 mt-4 mb-[-4px] mr-3"></div>
              <p className="text-3xl mr-7 ">
                {" "}
                {customerData?.registered_on &&
                  getYear(
                    parse(
                      customerData?.registered_on,
                      "yyyy-MM-dd HH:mm:ss",
                      new Date()
                    )
                  )}
              </p>
            </div>
          </div>
          <div className="flex p-1 shadow-lg rounded-b-lg bg-white">
            <div className="basis-1/2">
              {/* user_id */}
              <Barcode
                displayValue={false}
                width={1.5}
                height={50}
                value={customerData && customerData?.mobile}
              />
            </div>
            <div className="basis-1/2 roboto text-right flex items-center justify-end">
              <h2 className="font-bold text-lg">ID : {customerData?.mobile}</h2>
            </div>
          </div>
        </div>
        <div className="my-6">
          <h2 className="obviously mb-4 ml-2">
            {t("profileUpdate.contactInfo")}
          </h2>
          <div className="flex gap-2 mb-3 items-center">
            <Input
              addonBefore={t("profileUpdate.mobile") + " MY"}
              placeholder={t("profileUpdate.mobile")}
              value={"+" + customerData.mobile}
              className="w-3/5 p-1 ml-2 shadow-[0_0_3px_#00000080] rounded-3xl phone"
            />
            <div>
              <img src={NoSign} alt="No sign icon" />
            </div>
          </div>
          <div className="flex gap-2 mb-3  items-center">
            <Input
              addonBefore={t("profileUpdate.email")}
              placeholder={t("profileUpdate.email")}
              value={customerData.email}
              className="w-3/5 p-1 ml-2 shadow-[0_0_3px_#00000080] rounded-3xl phone"
            />
            <div>
              <img src={NoSign} alt="No sign icon" />
            </div>
          </div>
        </div>
        <ProfileInfo
          readOnly
          setShowModal={setShowModal}
          inputs={inputs}
          setInputs={setInputs}
          countries={nationalityOptions}
          selectedCountry={exProperties?.country_of_residence}
          // maxDate={maxDate}
        />

        <FieldSelection
          fields={brandList}
          fieldsTitle={t("profileUpdate.brandsYouLove")}
          brandsYouLove={brandsYouLove}
        />
        <FieldSelection
          fields={subscriptionDetails}
          fieldsTitle={t("profileUpdate.communication")}
          setShowCommunicationModal={setShowCommunicationModal}
        />
        {/* 
        <FieldSelection
          fields={accounts}
          fieldsTitle={t("profileUpdate.accounts")}
          setshowConnectedAccountsModal={setShowConnectedAccountsModal}
        /> */}

        {/* <FieldSelection
          fields={security}
          fieldsTitle={t("profileUpdate.security")}
        /> */}
      </div>
      {showModal && (
        <div className="profile">
          <CustomModel>
            <div className="bg-[#F8F8F8] p-4 rounded-xl shadow-[0_0_7px_#00000066] w-[99%] mx-auto profile">
              <ProfileInfo
                inputs={inputs}
                setInputs={setInputs}
                readOnly={false}
                setShowModal={setShowModal}
                customerData={customerData}
                countries={nationalityOptions}
                selectedCountry={exProperties?.nationality}
              />
              <div className="w-full flex gap-2">
                <Button
                  className="basis-1/2 rounded-full h-10 bg-black text-white flex justify-between px-4 py-6"
                  onClick={() => setShowModal(false)}
                >
                  <img src={closeIcon} alt="close icon" />
                  <p>{t("profileUpdate.cancel")}</p>
                </Button>
                <Button
                  className="basis-1/2 rounded-full h-10 bg-[#B78600] text-white flex justify-between px-4 py-6"
                  onClick={() => handleUpdateProfile(inputs)}
                >
                  <img src={rightIcon} alt="right icon" />
                  <p>{t("profileUpdate.save")}</p>
                </Button>
              </div>
            </div>
          </CustomModel>
        </div>
      )}
      {showCommunicationModal && (
        <div className="profileComm">
          <CustomModel>
            <div className=" p-4  w-full h-full bg-white">
              <div className="flex items-center ">
                <img
                  src={BackIcon}
                  alt="back icon"
                  className="mt-3"
                  onClick={() => setShowCommunicationModal(false)}
                />
              </div>
              <div className="mt-10 flex gap-3 flex-wrap">
                {subscriptionDetails?.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className={`w-[45%] p-1 shadow-[0_0_3px_#00000080] rounded-3xl phone font-extrabold h-9 text-xs flex justify-center items-center obviously mt-4 ${
                        item.priority[0].user_preference === "SUBSCRIBED"
                          ? "text-[#000]"
                          : "text-[#ADA1A1]"
                      }`}
                      onClick={() =>
                        handleUpdateChannel(
                          item?.priority[0]?.user_preference,
                          item.name.toLowerCase()
                        )
                      }
                    >
                      {item?.name}
                    </div>
                  );
                })}
              </div>
            </div>
          </CustomModel>
        </div>
      )}
      {showConnectedAccountsModal && (
        <CustomModel>
          <div className="bg-[#F8F8F8] p-4  w-[99%] mx-auto h-full ">
            <div className="flex items-center ">
              <img
                src={BackIcon}
                alt="back icon"
                className="mt-3"
                onClick={() => setShowConnectedAccountsModal(false)}
              />
            </div>
            <div className="mt-10 flex gap-3 flex-wrap">
              {accounts.map((item, index) => {
                return (
                  <div
                    key={index}
                    className={`w-[45%] p-1 shadow-[0_0_3px_#00000080] rounded-3xl phone font-extrabold h-9 text-xs flex justify-center items-center obviously mt-4 ${
                      item.value === "true" ? "text-[#000]" : "text-[#ADA1A1]"
                    }`}
                    onClick={() => handleUpdateAccount(item)}
                  >
                    {item.name}
                  </div>
                );
              })}
            </div>
          </div>
        </CustomModel>
      )}
    </div>
  );
}
