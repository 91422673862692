import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";

const MobileNumberInput = ({
  setError,
  phoneNo,
  setPhoneNo,
  setCountryCode,
  inputWidth,
  disabled,
  setPhoneRegex,
}) => {
  const [countries, setCountries] = useState([]);

  const [filteredCountries, setFilteredCountries] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCountry, setSelectedCountry] = useState(null);
  const countryC = sessionStorage.getItem("countryCode");
  const defaultRegex = "^(?!0+$)[0-9]{9,10}$";
  // const [phoneNo, setPhoneNo] = useState("");

  // const [phoneRegex, setPhoneRegex] = useState("");
  const countryList = useSelector(
    (store) => store?.customerDetails?.countryList
  );
  // console.log(countryList);
  // useEffect(() => {
  //   console.log(selectedCountry);
  // }, [selectedCountry]);

  useEffect(() => {
    if (countryList?.length > 0) {
      setCountries(countryList);
      setFilteredCountries(countryList);
      let countryImgObj;
      if (countryC) {
        countryImgObj = countryList.find((item) => item.code === countryC);

        setSelectedCountry(countryImgObj);
        setPhoneRegex(
          countryImgObj?.phoneregex && countryImgObj?.phoneregex.length > 0
            ? countryImgObj?.phoneregex
            : defaultRegex
        );
      } else {
        setSelectedCountry(countryList[0]);

        setPhoneRegex(
          countryList[0]?.phoneregex && countryList[0].phoneregex.length > 0
            ? countryList[0].phoneregex
            : defaultRegex
        );
      }
    }
  }, [countryList]);

  const handleToggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleSelectCountry = (country) => {
    setSelectedCountry(country);
    // setPhoneNo(`+${country.code} `);

    setPhoneRegex(
      country?.phoneregex && country?.phoneregex.length > 0
        ? country?.phoneregex
        : defaultRegex
    );

    // setPhoneNo("");
    setError("");
    setIsOpen(false);
  };
  const handleSearchChange = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchTerm(value);
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      const filtered = countries.filter((country) => {
        const countryNameMatch = country.country_name
          .toLowerCase()
          .includes(searchTerm);
        const countryCodeMatch = country.code.includes(searchTerm);
        return countryNameMatch || countryCodeMatch;
      });
      setFilteredCountries(filtered);
    }, 300); // Debounce time

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm, countries]);

  const handlePhoneChange = (e) => {
    let value = e.target.value.replace(/[^0-9]/g, "");
    // if (value.length > 100) {
    //   value = "";
    // }
    setPhoneNo(value);

    // Validate the phone number based on the selected country's regex
    if (selectedCountry) {
      if (value === "") {
        setError(""); // Clear error if the field is empty
      } else {
        setPhoneNo(value);
        setCountryCode(selectedCountry.code);
        setError(""); // Clear error if valid
      }
    }
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginBottom: 10,
      }}
    >
      <div style={{ position: "relative" }}>
        <div
          // onClick={handleToggleDropdown}
          onClick={disabled ? null : handleToggleDropdown}
          style={{
            // border: "1px solid #ccc",

            padding: "16px",
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            width: inputWidth ? "29vmin" : "30vmin",
            height: "53px",
          }}
          className="bg-slate-200 rounded-s-[28px]"
        >
          {selectedCountry ? (
            <>
              <img
                src={selectedCountry.flag}
                alt={selectedCountry.country_name}
                style={{
                  width: 40,
                  height: 35,
                  marginRight: 10,
                  padding: 3,
                  //   objectFit: "cover",
                }}
              />
              <span
                className="text-sm obviously"
                style={{ color: disabled && "rgba(0,0,0,0.3)" }}
              >
                +{selectedCountry.code}
              </span>
            </>
          ) : (
            "Select country"
          )}
          <div
            className={!isOpen ? "arrow" : "arrow up"}
            // onClick={handleToggleDropdown}
            onClick={disabled ? null : handleToggleDropdown}
          ></div>
        </div>
        {isOpen && (
          <div
            style={{
              position: "absolute",
              top: "100%",
              left: 0,
              right: 0,
              border: "1px solid #ccc",
              borderRadius: "8px",
              backgroundColor: "white",

              maxHeight: "200px",
              overflowY: "auto",
              width: inputWidth ? inputWidth : "83vmin",
              // borderRadius: "28px",
              zIndex: 10,
            }}
          >
            <input
              type="text"
              value={searchTerm}
              onChange={handleSearchChange}
              placeholder="Search..."
              style={{
                width: "100%",
                padding: "12px",
                border: "none",
                borderBottom: "1px solid #ccc",
                fontFamily: "obviously",
              }}
            />

            {filteredCountries?.length > 0 ? (
              filteredCountries?.map((country, index) => (
                <div
                  key={index}
                  onClick={() => handleSelectCountry(country)}
                  style={{
                    padding: "8px",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                  }}
                  onMouseEnter={(e) =>
                    (e.currentTarget.style.backgroundColor = "#f0f0f0")
                  }
                  onMouseLeave={(e) =>
                    (e.currentTarget.style.backgroundColor = "white")
                  }
                >
                  <img
                    src={country.flag}
                    alt={country.country_name}
                    style={{ width: 40, height: 40, marginRight: 8 }}
                  />
                  <span className="text-base obviously">
                    {country.country_name} (+{country.code})
                  </span>
                </div>
              ))
            ) : (
              <p className="text-base obviously p-3">No Data Found!</p>
            )}
          </div>
        )}
      </div>
      <input
        type="tel"
        value={phoneNo ? phoneNo : ""}
        maxLength={selectedCountry?.max_length && selectedCountry?.max_length}
        onChange={handlePhoneChange}
        placeholder="12 385 XXXX"
        style={{
          padding: "25px",
          //   border: "1px solid #ccc",
          height: "53px",
          width: inputWidth ? "56vmin" : "53vmin",
          backgroundColor: disabled && "white",
          color: disabled && "rgba(0,0,0,0.3)",
          borderRadius: "0px 28px 28px 0px",
        }}
        // rounded-e-[28px]
        className="text-sm obviously  focus:outline-none focus:border-transparent"
        disabled={disabled}
      />
    </div>
  );
};

export default MobileNumberInput;
