import mobileView from ".././assets/svg/mobile_view.svg";

const FallbackUI = () => {
  return (
    <div style={styles.fallbackContainer}>
      <img src={mobileView} alt="mobile-view" style={styles.styleImg}></img>
    </div>
  );
};

const styles = {
  fallbackContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: "100vh",
    width: "100vw",
    backgroundColor: "#031627",
  },
  styleImg: {
    height: "100%",
    border: "0px",
  },
};

export default FallbackUI;
