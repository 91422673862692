import { Button, Image } from "antd";
import React, { useEffect, useState } from "react";
import FilterIcon from "../assets/filter.svg";

export default function Filters({
  categoryList,
  setRenderedBrands = () => {},
  brands,
}) {
  const [selectedFilterId, setSelectedFilterId] = useState([]);

  useEffect(() => {
    if (brands && brands.length > 0) {
      setRenderedBrands(brands);
    }
    // eslint-disable-next-line
  }, []);
  function capitalizeFirstLetter(string) {
    if (!string) return string; // Return the original string if it's falsy

    // Split the string into words, capitalize the first letter of each, and join them back
    return string
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }
  const filterBrands = (filterIds) => {
    if (filterIds.length === 0) {
      return brands;
    }
    return brands.filter((brand) => {
      //cat_ids array
      if (Array.isArray(brand?.attributes?.cat_ids?.cat_ids)) {
        if (
          filterIds.some((id) =>
            brand?.attributes?.cat_ids?.cat_ids.includes(id)
          )
        ) {
          return true;
        }
      }
      //cat_id object for reward catalog
      if (Array.isArray(brand.categoryList)) {
        // console.log(brand.categoryList);
        // console.log(filterIds);
        if (
          brand.categoryList.some((cat) =>
            filterIds.includes(capitalizeFirstLetter(cat?.name))
          )
        ) {
          // console.log(true);
          return true;
        }
      }
      //cat id object for transaction
      if (Array.isArray(brand.extended_fields?.field)) {
        return brand.extended_fields.field.some(
          (field) =>
            field.name === "order_channel" && filterIds.includes(field.value)
        );
      }

      //cat id object for stores
      if (brand && brand?.customFields) {
        return filterIds.includes(brand?.customFields?.category);
      }

      //cat id for your offers
      if (brand && Array.isArray(brand?.cat_ids)) {
        if (brand.cat_ids.some((cat) => filterIds.includes(cat))) {
          return true;
        }
      }
      return false;
    });
  };

  const handleClick = (filter) => {
    const filterId =
      filter?.cat_id || filter?.id || filter?.cat || filter?.type;
    // console.log(filterId);
    const newSelectedFilterIds = selectedFilterId?.includes(filterId)
      ? selectedFilterId?.filter((id) => id !== filterId) // Remove if already selected
      : [...selectedFilterId, filterId];
    // console.log(newSelectedFilterIds);
    setSelectedFilterId(newSelectedFilterIds);

    const filteredBrands = filterBrands(newSelectedFilterIds);

    setRenderedBrands(filteredBrands);
  };

  return (
    <div className="carousel-container">
      <img
        src={FilterIcon}
        alt="Filter icon"
        className="bg-[#B5B5B5] p-2 rounded-full  "
      />
      <Button
        className="bg-[#B5B5B5] p-4  rounded-full filterBtn"
        width={"32px"}
        onClick={() => {
          setRenderedBrands(brands);
          setSelectedFilterId([]);
        }}
        style={{
          backgroundColor:
            selectedFilterId?.length === 0 ? "#B78600" : "#B5B5B5",
          color: "white",
          border: "none",
        }}
      >
        <p className="text-white">All</p>
      </Button>
      {categoryList?.map((filter, index) => (
        <Button
          key={index} // Use cat_id as the key
          shape="round"
          style={{
            backgroundColor: selectedFilterId?.includes(
              filter?.cat_id || filter?.cat || filter?.type
            )
              ? "#B78600"
              : "#B5B5B5",
            color: "white",
            border: "none",
            textTransform: "capitalize",
          }}
          onClick={() => handleClick(filter)}
        >
          {filter.title}
        </Button>
      ))}
    </div>
  );
}
