import React from "react";
import { useTranslation } from "react-i18next";

import CustomModel from "./CustomModel";
import mkLogo from "../assets/mkLogo1.png";
import rightIcon from "../assets/right.svg";
import closeIcon from "../assets/closeIcon.svg";
import crossIcon from "../assets/crossIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import CustomerServiceInstance from "../utils/customer-service";
import { updateCustomerData } from "../redux/slices/customerDetails";

function MKCardModel({
  onClose,
  setIsBrandOpted,
  brand_id,
  isBrandOpted,
  brandName,
  customF,
}) {
  const dispatch = useDispatch();

  const customerData = useSelector(
    (store) => store.customerDetails.customerData
  );
  // const exProperties = customerData?.extended_fields?.field?.reduce(
  //   (acc, field) => {
  //     acc[field.name] = field.value;
  //     return acc;
  //   },
  //   {}
  // );
  // const customProperties = customerData.custom_fields?.field?.reduce(
  //   (acc, field) => {
  //     acc[field.name] = field.value;
  //     return acc;
  //   },
  //   {}
  // );

  // const getBrandYouLoveId = () => {
  //   const idStr = String(brand_id);

  //   const BrandsYouLoveObject = customerData?.custom_fields?.field?.find(
  //     (item) => item.name === "brands_you_love"
  //   );

  //   const myFav = BrandsYouLoveObject ? BrandsYouLoveObject.value : "";

  //   let data = myFav.split(",").filter(Boolean).map(String);

  //   data = Array.from(new Set(data));

  //   if (data?.includes(idStr)) {
  //     data = data.filter((item) => item !== idStr);
  //     setIsBrandOpted(false);
  //   } else {
  //     data.push(idStr);
  //     setIsBrandOpted(true);
  //   }

  //   return data.join(",");
  // };
  const { t } = useTranslation();

  // const brandsYouLove = getBrandYouLoveId();
  // const BrandsYouLoveObject = customerData?.custom_fields?.field?.find(
  //   (item) => item.name.toLowerCase() === customF.toLowerCase()
  // );

  // const brandsYouLoveUpdateObj = BrandsYouLoveObject ? !BrandsYouLoveObject.value : true;

  // const body = {
  //   root: {
  //     customer: [
  //       {
  //         mobile: sessionStorage.getItem("mobile"),
  //         firstname: customerData.firstname,
  //         lastname: customerData.lastname,
  //         email: customerData.email,
  //         custom_fields: {
  //           field: [
  //             {
  //               name: "brands_you_love",
  //               value: brandsYouLove,
  //             },
  //             {
  //               name: "favourite_offers",
  //               value: customProperties.favourite_offers,
  //             },
  //             {
  //               name: "salutation",
  //               value: customProperties?.salutation,
  //             },
  //             {
  //               name: "wedding_anniversary",
  //               value: customProperties?.wedding_anniversary,
  //             },
  //             {
  //               name: "bra_fit",
  //               value: customProperties.bra_fit,
  //             },
  //             // {
  //             //   name: "bra_fit_cup",
  //             //   value: customProperties?.bra_fit_cup,
  //             // },
  //             {
  //               name: "shoe_size",
  //               value: customProperties?.shoe_size,
  //             },
  //             {
  //               name: "top_size",
  //               value: customProperties?.top_size,
  //             },
  //             {
  //               name: "botton_size",
  //               value: customProperties?.botton_size,
  //             },
  //           ],
  //         },
  //       },
  //     ],
  //   },
  // };
  // try {
  //   const res = await CustomerServiceInstance.updateCustomer(body);
  //   if (res.status.success) {
  //     dispatch(updateCustomerData(res?.customers?.customer[0]));
  //     onClose();
  //   }
  // } catch (err) {
  //   console.log("Error while updating", err);
  // }
  // const existingCustomFields = customerData?.custom_fields?.field || [];

  // // Find the existing brand object
  // const isBranpOptIn = existingCustomFields.find(
  //   (item) => item.name.toLowerCase() === customF.toLowerCase()
  // );

  const handleOptIn = async () => {
    const brandN = customF;

    const existingCustomFields = customerData?.custom_fields?.field || [];

    // Find the existing brand object
    const existingBrandObject = existingCustomFields.find(
      (item) => item.name.toLowerCase() === brandN.toLowerCase()
    );
    // Toggle the value
    const newBrandValue = existingBrandObject
      ? existingBrandObject.value === "true"
        ? "false"
        : "true"
      : "true";

    // Create a new custom fields array
    const updatedCustomFields = existingCustomFields.map((item) => {
      if (item.name.toLowerCase() === brandN.toLowerCase()) {
        // Return a new object with the updated value
        return { ...item, value: newBrandValue };
      }
      return item; // Keep the unchanged field
    });

    // If the brand object didn't exist before, add it
    if (!existingBrandObject) {
      updatedCustomFields.push({
        name: brandN,
        value: newBrandValue,
      });
    }
    const updatedBrandObject = {
      name: brandN,
      value: newBrandValue,
    };

    // Update the state with the new brand object
    setIsBrandOpted(updatedBrandObject);

    const body = {
      root: {
        customer: [
          {
            mobile: sessionStorage.getItem("mobile"),
            firstname: customerData.firstname,
            lastname: customerData.lastname,
            email: customerData.email,
            custom_fields: {
              field: updatedCustomFields, // Use the updated custom fields array
            },
          },
        ],
      },
    };

    try {
      const res = await CustomerServiceInstance.updateCustomer(body);
      if (res.status.success) {
        dispatch(updateCustomerData(res?.customers?.customer[0]));
        onClose();
      }
    } catch (err) {
      console.log("Error while updating", err);
    }
  };

  return (
    <CustomModel>
      <div className="flex flex-col justify-center items-center">
        {/* <img
          src={mkLogo}
          alt="logo"
          width=""
          height="25.61px"
          className="mt-44"
        ></img> */}
        <p className="text-white w-[270px] h-[25.61px] mt-30 text-center text-4xl">
          {brandName}
        </p>
        <h1 className="title mt-20">{t("cards.modal.title")}</h1>

        {!(isBrandOpted?.value === "true") ? (
          <button
            className="button flex items-center justify-center mb-[10px] mt-10"
            onClick={handleOptIn}
          >
            <img src={rightIcon} alt="icon" className="ml-2"></img>
            <span className="flex-1 text-center">
              {t("cards.modal.button1")}
            </span>
          </button>
        ) : (
          <button
            className="button  flex items-center justify-center bgBlack mb-[10px] mt-10"
            onClick={handleOptIn}
          >
            <img src={closeIcon} alt="icon" className="ml-2"></img>
            <span className="flex-1 text-center">
              {t("cards.modal.button2")}
            </span>
          </button>
        )}
        <p className="text-[#e0dcdc] playfair italic text-base text-center w-[70vmin] mt-12">
          {t("cards.modal.desc")}
          <span className="underline block mt-6">{t("cards.modal.link")}</span>
        </p>
        <button className="mt-20" onClick={onClose}>
          <img src={crossIcon} alt="icon"></img>
        </button>
      </div>
    </CustomModel>
  );
}
export default MKCardModel;
