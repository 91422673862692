import React, { useEffect, useState } from "react";
import { Button, Drawer, Modal, Spin } from "antd";

import "./styles.css";
import Header2 from "../../components/Header2";
import Filters from "../../components/Filters";
import RewardCatalog from "../../components/RewardCatalog";
import CrossIcon from "../../assets/crossIcon.svg";
import { useTranslation } from "react-i18next";
import CustomerServiceInstance from "../../utils/customer-service";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { motion as m } from "framer-motion";
import { getRewardCatalogCategory } from "../../APIs/api";
import ritualImg from "../../assets/ritual.svg";

export default function RewardsCatalog() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [rewardDetailList, setRewardDetailList] = useState([]);
  const [reward, setReward] = useState({});
  const [termAndConditions, setTermsAndConditions] = useState();
  const [remainingDays, setRemainingDays] = useState();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [renderedRewards, setRenderedRewards] = useState(rewardDetailList);
  const rewardsList = rewardDetailList;
  const [categoryList, setCategoryList] = useState([]);

  const customerData = useSelector(
    (store) => store.customerDetails.customerData
  );
  const onClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    fetchRewardsCatalogDetails();
  }, []);
  useEffect(() => {
    async function getRewardCategory() {
      const dataObj = await getRewardCatalogCategory();
      const dataArray = dataObj;

      if (dataArray !== undefined) {
        setCategoryList(dataArray);
      } else {
        //brandopt
      }
    }
    getRewardCategory();
    // eslint-disable-next-line
  }, []);
  const fetchRewardsCatalogDetails = async () => {
    try {
      setLoading(true);
      const res = await CustomerServiceInstance.getRewards();
      if (res?.status?.success) {
        setRewardDetailList(res?.rewardList);
      }
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    // Disable scrolling when modal is open
    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [open]);
  const handleImageClickToOpenDrawer = (reward) => {
    setOpen(true);

    setReward(reward);
    getTermsAndConditions(reward.termAndConditionsUrl);
    calculateDaysRemaining(reward.endTime);
  };

  // Calculate the number of days to the expiry date
  const calculateDaysRemaining = (expiryDate) => {
    const formattedDate = expiryDate?.replace(" ", "T");
    const currentDate = new Date();
    const expiryDateObj = new Date(formattedDate);
    const timeDifference = expiryDateObj - currentDate;
    const daysRemaining = Math.ceil(timeDifference / (1000 * 60 * 60 * 24)); // Convert milliseconds to days
    setRemainingDays(daysRemaining);
  };

  //fetch termandconditions
  const getTermsAndConditions = async (tandurl) => {
    try {
      if (tandurl || tandurl.length > 0) {
        const res = await fetch(tandurl);

        const tandc = await res.text();

        setTermsAndConditions(tandc);
      }
    } catch (err) {
      console.log("TAndC Error", err);
    }
  };
  const handleRedeemClick = async (reward) => {
    if (customerData?.loyalty_points >= reward?.intouchPoints) {
      try {
        setLoading(true);
        const res = await CustomerServiceInstance.redeemReward(reward?.id);
        if (res.status.success) {
          toast.success("Redeemed successfully!", {
            position: "bottom-right",
            autoClose: 4000,
            hideProgressBar: true,
            closeButton: true,
          });
          navigate("/rewards-list");
        } else {
          toast.error(res.status.message, {
            position: "bottom-right",
            autoClose: 4000,
            hideProgressBar: true,
            closeButton: true,
          });
          setOpen(false);
        }
      } catch (err) {
        setError(err);
      } finally {
        console.log(error);

        setLoading(false);
      }
    } else {
      Modal.warning({
        title: (
          <span className="playfair text-md text-white">
            "You can't redeem this reward"
          </span>
        ),
        content: (
          <span className="playfair text-white">
            "Fail to issue reward as points are not redeemable"
          </span>
        ),
        style: {
          top: "50%",
          transform: "translateY(-50%)",
        },
        className: "custom-modal",
      });
    }
  };

  // const categoryList = [
  //   {
  //     cat_id: 374,
  //     title: "perfumes & cosmetics",
  //   },
  //   { cat_id: 375, title: "fashion & accessories" },
  //   { cat_id: 123, title: "F&B" },
  // ];

  if (loading || (loading && renderedRewards.length === 0))
    return (
      <div className="loading-div">
        <Spin size="large" />
      </div>
    );
  return (
    <m.div className="min-h-dvh">
      <Header2 />
      <div className="p-2 pb-24">
        <div className="obviously py-2 text-lg pl-3">
          <h2>{t("rewardsCatalog.title")}</h2>
        </div>
        <Filters
          categoryList={categoryList}
          setRenderedBrands={setRenderedRewards}
          brands={rewardsList}
        />
        <div className="mt-4 flex flex-col gap-4">
          {renderedRewards.length > 0 ? (
            renderedRewards.map((reward, index) => (
              <div
                key={reward.id}
                className={`text-white relative h-40 flex flex-col justify-between px-4 py-4 rounded-xl`}
                onClick={() => handleImageClickToOpenDrawer(reward)}
              >
                <div
                  className="absolute inset-0 h-full w-full rounded-xl"
                  style={{
                    backgroundImage: `url(${reward.imageUrl})`,
                    filter: `${reward.isLocked && "blur(2px)"}`,
                  }}
                ></div>
                <div className="absolute inset-0 h-full w-full bg-black opacity-20 rounded-xl"></div>
                <RewardCatalog reward={reward} index={index} />
              </div>
            ))
          ) : (
            <div className="flex items-center justify-center flex-col">
              <img src={ritualImg} alt="ritual-img"></img>
              <p className="text-2xl text-center  font-[400] italic text-black">
                No Reward found!
              </p>
            </div>
          )}
        </div>
      </div>
      <Drawer
        placement="bottom"
        closable={false}
        onClose={onClose}
        open={open}
        getContainer={false}
        maskClosable={false}
        style={{
          borderTopLeftRadius: 20,
          borderTopRightRadius: 20,
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 10,
          width: "95%",
          height: "97%",
          transform: "translate(10px, 0px)",
          transition: "all 0.3s ease",
        }}
        // rootStyle={{ width: "90%", margin: "0 auto", outline: "none" }}
        rootClassName="hell"
        styles={{ body: { backgroundColor: "black" } }}
      >
        <div
          className="absolute inset-0 h-44 bg-cover w-full rounded-t-2xl"
          style={{
            backgroundImage: `url(${reward.imageUrl})`,
          }}
        ></div>
        <div className="mt-44 flex flex-col gap-6 mb-10">
          <RewardCatalog reward={reward} index={-1} open={open} />
        </div>
        <div className="text-white">
          <p className="font-bold">TERMS & CONDITIONS</p>
          <div className="my-8" style={{ fontSize: "12px", lineHeight: "1.5" }}>
            {/* <p>$25 Sexy T and Bralettes</p>
            <p>
              Select styles. Excludes PINK, third-party styles, and clearance.
              Priced as marked.
            </p> */}
          </div>
          {/* <ul
            className="obviously list-decimal pl-4"
            style={{ fontSize: "10px", lineHeight: "1.5" }}
          > */}
          {/* <li>
              It’s a very exciting news and we look forward to further business
              expansion.
            </li>
            <li>It’s a very exciting news</li>
            <li>It’s a very exciting news and we look forward</li>
            <li>It’s a very exciting news and we look</li>
            <li>
              It’s a very exciting news and we look forward to further business
              expansion.
            </li>
            <li>It’s a very exciting news</li>
            <li>It’s a very exciting news and we look forward</li> */}
          <p>{termAndConditions}</p>
        </div>
        <div className="text-white flex flex-col my-11 items-center gap-4 obviously">
          <div className="bg-white text-black px-4 py-2 text-xs rounded-full">
            EXPIRES IN {remainingDays} DAYS OF PURCHASE
          </div>
          <Button
            className="h-11 bg-[#B78600] border-0 rounded-full text-white py-4 px-11"
            onClick={() => handleRedeemClick(reward)}
          >
            REDEEM
          </Button>
        </div>

        <div className="flex justify-center mb-4">
          <img
            src={CrossIcon}
            alt="Cross icon"
            onClick={() => setOpen(false)}
          />
        </div>
      </Drawer>
    </m.div>
  );
}
