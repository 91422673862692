import { useEffect, useRef, useState } from "react";
import { Button, Input, Spin } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import backgroundVideo from "../../assets/videos/background-video.mp4";
import {
  addCustomerData,
  updateCustomerData,
} from "../../redux/slices/customerDetails";
import AuthService from "../../utils/auth-service";
import customerService from "../../utils/customer-service";
import { useAuth } from "../../utils/AuthProvider";

import "./styles.css";

import eyeIcon from "../../assets/eyebrow.svg";
import { toast } from "react-toastify";
import MobileNumberInput from "../../components/MobileNumberInput";
import CustomerServiceInstance from "../../utils/customer-service";
import backIcon from "../../assets/backIcon.svg";
import eyeOpenIcon from "../../assets/open-eye.svg";

// const lngs = [
//   ["en", "English"],
//   ["ms", "Malay"],
// ];

function LoginPage({ setShowNav }) {
  const [showPassword, setShowPassword] = useState(false);
  const [phoneRegex, setPhoneRegex] = useState("");
  // const [countryDetails, setCountryDetails] = useState();
  const { t } = useTranslation();
  // const handleChange = (value) => {
  //   i18n.changeLanguage(value);
  // };

  const { setIsLoggedIn } = useAuth();
  const navigate = useNavigate();
  const [phoneNumber, setPhoneNumber] = useState(
    sessionStorage.getItem("phone")
  );
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [countryCode, setCountryCode] = useState(
    sessionStorage.getItem("countryCode")
  );
  const [mobileError, setMobileError] = useState("");
  const dispatch = useDispatch();
  const videoRef = useRef(null);
  const [videoLoaded, setVideoLoaded] = useState(false);
  // const fetchcountries = async () => {
  //   setLoading(true);
  //   try {
  //     const res = await getCountryCodeList();
  //     setCountryDetails(res?.data[0]?.attributes?.country?.country_details);
  //   } catch (err) {
  //     setError(err.message);
  //   } finally {
  //     setLoading(false);
  //   }
  // };
  useEffect(() => {
    setShowNav(false);
    // fetchcountries();
    // eslint-disable-next-line
  }, []);
  const handleToggle = () => {
    setShowPassword((prev) => !prev);
  };
  const handleLogin = async (e) => {
    e.preventDefault();
    const regex = new RegExp(phoneRegex);
    if (phoneNumber?.length === 0) {
      setMobileError("Please enter the mobile number");
      return;
    } else if (!regex?.test(phoneNumber)) {
      setMobileError("Enter a valid phone number");
      return;
    }
    if (password?.length === 0) {
      setError("Please enter the password");
      return;
    }
    const fullNo = countryCode + phoneNumber;
    sessionStorage.setItem("phone", phoneNumber);
    sessionStorage.setItem("countryCode", countryCode);
    sessionStorage.setItem("mobile", fullNo);
    setLoading(true);
    try {
      const tokenRes = await AuthService.tokenGenerate(password);

      if (tokenRes.status.success) {
        try {
          const validateRes = await AuthService.passwordValidate(password);
          if (validateRes.status.success) {
            try {
              const getCustomer = await customerService.getCustomer();
              if (getCustomer.status.success) {
                const customerData = getCustomer.customers.customer[0];
                const customFields = customerData.custom_fields.field;

                // Check for app_login and app_login_date fields
                const appLoginField = customFields.find(
                  (field) => field.name === "app_login"
                );
                const appLoginDateField = customFields.find(
                  (field) => field.name === "app_login_date"
                );

                if (appLoginField && appLoginDateField) {
                  // If both fields exist, navigate to the Valiram card
                  dispatch(
                    addCustomerData(getCustomer?.customers?.customer[0])
                  );
                  sessionStorage.setItem("tour", true);
                  setIsLoggedIn(true);
                  navigate("/valiram-card");
                } else {
                  // Create a copy of custom fields to update
                  let updatedFields = [...customFields];

                  // Check if app_login exists, if not, add it
                  if (!appLoginField) {
                    updatedFields.push({ name: "app_login", value: true });
                  }

                  // Check if app_login_date exists, if not, add it
                  if (!appLoginDateField) {
                    const date = new Date();
                    const day = String(date.getDate()).padStart(2, "0");
                    const month = String(date.getMonth() + 1).padStart(2, "0");
                    const year = date.getFullYear(); // Get full year

                    const formattedDate = `${day}-${month}-${year}`;
                    updatedFields.push({
                      name: "app_login_date",
                      value: formattedDate,
                    });
                  }

                  const body = {
                    root: {
                      customer: [
                        {
                          mobile: sessionStorage.getItem("mobile"),
                          firstname: customerData.firstName,
                          lastname: customerData.lastName,
                          email: customerData.email,
                          custom_fields: {
                            field: updatedFields,
                          },
                        },
                      ],
                    },
                  };

                  // Call the update customer API
                  const updateResponse =
                    await CustomerServiceInstance.updateCustomer(body);

                  if (updateResponse.status.success) {
                    // Dispatch updated customer data
                    dispatch(
                      updateCustomerData(updateResponse?.customers?.customer[0])
                    );

                    // Navigate to the Valiram card
                    sessionStorage.setItem("tour", true);
                    setIsLoggedIn(true);
                    navigate("/valiram-card");
                  } else {
                    // console.log(updateResponse.status.message);
                    setError(updateResponse.status.message);
                  }
                }
              } else {
                // console.log(getCustomer.status.message);
                setError(getCustomer.status.message);
              }
            } catch (error) {
              console.log("Failed to get or update Customer data: " + error);
            }
          } else {
            // setError(validateRes.status.message);
            toast.error(validateRes.status.message, {
              position: "bottom-right",
              autoClose: 4000,
              hideProgressBar: true,
              closeButton: true,
            });
            if (validateRes.status.code === 1515) {
              navigate("/signup");
            }

            // console.log(validateRes.status.message);
          }
        } catch (validateErr) {
          setError("Password Validation failed" + validateErr.message);
          console.log("Password Validation failed", validateErr.message);
        }
      } else {
        setError(tokenRes.status.message);
        console.log(tokenRes.status.message);
      }
    } catch (tokenGenerationError) {
      console.log(error);
      console.log("Token generation failed", tokenGenerationError.message);
    } finally {
      setLoading(false);
    }
  };
  const handleSignupClick = () => {
    sessionStorage.removeItem("countryCode");
    navigate("/signup");
  };
  useEffect(() => {
    // const video = document.querySelector(".qVaWx1ia4MK_oyPXz791");
    const video = videoRef.current;
    if (video) {
      video.load();
      video.play().catch((error) => {
        console.log("Video play failed:", error);
      });
    }
    setVideoLoaded(true);
    return () => {
      // Optionally store the current video state before unmounting
      if (video) {
        setVideoLoaded(false);
      }
    };
  }, []);
  const handlePasswordChange = (e) => {
    const newPassword = e.target.value.replace(/\s/g, ""); // Remove all spaces
    setPassword(newPassword);
    setError("");
  };
  if (loading)
    return (
      <div className="loading-div">
        <Spin size="large" />
      </div>
    );

  return (
    <div className="loginpage-container">
      <div className="video-background">
        <video
          className="qVaWx1ia4MK_oyPXz791"
          src={backgroundVideo}
          preload="auto"
          autoPlay={videoLoaded}
          loop
          muted
          ref={videoRef}
          playsInline
        ></video>
      </div>
      <div className="back-button-container justify-between items-center">
        <button className="back-button" onClick={() => navigate(-1)}>
          <img src={backIcon} alt="back-button"></img>
        </button>
        {/* <Select
          defaultValue={i18n.resolvedLanguage}
          style={{ width: 150 }}
          onChange={handleChange}
          options={lngs.map((lng) => {
            return { value: lng[0], label: lng[1] };
          })}
        /> */}
      </div>
      {/* <div className="overlay"></div> */}
      <div className="loginpage-content">
        <h1 className="heading">Welcome Back</h1>
        <p className="subtitle" style={{ fontSize: "16px" }}>
          {t("loginpage.desc")}
        </p>
        <form autoComplete="off" onSubmit={handleLogin}>
          {/* <Input
            type="tel"
            placeholder={t("loginpage.placeholder1")}
            className="input-field"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
          /> */}

          <MobileNumberInput
            setError={setMobileError}
            phoneNo={phoneNumber}
            setPhoneNo={setPhoneNumber}
            setCountryCode={setCountryCode}
            autoComplete="off"
            setPhoneRegex={setPhoneRegex}
          />

          {mobileError && (
            <p
              style={{
                color: "white",
                textAlign: "center",
                fontFamily: "Obviously",
              }}
              className="text-[10.5px] leading-3 mb-3"
            >
              <span
                style={{
                  borderBottom: "2px solid #A00808",
                  textDecoration: "none",
                }}
              >
                {mobileError}
              </span>
            </p>
          )}
          <div className="relative w-full">
            <Input
              type={showPassword ? "text" : "password"}
              placeholder={t("loginpage.placeholder2")}
              className="input-field"
              value={password}
              onChange={handlePasswordChange}
              autoComplete="off"
            />

            <Button
              onClick={handleToggle}
              type="button"
              className="toggle-button border-none"
            >
              {!showPassword ? (
                <img src={eyeIcon} alt="eye icon"></img>
              ) : (
                <img src={eyeOpenIcon} alt="eye icon"></img>
              )}
            </Button>
          </div>
          {error && (
            <p
              style={{
                color: "white",
                textAlign: "center",
                fontFamily: "Obviously",
              }}
              className="text-[10.5px] leading-3 mb-3"
            >
              <span
                style={{
                  borderBottom: "2px solid #A00808",
                  textDecoration: "none",
                }}
              >
                {error}
              </span>
            </p>
          )}
          {/* {error && (
            <p style={{ color: "red", textAlign: "center" }}>{error}</p>
          )} */}
          <div className="submitbutton-container">
            <button
              type="submit"
              className="button "
              disabled={!!error || !!mobileError}
            >
              Log In
            </button>
          </div>
        </form>

        <Link className="forgot-link" to="/forgotPassword">
          {t("loginpage.forgot") + "?"}
        </Link>

        <div className="footer">
          <p>New to Valiram+</p>
          <p onClick={handleSignupClick}>({t("loginpage.create")})</p>
        </div>
      </div>
    </div>
  );
}
export default LoginPage;
