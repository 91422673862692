import { useEffect, useState } from "react";

function useOtpTimer() {
  const [timeLeft, setTimeLeft] = useState(60);
  useEffect(() => {
    if (timeLeft === 0) return;
    const timer = setInterval(() => {
      setTimeLeft((t) => t - 1);
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, [timeLeft]);
  const resetTimer = () => {
    setTimeLeft(60); // Reset the timer to 30 seconds
  };
  return { timeLeft, setTimeLeft, resetTimer };
}
export default useOtpTimer;
