import { Drawer } from "antd";
import React from "react";

import ChartNetwork from "../assets/chart-network.svg";
import Down from "../assets/down.svg";
import { Link } from "react-router-dom";

export default function ConnectDrawer({ open, onClose, brandDetails }) {
  const capitalizeFirstLetter = (string) => {
    if (string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
  };

  return (
    <Drawer
      placement="bottom"
      closable={false}
      onClose={onClose}
      open={open}
      getContainer={false}
      maskClassName="bg-no"
      width={900}
      // style={{ height: "115%" }}
      // rootStyle={{ width: "94%", margin: "0 auto", outline: "none" }}
      // height="90.5vh"
      // // borderRadius="1rem"
      // rootClassName="hell"\
      style={{
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20,
        position: "fixed",
        bottom: 0,
        left: "50%",
        right: 0,
        zIndex: 10,
        width: "92%",
        height: "96.5%",
        transform: "translateX(-50%)",
        transition: "all 0.3s ease",
      }}
      rootStyle={{ width: "96%", margin: "0 auto", outline: "none" }}
      // height="98.5vh"
      borderRadius="1rem"
      styles={{
        body: { backgroundColor: "#714F01" },
        mask: { pointerEvents: "auto" },
      }}
      maskClosable={false}
      className="rounded-t-2xl rounded-tr-2xl"
    >
      <div>
        <div className="flex gap-4 items-center">
          <img src={ChartNetwork} alt="connect icon" />
          <p className="text-white text-base obviously">Connect</p>
        </div>
        <div className="p-8 flex justify-center items-center border-b-[1px] border-white border-solid">
          <p className="text-white text-3xl  playfair-bold">
            {brandDetails?.brandName}
          </p>
        </div>
        <div className="text-white text-center py-6 mb-16">
          <div className="mb-16">
            <p className="playfair italic text-2xl">
              Connect with Brands Official Media Platform
            </p>
          </div>
          <div className="obviously flex flex-col gap-3 text-base">
            {brandDetails?.social_link?.social_link.map((item, index) => {
              return (
                <Link key={index} to={item.link}>
                  <p className="obviously text-[18px] leading-10 ">
                    {" "}
                    {capitalizeFirstLetter(item.name)}
                  </p>
                </Link>
              );
            })}
          </div>
        </div>
        <div className="flex justify-center">
          <img src={Down} alt="down icon" onClick={onClose} />
        </div>
      </div>
    </Drawer>
  );
}
