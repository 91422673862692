import React, { useEffect, useState } from "react";
import { Button, Spin } from "antd";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CustomerServiceInstance from "../../utils/customer-service";

import { useJsApiLoader } from "@react-google-maps/api";

import "./styles.css";
import Map from "../../components/Map";
import BackIcon from "../../assets/backIcon.svg";
import valiramLogo from "../../assets/valiram.svg";
import Filters from "../../components/Filters";
import { getStoresCategory } from "../../APIs/api";
import ritualImg from "../../assets/ritual.svg";
import StoreImage from "../../assets/svg/store-image.svg";
import { getStoreImages } from "../../APIs/api";

const gogleMapKey = process.env.REACT_APP_googleMapKey;

export default function BrandsStoreListing({
  brand,
  setStore,
  storeIds,
  setStoreImages,
  storeImages,
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [view, setView] = useState(true);
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [stores, setStores] = useState([]);
  const [renderedStores, setRenderedStores] = useState(stores);
  const storeImagesTmp = [StoreImage, StoreImage, StoreImage];

  // const storeIds = brand.store_ids;
  const renderedStoreIds =
    storeIds?.length !== 0 ? storeIds : brand?.store_ids?.store_ids;
  const handleClick = async (store) => {
    setStore(store);
    const storeData = storeImages?.filter(
      (val) => val?.storeID === store?.storeId
    );
    if (storeData?.length === 0) {
      setStoreImages(storeImagesTmp);
    } else {
      setStoreImages(storeData?.[0]?.storeImages);
    }
    navigate("/store-page");
  };

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: gogleMapKey,
  });
  useEffect(() => {
    async function getStoresCategoryFunc() {
      const dataObj = await getStoresCategory();
      const dataArray = dataObj;

      if (dataArray !== undefined) {
        setCategories(dataArray);
      } else {
        //brandopt
      }
    }
    getStoresCategoryFunc();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    // if (Object.keys(brand).length === 0) {
    //   navigate("/brands");
    // }
    async function getStores() {
      setLoading(true);
      const dataObj = await getStoreImages();
      const dataArray = dataObj?.data?.[0]?.attributes?.data?.storeImages;
      if (dataArray !== undefined) {
        setStoreImages(dataArray);
      } else {
        console.log("Request Failed");
        navigate(-1);
      }
      const data = await CustomerServiceInstance.getNerbyStores();
      if (data?.status?.success) {
        if (Object.keys(brand).length === 0 && storeIds.length === 0) {
          const newStores = data?.storesWithDistance?.filter(
            (stores) => stores?.latitude && stores?.longitude
          );
          const newNewStores = newStores?.map((store) => {
            const temp = dataArray?.find(
              (val) => val?.storeID === store?.storeId
            );
            if (temp) {
              store = { ...store, ...temp };
            }
            return store;
          });
          setStores(newNewStores);
          setRenderedStores(newNewStores);
        } else {
          const newStores = data?.storesWithDistance
            ?.filter((stores) => stores?.latitude && stores?.longitude)
            ?.filter(
              (store) =>
                renderedStoreIds?.includes(Number(store?.storeId)) ||
                renderedStoreIds?.includes(store?.storeId)
            );

          const newNewStores = newStores?.map((store) => {
            const temp = dataArray?.find(
              (val) => val?.storeID === store?.storeId
            );
            if (temp) {
              store = { ...store, ...temp };
            }
            return store;
          });

          setStores(newNewStores);
          setRenderedStores(newNewStores);
        }
      } else {
        // setStores(storesOpt);
        console.log("Request Failed");
      }
      setLoading(false);
    }
    getStores();
    // eslint-disable-next-line
  }, []);

  const getTimings = (timings) => {
    // Extracting the opening and closing times using regex
    let match = timings?.match(/(\d{1,2})(am)- (\d{1,2})(pm)/);
    if (match) {
      let openingHour = parseInt(match[1]);
      let closingHour = parseInt(match[3]) + 12; //(converted to 24-hour format)

      let now = new Date();
      let currentHour = now?.getHours(); // Get current hour (24-hour format)

      if (currentHour >= openingHour && currentHour < closingHour) {
        return t("brands.open", { time: "10:00 PM" });
      } else {
        return t("brands.soon", { time: "10:00 AM" });
      }
    }
  };
  if (loading || (loading && renderedStores?.length === 0))
    return (
      <div className="loading-div">
        <Spin size="large" />
      </div>
    );
  return (
    <>
      {!view ? (
        <Map
          isLoaded={isLoaded}
          view={view}
          setView={setView}
          stores={stores}
        />
      ) : (
        <div className="p-2 bg-white min-h-dvh">
          <div className="flex items-center mt-4">
            <img src={BackIcon} alt="back icon" onClick={() => navigate(-1)} />
          </div>
          <div className="py-8 flex justify-center items-center flex-col gap-3 obviously">
            <div className="w-full flex justify-center items-center">
              <img
                src={brand?.img || valiramLogo}
                width={150}
                height={95}
                alt="brand logo"
              />
            </div>
            <p className="text-xs">
              {renderedStores?.length} {t("brands.stores")}
            </p>
          </div>
          <Filters
            categoryList={categories}
            setRenderedBrands={setRenderedStores}
            brands={stores}
          />

          <div className="pb-28">
            {renderedStores?.length > 0 ? (
              renderedStores?.map((store, index) => (
                <div
                  key={index}
                  className="flex h-24 rounded-2xl shadow-lg mb-2 bg-white p-2"
                  onClick={() => handleClick(store)}
                >
                  <div className="basis-2/6 flex items-center justify-center">
                    <img
                      src={store?.storeLogoImage || valiramLogo}
                      alt="brand logo"
                      width={"80px"}
                      className="max-h-[115%]"
                    />
                  </div>
                  <div className="basis-2/3 flex justify-center items-start flex-col obviously p-2 text-xs font-bold">
                    <div className="flex mb-3 justify-between items-center w-full">
                      <h5>{store?.storeName?.split(" - ")[0]}</h5>
                      <p className="text-[#5E5E5E] ">
                        {Math.trunc((store?.distance / 1000) * 10) / 10} KM
                      </p>
                    </div>
                    {Object.keys(store?.customFields)?.length !== 0 ? (
                      <div className="flex items-center gap-2 w-full">
                        <div
                          className={`w-2 h-2 rounded-full ${
                            store?.customFields?.is_Active
                              ? "bg-[#1CB45D]"
                              : "bg-[#BCBCBC]"
                          }`}
                        ></div>
                        <p
                          className="text-[#666666] "
                          style={{ fontSize: "10px", letterSpacing: "0.5px" }}
                        >
                          {getTimings(store?.customFields?.operating_hours)}
                        </p>
                      </div>
                    ) : (
                      <div>No Timing Info Available</div>
                    )}
                  </div>
                </div>
              ))
            ) : (
              <div className="flex items-center justify-center flex-col">
                <img src={ritualImg} alt="No stores found" />
                <p className="text-2xl text-center font-[400] italic">
                  No Stores found!
                </p>
              </div>
            )}
          </div>
          <div className="text-center fixed left-[39%] bottom-24">
            <Button
              shape="round"
              className="bg-black text-white border-0 playfair italic btn w-28 h-9"
              onClick={() => setView(!view)}
            >
              {view ? t("brands.map") : t("brands.list")}
            </Button>
          </div>
        </div>
      )}
    </>
  );
}
