import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
// import { useDispatch } from "react-redux";

import { getTourScreens } from "../../APIs/api";

import "./styles.css";
import Header from "../../components/Header";
import LoyaltyCard from "../../components/LoyaltyCard";
import TourGuide from "../../components/TourGuide";
import carouselImage1 from "../../assets/Brand_Carousel_image1.png";
import carouselImage2 from "../../assets/Brand_Carousel_image2.png";
import carouselImage3 from "../../assets/Brand_Carousel_image3.png";
import brandImg1 from "../../assets/mkLogo1.png";
import brandImg2 from "../../assets/svg/nike-logo.svg";
import brandImg3 from "../../assets/carousal_image3.png";
import viewMoreIcon from "../../assets/view-more.svg";
import Food from "../../assets/svg/food.svg";

export default function ValiramCard({
  setShowNav,
  stores,
  brands,
  category,
  setBrand,
  setStoreIds,
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [tourPage, setTourPage] = useState(() =>
    sessionStorage.getItem("tour") === null ? 0 : 6
  );
  const isOnTour = tourPage <= 5;
  const [tourScreens, setTourScreens] = useState([]);
  const [tourScreensLoading, setTourScreensLoading] = useState([]);
  // const dispatch = useDispatch();

  const storesTemp = [
    {
      name: "TORY BRUCH",
      bgImage: carouselImage1,
    },
    {
      name: "STEVE MADDEN",
      bgImage: carouselImage2,
    },
    {
      name: "VICTORIA'S SECRET",
      bgImage: carouselImage3,
    },
  ];
  const brandsTemp = [
    {
      name: "MICHAEL KORS",
      color: "#694502",
      bgImage: brandImg1,
      url: "/mk-card",
      brand_id: 4,
    },
    {
      name: "Bath and Body Works",
      color: "#ffffff",
      bgImage: brandImg3,
      url: "/rituals-card",
      brand_id: 3,
    },
    {
      name: "Nike",
      color: "#000000",
      bgImage: brandImg2,
      url: "/nike-card",
      brand_id: 2,
    },
  ];
  const brandsNew = brands?.map((brand, index) => {
    return { ...brand, url: brandsTemp[index]?.url };
  });

  useEffect(() => {
    if (sessionStorage.getItem("tour") === null) {
      sessionStorage.setItem("tour", true);
    }
    setShowNav(true);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setBrand({});
    setStoreIds([]);
    if (tourPage < 6) {
      async function getData() {
        setTourScreensLoading(true);
        const dataObj = await getTourScreens();
        const dataArray = dataObj?.data?.[0]?.attributes?.data?.data;
        if (dataArray !== undefined) {
          setTourScreens([...dataArray, ...dataArray]);
          setTourScreensLoading(false);
        } else {
          setTourScreensLoading(false);
          setTourPage(6);
        }
      }
      getData();
    }
    // setBrand([]);
    // eslint-disable-next-line
  }, []);
  function getStoreIds(url) {
    const urlParams = new URL(url)?.searchParams;
    const storeIds = urlParams?.get("storeid");
    return storeIds ? storeIds?.split(",") : [];
  }
  const handleStoreClick = (store) => {
    setStoreIds(getStoreIds(store?.deeplink));
    navigate("/brands-store-listing");
  };
  const handleClick = (brand, index) => {
    const params = new URLSearchParams(new URL(brand?.deeplink)?.search);

    // Get the value of the 'brand_id' parameter
    const brand_id = params.get("id");
    // if (brand?.title.includes("&")) {
    //   console.log(brand?.title.includes("&"));
    //   navigate("/brand-page/" + brand_id + "/" + brand.title.replace(/&/g, ""));
    // } else
    navigate("/brand-page/" + brand_id);
  };

  return (
    <>
      {!isOnTour ? (
        <>
          <Header
            showLogo
            //  bgColor={"transparent"} textColor={"white"}
          />
          <div className="p-3">
            <LoyaltyCard />
            <div className="pt-3">
              <div className="flex justify-between text-black">
                <h3 className="playfair text-lg">{t("valiramCard.stores")}</h3>
                <div>
                  <img
                    src={viewMoreIcon}
                    alt="view more icon"
                    onClick={() => navigate("/brands-store-listing")}
                  />
                </div>
              </div>
              <div className="carousel-container">
                {(stores !== undefined ? stores : storesTemp)?.map(
                  (store, index) => (
                    <div
                      key={index}
                      className="card card1 flex items-center justify-center bg-cover bg-top shadow-lg"
                      style={{ backgroundImage: `url(${store.bgImage})` }}
                      onClick={() => handleStoreClick(store)}
                    ></div>
                  )
                )}
              </div>
            </div>
            <div>
              <div className="flex justify-between text-black">
                <h3 className="playfair text-lg">{t("valiramCard.brands")}</h3>
                <div>
                  <img
                    src={viewMoreIcon}
                    alt="view more icon"
                    onClick={() => navigate("/brands")}
                  />
                </div>
              </div>
              <div className="carousel-container">
                {brandsNew?.map((brand, index) => (
                  <div
                    key={index}
                    className="card bg-contain bg-center bg-no-repeat"
                    style={{
                      height: "100px",
                      backgroundImage: `url(${brand.bgImage})`,
                      border: `0.3px solid #000000`,
                    }}
                    onClick={() => handleClick(brand, index)}
                  ></div>
                ))}
              </div>
            </div>
            <div>
              <div className="text-black">
                <h3 className="playfair text-lg">
                  {t("valiramCard.categories")}
                </h3>
              </div>

              <div
                className="h-32 rounded-2xl text-center pt-4 mt-2 playfair italic text-white text-3xl mb-24 bg-cover"
                style={{
                  backgroundImage: `url(${category ? category : Food})`,
                }}
              >
                {/* {t("valiramCard.food")} */}
              </div>
            </div>
          </div>
        </>
      ) : (
        <TourGuide
          tourPage={tourPage}
          setTourPage={setTourPage}
          tourScreens={tourScreens}
          tourScreensLoading={tourScreensLoading}
        />
      )}
    </>
  );
}
