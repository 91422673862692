import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import "./styles.css";
import Cards from "../../components/Cards";
import MKCardModel from "../../components/MKCardModel";
import ConnectDrawer from "../../components/ConnectDrawer";
import CatalogDrawer from "../../components/CatalogDrawer";
import LocationIcon from "../../assets/svg/location.svg";
import Catalog from "../../assets/svg/catalog.svg";
import Sparkles from "../../assets/sparkles.svg";
import ChartNetwork from "../../assets/chart-network.svg";
import mkcard2img2 from "../../assets/mkcard2img2.png";
import plusIcon from "../../assets/plusIcon.svg";
import BackIcon from "../../assets/backIcon.svg";
import crossIcon from "../../assets/crossIcon.svg";
import { useSelector } from "react-redux";
import { getBrandByIdAndName } from "../../APIs/api";
import { Spin } from "antd";

function BrandPage({ setBrand, setStoreIds }) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [loading, setLoading] = useState(false);
  const [brandDetails, setBrandDetails] = useState({});
  const { brandId } = useParams();

  useEffect(() => {
    setLoading(true);
    async function fetchBrandDetails() {
      const dataObj = await getBrandByIdAndName(brandId);
      setLoading(false);
      const dataArray = dataObj?.data[0]?.attributes;

      if (dataArray !== undefined) {
        // console.log(dataArray);
        setBrand(dataArray);

        setBrandDetails(dataArray);
      } else {
        //brandopt
      }
    }
    fetchBrandDetails();
    setStoreIds([]);
  }, []);
  useEffect(() => {
    // Disable scrolling when modal is open
    if (open || open2) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [open, open2]);

  const onClose = () => {
    setOpen(false);
  };
  const onClose2 = () => {
    setOpen2(false);
  };
  const customerData = useSelector(
    (store) => store?.customerDetails?.customerData
  );
  const customProperties = customerData?.custom_fields?.field?.reduce(
    (acc, field) => {
      acc[field?.name] = field?.value;
      return acc;
    },
    {}
  );
  const cardDetails = [
    { title: t("cardDetails.stores"), image: LocationIcon },
    { title: "Rewards", image: Sparkles },
    { title: t("cardDetails.connect"), image: ChartNetwork },
    { title: t("cardDetails.catalog"), image: Catalog },
  ];
  const [showModal, setShowModal] = useState(false);

  // const [isBrandOpted, setIsBrandOpted] = useState(
  //   customProperties?.brands_you_love?.includes(brandId)
  // );
  const existingCustomFields = customerData?.custom_fields?.field || [];

  // Find the existing brand object
  const [existingBrandObject, setExistingBrandObject] = useState(
    existingCustomFields.find(
      (item) =>
        item.name.toLowerCase() === brandDetails?.custom_f?.toLowerCase()
    )
  );
  useEffect(() => {
    const obj = existingCustomFields.find(
      (item) =>
        item.name.toLowerCase() === brandDetails?.custom_f?.toLowerCase()
    );
    setExistingBrandObject(obj);
  }, [customerData, brandDetails]);

  const navigate = useNavigate();

  if (loading)
    return (
      <div className="loading-div">
        <Spin size="large" />
      </div>
    );
  const pageConfig = Object.keys(brandDetails?.page_cofig || {});
  return (
    <div
      className="mk2-container bg-cover bg-center "
      style={{
        backgroundImage: `url(${brandDetails?.bg_img})`,
        minHeight: "100vh",
        height: "auto",
      }}
    >
      <div className="flex justify-between items-center px-4 py-8 fixed w-full top-0">
        <div className="flex items-center rounded-full ">
          <img
            src={BackIcon}
            alt="back icon"
            className="rounded-full"
            onClick={() => navigate(-1)}
          />
        </div>
        <button className="flex items-end" onClick={() => setShowModal(true)}>
          <img
            src={
              !(existingBrandObject?.value === "true") ? plusIcon : crossIcon
            }
            alt="btn"
          ></img>
        </button>
      </div>

      <div className="mk2-content">
        <div className=" overflow-auto">
          <div className="w-full mt-[150px]  mb-[20px] flex justify-center ">
            <img
              src={brandDetails?.img}
              alt="brand logo"
              className="max-w-[200px] max-h-[100px] "
            ></img>
          </div>
          <div className="px-6 text-white">
            <h1
              className="text-center text-3xl my-8"
              style={{
                color:
                  pageConfig?.length > 0 &&
                  brandDetails?.page_cofig?.cta_content_clr
                    ? brandDetails.page_cofig.cta_content_clr
                    : "white",
              }}
            >
              {t("cards.card2.title")}
            </h1>
            <p
              className="playfair italic text-sm text-center"
              style={{
                color:
                  pageConfig?.length > 0 &&
                  brandDetails?.page_cofig?.cta_content_clr
                    ? brandDetails.page_cofig.cta_content_clr
                    : "white",
              }}
            >
              {brandDetails?.description}
              {/* {t("cards.card2.desc")} */}
            </p>
          </div>
        </div>

        {/* <div
          className="w-[90vmin] h-[110px]  mt-4 flex  rounded-[15px]  "
          style={{
            background: "#070707c4",
            opacity: "0.7",
            margin: "20px auto 0px auto",
          }}
        >
          {
            <img
              src={
                brandDetails?.ritual_icon
                  ? brandDetails?.ritual_icon
                  : mkcard2img2
              }
              alt="mkimg2"
              className="p-4 w-32"
            ></img>
          }
          <div className="flex justify-center  flex-col">
            <h1 className="text-[#fff] text-[20px] leading-6">
              {brandDetails?.ritual_heading}
              {t("cards.card2.extra1")}
            </h1>
            <p className="text-[#fff] text-[15px] leading-6 italic font-inter">
              {brandDetails?.ritual_desc}
              {t("cards.card2.extra2")}
            </p>
          </div>
        </div> */}
        <Cards
          cardDetails={cardDetails}
          bgColor={"rgba(0,0,0,0.6)"}
          setOpen={setOpen}
          setOpen2={setOpen2}
          brandDetails={brandDetails}
        />

        {showModal && (
          <div className="modal">
            <MKCardModel
              onClose={() => setShowModal(false)}
              setIsBrandOpted={setExistingBrandObject}
              brand_id={brandDetails?.brand_id}
              brandName={brandDetails?.brandName}
              isBrandOpted={existingBrandObject}
              customF={brandDetails?.custom_f}
            />
          </div>
        )}

        <ConnectDrawer
          open={open}
          onClose={onClose}
          logo={mkcard2img2}
          brandDetails={brandDetails}
        />
        <CatalogDrawer
          open2={open2}
          onClose2={onClose2}
          brandName={brandDetails?.brandName}
          cat_link={brandDetails?.catalog_link}
        />
      </div>
    </div>
  );
}
export default BrandPage;
