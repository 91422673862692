import axios from "axios";
import apiWrapper from "./api-wrapper";
// import AuthServiceInstance from "./auth-service";
// import apiRequest from "./token-refresh";
// import { getNationalityFromCountryCode } from "./constants";

axios.defaults.headers.common["Content-Type"] = "application/json";
const identifierType = process.env.REACT_APP_identifierType;
const brand = process.env.REACT_APP_brand;
const deviceId = process.env.REACT_APP_deviceId;
const gatewayURL = process.env.REACT_APP_gatewayURL;

class CustomerService {
  //addCustomer
  async addCustomer() {
    try {
      const userData = JSON.parse(sessionStorage.getItem("user"));
      // const nationality = getNationalityFromCountryCode(60);
      // console.log(nationality + getNationalityFromCountryCode(60));
      const date = new Date();
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const year = date.getFullYear(); // Get full year

      const formattedDate = `${day}-${month}-${year}`;
      const mobile = sessionStorage.getItem("mobile");
      const body = {
        root: {
          customer: [
            {
              mobile: mobile,
              firstname: userData?.firstName,
              lastname: userData?.lastName,
              email: userData?.email,
              custom_fields: {
                field: [
                  {
                    name: "app_login",
                    value: true,
                  },
                  {
                    name: "app_login_date",
                    value: formattedDate,
                  },
                ],
              },
              extended_fields: {
                field: [
                  {
                    name: "dob_date",
                    value: userData?.dob,
                  },
                  {
                    name: "gender",
                    value: userData?.gender,
                  },
                  {
                    name: "country_of_residence",
                    value: userData?.country_of_residence,
                  },
                ],
              },
            },
          ],
        },
      };
      const res = await axios.post(`${gatewayURL}customer/add`, body, {
        headers: {
          cap_authorization: sessionStorage.getItem("token"),
          cap_brand: brand,
          cap_device_id: deviceId,
          cap_identifier_type: identifierType,
          cap_identifier_value: mobile,
        },
      });

      return res.data;
    } catch (e) {
      console.log("En Error occurred while adding customer", e);
      throw e;
    }
  }

  // async getCustomer() {
  //   try {
  //     const res = await axios.get(
  //       `${gatewayURL}/customer/get?format=json&user_id=true`,
  //       {
  //         headers: {
  //           cap_authorization: sessionStorage.getItem("token"),
  //           cap_brand: brand,
  //           cap_device_id: deviceId,
  //           cap_identifier_type: identifierType,
  //           cap_identifier_value: sessionStorage.getItem("mobile"),
  //         },
  //       }
  //     );
  //     return res.data;
  //   } catch (err) {
  //     console.log("En Error occurred while fetching customer", err);
  //     throw err;
  //   }
  // }
  async getCustomer() {
    const url = `${gatewayURL}/customer/get?format=json&user_id=true&subscriptions=true&embed=promotionalPoints&tier_upgrade_criteria=true&promotion_points=true&user_id=true&expiry_schedule=true&mlp=true&embed=CUSTOMERIMAGE`;
    const headers = {
      cap_authorization: sessionStorage.getItem("token"),
      cap_brand: brand,
      cap_device_id: deviceId,
      cap_identifier_type: identifierType,
      cap_identifier_value: sessionStorage.getItem("mobile"),
    };
    return await apiWrapper(url, "GET", null, headers);
  }
  //update customer
  async updateCustomer(body) {
    // try {
    //   const mobile = sessionStorage.getItem("mobile");

    //   const res = await axios.post(`${gatewayURL}customer/update`, body, {
    //     headers: {
    //       cap_authorization: sessionStorage.getItem("token"),
    //       cap_brand: brand,
    //       cap_device_id: deviceId,
    //       cap_identifier_type: identifierType,
    //       cap_identifier_value: mobile,
    //     },
    //   });
    //   return res.data;
    // } catch (error) {
    //   console.log("En Error occurred while updating customer details", error);
    //   throw error;
    // }
    const mobile = sessionStorage.getItem("mobile");
    const url = `${gatewayURL}customer/update`;
    const headers = {
      cap_authorization: sessionStorage.getItem("token"),
      cap_brand: brand,
      cap_device_id: deviceId,
      cap_identifier_type: identifierType,
      cap_identifier_value: mobile,
    };
    return await apiWrapper(url, "POST", body, headers);
  }

  //Get Transaction
  async getTransaction(userid) {
    // try {
    //   const res = await axios.get(
    //     `${gatewayURL}/customer/transactions/get?limit=1000&size=100&points_breakup=true&user_id${userid}`,
    //     {
    //       headers: {
    //         cap_authorization: sessionStorage.getItem("token"),
    //         cap_brand: brand,
    //         cap_device_id: deviceId,
    //         cap_identifier_type: identifierType,
    //         cap_identifier_value: sessionStorage.getItem("mobile"),
    //       },
    //     }
    //   );
    //   return res.data;
    // } catch (err) {
    //   console.log("En error occurred while fetching transaction history", err);
    //   throw err;
    // }
    const url = `${gatewayURL}/customer/transactions/get?limit=1000&size=100&points_breakup=true&user_id${userid}`;
    const headers = {
      cap_authorization: sessionStorage.getItem("token"),
      cap_brand: brand,
      cap_device_id: deviceId,
      cap_identifier_type: identifierType,
      cap_identifier_value: sessionStorage.getItem("mobile"),
    };
    return await apiWrapper(url, "GET", null, headers);
  }

  //get rewards catalog
  async getRewards() {
    // try {
    //   const res = await axios.get(
    //     `${gatewayURL}/marvel/reward/detail?language=en`,
    //     {
    //       headers: {
    //         cap_authorization: sessionStorage.getItem("token"),
    //         cap_brand: brand,
    //         cap_device_id: deviceId,
    //         cap_identifier_type: identifierType,
    //         cap_identifier_value: sessionStorage.getItem("mobile"),
    //       },
    //     }
    //   );
    //   return res.data;
    // } catch (err) {
    //   console.log("En error occurred while fetching rewards", err);
    //   throw err;
    // }
    const url = `${gatewayURL}/marvel/reward/detail?language=en`;
    const headers = {
      cap_authorization: sessionStorage.getItem("token"),
      cap_brand: brand,
      cap_device_id: deviceId,
      cap_identifier_type: identifierType,
      cap_identifier_value: sessionStorage.getItem("mobile"),
    };
    return await apiWrapper(url, "GET", null, headers);
  }

  //get reward vouchers
  async getRewardVouchers() {
    try {
      const res = await axios.get(
        `${gatewayURL}/get/marvel/vouchers?status=true&till_id=valiram.my.brand.01`,
        {
          headers: {
            cap_authorization: sessionStorage.getItem("token"),
            cap_brand: brand,
            cap_device_id: deviceId,
            cap_identifier_type: identifierType,
            cap_identifier_value: sessionStorage.getItem("mobile"),
          },
        }
      );
      return res.data;
    } catch (error) {
      console.log("En Error occurred while fetching reward vouchers", error);
      throw error;
    }
  }

  //get reward vouchers
  async getusermarvelVouchers() {
    // try {
    //   const res = await axios.get(`${gatewayURL}user/rewards`, {
    //     headers: {
    //       cap_authorization: sessionStorage.getItem("token"),
    //       cap_brand: brand,
    //       cap_device_id: deviceId,
    //       cap_identifier_type: identifierType,
    //       cap_identifier_value: sessionStorage.getItem("mobile"),
    //     },
    //   });
    //   return res.data;
    // } catch (error) {
    //   console.log("En Error occurred while fetching rewards", error);
    //   throw error;
    // }
    const url = `${gatewayURL}user/rewards`;
    const headers = {
      cap_authorization: sessionStorage.getItem("token"),
      cap_brand: brand,
      cap_device_id: deviceId,
      cap_identifier_type: identifierType,
      cap_identifier_value: sessionStorage.getItem("mobile"),
    };

    return await apiWrapper(url, "GET", null, headers);
  }

  //get vouchers
  async getVouchers() {
    // try {
    //   const res = await axios.get(`${gatewayURL}customers/coupons`, {
    //     headers: {
    //       cap_authorization: sessionStorage.getItem("token"),
    //       cap_brand: brand,
    //       cap_device_id: deviceId,
    //       cap_identifier_type: identifierType,
    //       cap_identifier_value: sessionStorage.getItem("mobile"),
    //     },
    //   });
    //   return res.data;
    // } catch (error) {
    //   console.log("En Error occurred while fetching vouchers", error);
    //   throw error;
    // }
    const url = `${gatewayURL}customer/coupon/details`;
    const headers = {
      cap_authorization: sessionStorage.getItem("token"),
      cap_brand: brand,
      cap_device_id: deviceId,
      cap_identifier_type: identifierType,
      cap_identifier_value: sessionStorage.getItem("mobile"),
    };

    return await apiWrapper(url, "GET", null, headers);
  }

  //get getRedeemedCoupons
  async getRedeemedCoupons() {
    // try {
    //   const res = await axios.get(`${gatewayURL}customer/redemptions`, {
    //     headers: {
    //       cap_authorization: sessionStorage.getItem("token"),
    //       cap_brand: brand,
    //       cap_device_id: deviceId,
    //       cap_identifier_type: identifierType,
    //       cap_identifier_value: sessionStorage.getItem("mobile"),
    //     },
    //   });
    //   return res.data;
    // } catch (err) {
    //   console.log("En Error occurred while fetching redeemed coupons", err);
    //   throw err;
    // }
    const url = `${gatewayURL}customer/redemptions`;
    const headers = {
      cap_authorization: sessionStorage.getItem("token"),
      cap_brand: brand,
      cap_device_id: deviceId,
      cap_identifier_type: identifierType,
      cap_identifier_value: sessionStorage.getItem("mobile"),
    };
    return await apiWrapper(url, "GET", null, headers);
  }

  //redeem reward/issue
  async redeemReward(rewardId) {
    // try {
    //   const body = {
    //     transactionNumber: "nsannas",
    //     notes: "1 X rewardName",
    //     redemptionTime: "10-01-2024",
    //   };
    //   const res = await axios.post(
    //     `${gatewayURL}marvel/rewards/issue?id=${rewardId}&till_id=${process.env.REACT_APP_tillId}`,
    //     body,
    //     {
    //       headers: {
    //         cap_authorization: sessionStorage.getItem("token"),
    //         cap_brand: brand,
    //         cap_device_id: deviceId,
    //         cap_identifier_type: identifierType,
    //         cap_identifier_value: sessionStorage.getItem("mobile"),
    //       },
    //     }
    //   );
    //   return res.data;
    // } catch (err) {
    //   console.log("En Error occurred while fetching redeemed coupons", err);
    //   throw err;
    // }
    const url = `${gatewayURL}marvel/rewards/issue?id=${rewardId}&till_id=${process.env.REACT_APP_tillId}`;
    const headers = {
      cap_authorization: sessionStorage.getItem("token"),
      cap_brand: brand,
      cap_device_id: deviceId,
      cap_identifier_type: identifierType,
      cap_identifier_value: sessionStorage.getItem("mobile"),
    };
    const body = {
      transactionNumber: "nsannas",
      notes: "1 X rewardName",
      redemptionTime: "10-01-2024",
    };
    return await apiWrapper(url, "POST", body, headers);
  }
  //get nearby stores
  async getNerbyStores() {
    // try {
    //   const res = await axios.get(
    //     `https://apac2-sloc.capillarytech.com/store-locator/v1/brand/nearbystores?distance=1000000&size=100&latitude=37.4219983&page=0&actualDistance=false&longitude=-122.084`,
    //     {
    //       headers: {
    //         cap_authorization: sessionStorage.getItem("token"),
    //         cap_brand: brand,
    //         cap_device_id: deviceId,
    //         cap_mobile: sessionStorage.getItem("mobile"),
    //       },
    //     }
    //   );
    //   return res.data;
    // } catch (error) {
    //   console.log("En Error occurred while fetching reward vouchers", error);
    //   throw error;
    // }
    const url = `https://apac2-sloc.capillarytech.com/store-locator/v1/brand/nearbystores?distance=1000000&size=100&latitude=25.9&page=0&actualDistance=false&longitude=52`;
    const headers = {
      cap_authorization: sessionStorage.getItem("token"),
      cap_brand: brand,
      cap_device_id: deviceId,
      cap_mobile: sessionStorage.getItem("mobile"),
    };
    return await apiWrapper(url, "GET", null, headers);
  }
  async updateTransaction(body) {
    const mobile = sessionStorage.getItem("mobile");
    const url = `${gatewayURL}update/transactions/bulk`;
    const headers = {
      cap_authorization: sessionStorage.getItem("token"),
      cap_brand: brand,
      cap_device_id: deviceId,
      cap_mobile: mobile,
    };

    return await apiWrapper(url, "POST", body, headers);
  }
}

const CustomerServiceInstance = new CustomerService();
export default CustomerServiceInstance;
