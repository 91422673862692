import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Carousel } from "antd";
import { useTranslation } from "react-i18next";
import { useLoadScript } from "@react-google-maps/api";

import CrossIcon from "../../assets/crossIcon.svg";
import PhoneIcon from "../../assets/phone.svg";
import EmailIcon from "../../assets/email.svg";
import Map2 from "../../components/Map2";

const libraries = ["places"];
const gogleMapKey = process.env.REACT_APP_googleMapKey;

export default function StorePage({ store, storeImages }) {
  const [view, setView] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const getUserLocation = () => {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            resolve({ lat: latitude, lng: longitude });
          },
          (error) => {
            reject(error);
          }
        );
      } else {
        reject(new Error("Geolocation not supported"));
      }
    });
  };

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: gogleMapKey,
    version: "weekly",
    libraries,
  });

  const [directions, setDirections] = useState(null);
  const [error, setError] = useState(null);
  // const [loading, setLoading ] = useState(true);

  const destination = { lat: store.latitude, lng: store.longitude };

  const fetchDirections = async () => {
    try {
      // setLoading(true);
      const location = await getUserLocation();
      await getDirections(location, destination);
    } catch (error) {
      console.error("Error fetching user location or directions:", error);
      setError("Unable to retrieve user location or directions.");
    }
    // setLoading(false);
  };

  // Function to get directions using async/await
  const getDirections = async (origin, destination) => {
    const directionsService = new window.google.maps.DirectionsService();

    try {
      // setLoading(true);
      const result = await directionsService.route({
        origin: origin,
        destination: destination,
        travelMode: window.google.maps.TravelMode.DRIVING, // You can also use WALKING, BICYCLING, TRANSIT
      });

      if (result.status === "OK") {
        setDirections(result);
      } else {
        console.error("Error fetching directions:", result);
      }
    } catch (error) {
      console.error("Error with directions service:", error);
      setError("Failed to fetch directions.");
    }
    // setLoading(false);
  };

  useEffect(() => {
    fetchDirections();
    // eslint-disable-next-line
  }, []);
  const weekdays = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  const today = new Date();
  let weekday = today.getDay();
  weekday = (weekday + 6) % 7;

  const getTimings = (timings) => {
    if (!timings) return;
    // Extracting the opening and closing times using regex
    let match = timings.match(/(\d{1,2})(am)- (\d{1,2})(pm)/);
    if (match) {
      let openingHour = parseInt(match[1]);
      let closingHour = parseInt(match[3]) + 12; //(converted to 24-hour format)

      let now = new Date();
      let currentHour = now.getHours(); // Get current hour (24-hour format)

      if (currentHour >= openingHour && currentHour < closingHour) {
        return t("brands.open", { time: "10:00 PM" });
      } else {
        return t("brands.soon", { time: "10:00 AM" });
      }
    }
  };
  useEffect(() => {
    if (Object.keys(store).length === 0) {
      navigate("/brands-store-listing");
    }
    // eslint-disable-next-line
  }, []);

  // if (loading)
  //   return (
  //     <div className="loading-div">
  //       <Spin size="large" />
  //     </div>
  //   );

  return (
    <>
      {view ? (
        <Map2
          setView={setView}
          directions={directions}
          isLoaded={isLoaded}
          error={error}
          view={view}
        />
      ) : (
        <>
          <div className="min-h-dvh">
            <Carousel autoplay>
              {storeImages.map((storeImg, index) => (
                <div key={index} className="h-[30vh] relative">
                  <img
                    src={storeImg}
                    alt="store pic"
                    className="h-full w-100"
                  />
                  <img
                    src={CrossIcon}
                    alt="Cross icon"
                    className="absolute right-2 top-4"
                    onClick={() => navigate(-1)}
                  />
                </div>
              ))}
            </Carousel>
            <div className="p-4 pb-24">
              <div className="obviously pb-3">
                <h3 className="font-bold text-[#111111]">{store?.storeName}</h3>
                <p className="text-xs text-[#666666] mt-6 mb-4">
                  {Object.keys(store?.customFields || {}).length !== 0
                    ? store?.customFields?.address1
                    : "No Address Available"}
                </p>

                {Object.keys(store?.customFields || {}).length !== 0 ? (
                  <div className="flex items-center gap-2">
                    <div
                      className={`w-2 h-2 rounded-full ${
                        store?.customFields?.is_Active
                          ? "bg-[#1CB45D]"
                          : "bg-[#BCBCBC]"
                      }`}
                    ></div>
                    <p
                      className="text-[#666666] "
                      style={{ fontSize: "10px", letterSpacing: "0.5px" }}
                    >
                      {getTimings(store?.customFields?.operating_hours)}
                    </p>
                  </div>
                ) : (
                  <div>No Timing Info Available</div>
                )}
              </div>
              <div className="py-2 border-y-[1px] border-solid border-[#C7C1C1] flex">
                <Button
                  className="bg-[#B78600] rounded-full playfair italic text-white p-4 mr-2"
                  icon={<img src={PhoneIcon} alt="phone icon" />}
                >
                  {Object.keys(store?.customFields || {})?.length !== 0
                    ? "+" + store?.customFields?.store_telephone
                    : "No Number Available"}
                </Button>
                <Button
                  className="bg-[#B78600] rounded-full playfair italic text-white p-4"
                  icon={<img src={EmailIcon} alt="phone icon" />}
                >
                  {Object.keys(store?.customFields || {})?.length !== 0
                    ? store?.customFields?.store_email_address
                    : "No Email Available"}
                </Button>
              </div>
              <div className="obviously text-[#666666] py-4">
                <p className="font-bold text-sm">{t("brands.hours")}</p>
                {Object.keys(store?.customFields || {}).length !== 0 ? (
                  <div className="flex gap-24 text-xs py-6">
                    <div>
                      {weekdays.map((day, index) => (
                        <p
                          key={index}
                          className={`mb-2 ${
                            index === weekday && "text-[#B78600] font-bold"
                          }`}
                        >
                          {day}
                        </p>
                      ))}
                    </div>
                    <div>
                      {weekdays.map((_, index) => (
                        <p
                          key={index}
                          className={`mb-2 ${
                            index === weekday && "text-[#B78600] font-bold"
                          }`}
                        >
                          10:00 - 22:00
                        </p>
                      ))}
                    </div>
                  </div>
                ) : (
                  <div className="py-6">No Info Available</div>
                )}
              </div>
            </div>
          </div>
          <div className="text-center fixed left-[39%] bottom-24">
            <Button
              shape="round"
              className="bg-black text-white border-0 playfair italic btn w-28 h-9"
              onClick={() => setView(!view)}
            >
              {t("brands.direction")}
            </Button>
          </div>
        </>
      )}
    </>
  );
}
