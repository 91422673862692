import React from "react";
import { useNavigate } from "react-router-dom";
import valiramLogo from "../assets/valiram.svg";
import valiramLogo2 from "../assets/valiram2.svg";
import BackIcon from "../assets/backIcon.svg";

export default function Header2({ bgColor, textColor }) {
  const navigate = useNavigate();
  let styles = {};
  if (bgColor && textColor) {
    styles = { ...styles, backgroundColor: bgColor, color: textColor };
  }
  return (
    <div
      className={`flex items-center justify-between pt-6 pb-6 px-3`}
      style={styles}
    >
      <div className="">
        <img
          src={BackIcon}
          alt="back icon"
          className="rounded-full"
          onClick={() => navigate(-1)}
        />
      </div>
      <div>
        <img src={bgColor ? valiramLogo2 : valiramLogo} alt="valiram logo" />
      </div>
    </div>
  );
}
