import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Input, Modal, Spin } from "antd";
import { useTranslation } from "react-i18next";
import "./styles.css";
import backIcon from "../../assets/backIcon.svg";
import backgroundVideo from "../../assets/videos/background-video.mp4";
import eyeIcon from "../../assets/eyebrow.svg";
import AuthServiceInstance from "../../utils/auth-service";
import eyeOpenIcon from "../../assets/open-eye.svg";
// const lngs = [
//   ["en", "English"],
//   ["ms", "Malay"],
// ];

function UpdatePassword({ setShowNav }) {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [pswd, setPswd] = useState("");
  const [pswd1, setPswd1] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const videoRef = useRef(null);
  const [videoLoaded, setVideoLoaded] = useState(false);

  const navigate = useNavigate();

  // const handleChange = (value) => {
  //   i18n.changeLanguage(value);
  // };

  const handleToggle = () => {
    setShowPassword((prev) => !prev);
  };

  const handleToggle1 = () => {
    setShowPassword1((prev) => !prev);
  };

  useEffect(() => {
    if (pswd1 && pswd !== pswd1) {
      setError("Passwords do not match.");
    } else {
      setError(""); // Reset error if all checks pass
    }
  }, [pswd, pswd1]);
  const handleChange = (e) => {
    const { name, value } = e.target;

    // Update the state based on which field is being changed
    if (name === "password") {
      setPswd(value.replace(/\s/g, ""));
    } else if (name === "confirmPassword") {
      setPswd1(value.replace(/\s/g, ""));
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const passwordCriteria =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    if (!pswd || !pswd1) {
      setError("Password is required");
      return;
    } else if (!passwordCriteria.test(pswd)) {
      setError(
        "Password must be at least 8 characters long, include an uppercase letter, a lowercase letter, a number, and a special character."
      );
      return;
    }

    setLoading(true);
    try {
      const tokenRes = await AuthServiceInstance.tokenGenerate(pswd);
      if (tokenRes.status.success) {
        // console.log(!tokenRes.user.userRegisteredForPassword);
        sessionStorage.setItem("sessionId", tokenRes?.user?.sessionId);

        // if (tokenRes.user.userRegisteredForPassword) {
        //   sessionStorage.setItem("updatePassword", true);
        // }

        const updateStatus = sessionStorage.getItem("crm") === "true";
        if (updateStatus) {
          navigate("/terms&condition");
        } else {
          try {
            setLoading(true);
            const res = await AuthServiceInstance.forgotPassword(pswd);
            if (!res.status.success) {
              Modal.error({
                title: (
                  <span className="playfair text-md text-white">Error</span>
                ),
                content: (
                  <span className="playfair text-white">
                    {res?.status?.message}
                  </span>
                ),
                style: {
                  top: "50%",
                  transform: "translateY(-50%)",
                },
                className: "custom-modal",
              });
            } else {
              sessionStorage.setItem("updatePassword", true);
              navigate("/signup2");
            }
          } catch (err) {
            console.log(err);
          } finally {
            setLoading(false);
          }
        }
      } else {
        setError(tokenRes.status.message);
        console.log(tokenRes.status.message);
      }
    } catch (tokenErr) {
      console.log("Token Generation Failed", tokenErr.message);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // const video = document.querySelector(".qVaWx1ia4MK_oyPXz791");
    const video = videoRef.current;
    if (video) {
      video.load();
      video.play().catch((error) => {
        console.log("Video play failed:", error);
      });
    }
    setVideoLoaded(true);
    return () => {
      // Optionally store the current video state before unmounting
      if (video) {
        setVideoLoaded(false);
      }
    };
  }, []);

  if (loading)
    return (
      <div className="loading-div">
        <Spin size="large" />
      </div>
    );
  return (
    <div className="updatePassword-container">
      {/* <video autoPlay muted loop className="background-video">
        <source src={backgroundVideo} type="video/mp4" />
      </video> */}
      <div className="video-background">
        <video
          className="qVaWx1ia4MK_oyPXz791"
          src={backgroundVideo}
          preload="auto"
          autoPlay={videoLoaded}
          ref={videoRef}
          loop
          muted
          playsInline
        ></video>
      </div>
      {/* <video id="background-video" class="qVaWx1ia4MK_oyPXz791" src={backgroundVideo} preload="auto" autoPlay loop muted playsinline></video> */}
      <div className="back-button-container justify-between items-center">
        <button className="back-button" onClick={() => navigate(-1)}>
          <img src={backIcon} alt="back-button"></img>
        </button>
        {/* <Select
          defaultValue={i18n.resolvedLanguage}
          style={{ width: 150 }}
          onChange={handleChange}
          options={lngs.map((lng) => {
            return { value: lng[0], label: lng[1] };
          })}
        /> */}
      </div>
      <div className="overlay"></div>
      <form className="updatePassword-content" onSubmit={handleSubmit}>
        <h1 className="title">{t("updatePassword.title")}</h1>
        <p className="subtitle">{t("updatePassword.desc")}</p>
        <div className="relative">
          <Input
            type={showPassword ? "text" : "password"}
            placeholder={t("updatePassword.placeholder1")}
            className="input-field"
            value={pswd}
            onChange={handleChange}
            name="password"
          />
          <button
            type="button"
            onClick={handleToggle}
            className="toggle-button"
          >
            {!showPassword ? (
              <img src={eyeIcon} alt="eye icon"></img>
            ) : (
              <img src={eyeOpenIcon} alt="eye close icon"></img>
            )}
          </button>
        </div>
        <div className="relative">
          <Input
            type={showPassword1 ? "text" : "password"}
            placeholder={t("updatePassword.placeholder2")}
            className="input-field"
            value={pswd1}
            name="confirmPassword"
            onChange={handleChange}
          />
          <button
            type="button"
            onClick={handleToggle1}
            className="toggle-button"
          >
            {!showPassword1 ? (
              <img src={eyeIcon} alt="eye icon"></img>
            ) : (
              <img src={eyeOpenIcon} alt="eye icon"></img>
            )}
          </button>
        </div>
        {/* {error && (
          <p className="text-red-700 text-center w-full px-2">{error}</p>
        )} */}
        {error && (
          <p
            style={{
              color: "white",
              textAlign: "center",
              fontFamily: "Obviously",
            }}
            className="text-[10.5px] leading-3 mb-3"
          >
            <span
              style={{
                borderBottom: "2px solid #A00808",
                textDecoration: "none",
              }}
            >
              {error}
            </span>
          </p>
        )}
        <div className="mt-24 flex justify-center items-center">
          <button type="submit" className="button" disabled={!!error}>
            {t("updatePassword.save")}
          </button>
        </div>
      </form>
    </div>
  );
}

export default UpdatePassword;
