import React from "react";
import Sparkles from "../assets/sparkles.svg";
import Voucher from "../assets/voucher.svg";
import { TruncateSentence } from "../utils/truncate-func";
import { format, parseISO } from "date-fns";

export default function Reward({
  item,
  setOpen,
  sparkles,
  redeemed,
  tab,
  setReward,
  rewardDetail,
  open,
}) {
  const convertDateFormat = (dateString) => {
    // Parse the date string to a Date object
    const parsedDate = parseISO(dateString.replace(" ", "T")); // Replacing space with 'T' for ISO compliance
    // Format the date to the desired format
    return format(parsedDate, "dd/MM/yyyy");
  };

  const handleOpenDrawerClick = () => {
    if ((sparkles && !redeemed) || (Voucher && !redeemed)) {
      setOpen(true);
      tab === "rewards"
        ? setReward({ ...item, ...rewardDetail })
        : setReward(item);
    }
  };
  if (tab === "rewards" && item.redemptionCount !== 0) {
    return null;
  }

  return (
    <div
      className={`${redeemed ? "bg-[#4D4D4D]" : "bg-[#002771]"} ${
        redeemed ? "text-[#DDDDDD]" : "text-white"
      } rounded-2xl p-3 pt-3 flex mb-3 obviously`}
      onClick={handleOpenDrawerClick}
    >
      <div className="py-2 flex items-center justify-center">
        <img
          src={sparkles ? Sparkles : Voucher}
          // src={item.imageUrl}
          alt="sparkles icon"
          width={30}
        />
      </div>
      <div className="pl-4 w-full">
        <div className="flex justify-between items-center ">
          <p
            className={`${
              redeemed ? "text-[#DDDDDD]" : "text-white"
            } text-xs font-bold`}
          >
            {tab === "rewards"
              ? item?.seriesName
              : tab === "redeemed"
              ? item?.code
              : item?.code}
          </p>
          <p
            className={`playfair text-sm ${
              redeemed ? "text-[#DDDDDD]" : "text-white"
            }`}
            style={{ fontSize: "12px" }}
          >
            {tab === "rewards"
              ? rewardDetail?.label
              : tab === "redeemed"
              ? item?.label
              : item?.customPropertyMap?.Brand}
          </p>
        </div>
        <div className="text-left pt-2 pb-1">
          <p style={{ fontSize: "8px" }}>
            {tab === "rewards"
              ? !open
                ? TruncateSentence(rewardDetail?.description, 80)
                : TruncateSentence(item?.description, 80)
              : item?.description}
          </p>
        </div>
        <div className="flex items-center gap-2 pt-1">
          <div
            className={`w-2 h-2 rounded-full ${
              redeemed ? "bg-[#A7A7A7]" : "bg-[#289A19]"
            }`}
          ></div>
          <p style={{ fontSize: "8px" }}>
            VALID UNTIL :{" "}
            {tab === "rewards"
              ? convertDateFormat(item?.expiry)
              : tab === "redeemed"
              ? convertDateFormat(item.redeemed_time)
              : convertDateFormat(item?.validTill)}
          </p>
        </div>
      </div>
    </div>
  );
}
