import React, { useEffect, useState } from "react";
import { Button, Drawer, Spin } from "antd";
import { useNavigate } from "react-router-dom";
// import { useTranslation } from "react-i18next";
import crossIcon from "../../assets/crossIcon.svg";
import "./styles.css";
import Header2 from "../../components/Header2";
import LiveChat from "../../assets/live-chat.svg";

export default function CustomerSupport() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  //   const { t } = useTranslation();
  const cleanupFreshChat = () => {
    if (window?.fcWidget) {
      window?.fcWidget?.destroy();
    } else {
      // console.log("FreshChat widget is not initialized.");
    }
  };
  useEffect(() => {
    return () => {
      cleanupFreshChat();
    };
  }, []);

  const handleClick = () => {
    setLoading(true);
    initialize(document, "Freshchat-js-sdk");

    function initFreshChat() {
      window?.fcWidget?.init({
        token: "1373566b-dc9c-4fc0-8b14-2150305d1a5b",
        host: "https://luxuryventurespteltd.freshchat.com",
        widgetUuid: "b75dbe03-7e11-4d2f-be6d-595e103ce77e",
      });
      setLoading(false);
    }

    function initialize(i, t) {
      if (i.getElementById(t)) {
        initFreshChat();
      } else {
        const e = i.createElement("script");
        e.id = t;
        e.async = true;
        e.src = "https://luxuryventurespteltd.freshchat.com/js/widget.js";
        e.onload = initFreshChat;
        i.head.appendChild(e);
      }
    }

    // function initiateCall() {

    //   initialize(document, "Freshchat-js-sdk");
    // }

    // if (window.addEventListener) {
    //   window.addEventListener("load", initiateCall, false);
    // } else {
    //   window.attachEvent("onload", initiateCall);
    // }
  };
  useEffect(() => {
    // Disable scrolling when modal is open
    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [open]);
  const onClose = () => {
    setOpen((open) => !open);
  };
  if (loading)
    return (
      <div className="loading-div">
        <Spin size="large" />
      </div>
    );
  return (
    <div className="min-h-dvh bg-white">
      <div className="pl-2">
        <Header2 />
      </div>
      <div className="obviously py-3 text-xl pl-5 mb-4">
        <h2>Submit Ticket</h2>
      </div>
      <div className="px-3">
        <div className="flex flex-col justify-center items-center gap-4 obviously pt-20">
          <Button
            className="w-4/5 h-10 shadow-[0_0_3px_#00000080] rounded-full font-bold"
            onClick={onClose}
          >
            GENERAL FEEDBACK
          </Button>
          <Button
            className="w-4/5 h-10 shadow-[0_0_3px_#00000080] rounded-full font-bold"
            // onClick={() => navigate("/customer-feedback-form")}
          >
            TRANSACTION
          </Button>
          <Button
            className="w-4/5 h-10 rounded-full font-bold bg-[#B78600] text-white"
            // onClick={() => navigate("/customer-feedback-form")}
          >
            VIEW MY CASES
          </Button>
        </div>
        <div className="text-center py-8 px-4">
          <p className="playfair italic text-sm">
            Submit your general feedback and transactions dispute here, our
            friendly customer service will get back to you within operating
            hours.
          </p>
        </div>
        <div className="flex flex-col gap-2 justify-center items-center py-16 mb-10">
          <img src={LiveChat} alt="live chat icon" onClick={handleClick} />
          <p className="obviously">Live Chat Available Now!</p>
        </div>
      </div>
      <Drawer
        placement="bottom"
        closable={false}
        onClose={onClose}
        open={open}
        getContainer={false}
        maskClassName={true}
        width={900}
        // style={{ height: "115%" }}
        // rootStyle={{ width: "94%", margin: "0 auto", outline: "none" }}
        // height="90.5vh"
        // // borderRadius="1rem"
        rootClassName="hell"
        style={{
          borderTopLeftRadius: 20,
          borderTopRightRadius: 20,
          position: "fixed",
          bottom: 0,
          left: "50%",
          right: 0,
          zIndex: 10,
          width: "92%",
          height: "96.5%",
          transform: "translateX(-50%)",
          transition: "all 0.3s ease",
          boxShadow: "0px 0px 25px 25px #d3d3d3",
        }}
        rootStyle={{ width: "96%", margin: "0 auto", outline: "none" }}
        // height="98.5vh"
        borderRadius="1rem"
        styles={{
          body: { backgroundColor: "#fff" },
          mask: { pointerEvents: "auto" },
        }}
        // className="drawerOverlay rounded-t-2xl rounded-tr-2xl"
        maskClosable={false}
      >
        <div className="bg-white h-full pt-4" style={{ borderRadius: "1rem" }}>
          <div className="absolute  right-2 top-2 ">
            <img src={crossIcon} alt="cross icon" onClick={onClose} />
          </div>

          <div className="h-4/5 py-3">
            <iframe
              className="h-full w-full"
              src="https://valiram.com/app/loyalty/loyalty.php"
              title="ticket website"
            ></iframe>
          </div>
        </div>
      </Drawer>
    </div>
  );
}
