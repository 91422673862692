import React, { useEffect, useRef, useState } from "react";
import { Button, Drawer, Spin } from "antd";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

import customerService from "../../utils/customer-service";

import "./styles.css";
import Transaction from "../../components/Transaction";
import Header2 from "../../components/Header2";
import Recipt from "../../components/Recipt";
import Down from "../../assets/down2.svg";
import DownloadIcon from "../../assets/progress-download.png";
// import Ratings from "../../assets/svg/ratings.svg";
import Good from "../../assets/good.png";
import Bad from "../../assets/bad.png";
import Ugly from "../../assets/ugly.png";
import ritualImg from "../../assets/ritual.svg";
import Filters from "../../components/Filters";
import { getTransactionCategory } from "../../APIs/api";

const ratings = [Good, Bad, Ugly];
const ratinfStr = ["Good", "Bad", "Ugly"];

export default function PointsHistory() {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const userid = useSelector((store) => store?.customerDetails?.customerData);
  const [transactionDetails, setTransactionDetails] = useState([]);
  const [transaction, setTransaction] = useState({});
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState(null);
  const [userName, setUserName] = useState(null);
  const [categories, setCategories] = useState([]);

  const [renderedTransactions, setRenderedTransactions] =
    useState(transactionDetails);
  const lineItems = transaction?.line_items?.line_item;
  const contentRef = useRef();

  useEffect(() => {
    async function getTransactionCategoryFunc() {
      const dataObj = await getTransactionCategory();
      const dataArray = dataObj;

      if (dataArray !== undefined) {
        setCategories(dataArray);
      } else {
        //brandopt
      }
    }
    getTransactionCategoryFunc();
    // eslint-disable-next-line
  }, []);

  const handleRatings = async (index) => {
    const rate = ratinfStr[index];
    const transactionId = transaction?.id;

    const body = [
      {
        identifierType: "mobile",
        identifierValue: sessionStorage.getItem("mobile"),
        source: "INSTORE",
        id: transactionId,
        customFields: {
          store_experience: rate,
        },
      },
    ];

    const res = await customerService.updateTransaction(body);
    if (res?.response[0]) {
      const newCustomFields = res?.response[0]?.result.customFields;

      const updatedTransaction = {
        ...transaction,
        custom_fields: {
          field: [
            {
              name: "store_experience",
              value: newCustomFields.store_experience || rate,
            },
          ],
        },
      };
      setTransaction(updatedTransaction);
      setOpen(false);
      fetchTransactionDetails();
    }
  };

  // const handleDownloadPdf = () => {
  //   const input = contentRef.current;
  //   html2canvas(input)?.then((canvas) => {
  //     const imgData = canvas.toDataURL("image/png");
  //     const pdf = new jsPDF();
  //     // Get image width and height
  //     const imgWidth = canvas?.width;
  //     const imgHeight = canvas?.height;

  //     // Get PDF page size (default A4 page)
  //     const pdfWidth = pdf?.internal?.pageSize?.getWidth();
  //     const pdfHeight = pdf?.internal?.pageSize?.getHeight();

  //     // Calculate X and Y offset to center the image
  //     const xOffset = (pdfWidth - imgWidth * 0.264583) / 2; // 0.264583 is conversion from px to mm (since jsPDF uses mm units)
  //     const yOffset = (pdfHeight - imgHeight * 0.264583) / 2;

  //     // Add the image to the PDF centered
  //     pdf.addImage(
  //       imgData,
  //       "JPEG",
  //       xOffset,
  //       yOffset,
  //       imgWidth * 0.264583,
  //       imgHeight * 0.264583
  //     );
  //     // pdf.addImage(imgData, "JPEG", 0, 0);
  //     const timestamp = new Date().toISOString().replace(/:/g, "-");
  //     pdf.save(`download-${timestamp}.pdf`);
  //   });
  // };
  const handleDownloadPdf = () => {
    const input = contentRef.current;
    html2canvas(input, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      const imgWidth = canvas.width;
      const imgHeight = canvas.height;

      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();

      const xOffset = (pdfWidth - imgWidth * 0.264583) / 2;
      const yOffset = (pdfHeight - imgHeight * 0.264583) / 2;

      // Check if content fits within a single page
      if (imgHeight * 0.264583 > pdfHeight) {
        const numberOfPages = Math.ceil((imgHeight * 0.264583) / pdfHeight);
        for (let i = 0; i < numberOfPages; i++) {
          pdf.addImage(
            imgData,
            "JPEG",
            xOffset,
            yOffset - pdfHeight * i,
            imgWidth * 0.264583,
            imgHeight * 0.264583
          );
          if (i < numberOfPages - 1) {
            pdf.addPage();
          }
        }
      } else {
        pdf.addImage(
          imgData,
          "JPEG",
          xOffset,
          yOffset,
          imgWidth * 0.264583,
          imgHeight * 0.264583
        );
      }

      const timestamp = new Date().toISOString().replace(/:/g, "-");
      pdf.save(`download-${timestamp}.pdf`);
    });
  };

  useEffect(() => {
    fetchTransactionDetails();
    // eslint-disable-next-line
  }, []);

  const fetchTransactionDetails = async () => {
    try {
      setLoading(true);
      const transRes = await customerService.getTransaction(userid);
      if (transRes?.status?.success === "true") {
        setUserId(transRes?.customer?.user_id);
        setUserName(transRes?.customer?.firstname);
        setTransactionDetails(transRes?.customer?.transactions?.transaction);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  const ratingStyle = (index) => {
    const rate = ratinfStr[index];

    if (transaction) {
      return {
        opacity:
          rate ===
          transaction?.custom_fields?.field?.find(
            (field) => field?.name === "store_experience"
          )?.value
            ? 1
            : 0.3,
      };
    }
  };
  useEffect(() => {
    // Disable scrolling when modal is open
    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [open]);
  const showDrawer = (transaction) => {
    setOpen(true);
    // console.log(transaction);
    // console.log(lineItems);

    setTransaction(transaction);
  };

  const onClose = () => {
    setOpen(false);
  };
  if (loading || (loading && renderedTransactions?.length === 0))
    return (
      <div className="loading-div">
        <Spin size="large" />
      </div>
    );

  return (
    <>
      <div className="p-2">
        <Header2 />
        <div className="obviously py-2 text-lg pl-3 pb-6">
          <h2>{t("pointsHistory.title")}</h2>
        </div>
        <Filters
          categoryList={categories}
          setRenderedBrands={setRenderedTransactions}
          brands={transactionDetails}
        />
        {/* <Filters /> */}
        <div className="shadow-[0_3px_6px_#00000080] overflow-scroll rounded-xl h-[80vh] mb-24 bg-white">
          {renderedTransactions?.length > 0 ? (
            renderedTransactions?.map((trs) => (
              <Transaction
                key={trs.id}
                transaction={trs}
                onClick={() => showDrawer(trs)}
              />
            ))
          ) : (
            <div className="flex items-center justify-center flex-col">
              <img src={ritualImg} alt="ritual-img"></img>
              <p className="text-2xl text-center  font-[400] italic">
                No Transaction found!
              </p>
            </div>
          )}
        </div>
      </div>
      <Drawer
        placement="bottom"
        closable={false}
        onClose={onClose}
        open={open}
        getContainer={false}
        maskClassName="bg-no"
        width={900}
        // style={{ borderRadius: "1rem" }}
        rootStyle={{ width: "90%", margin: "0 auto", outline: "none" }}
        height="98.5vh"
        // borderRadius="1rem"
        rootClassName="hello"
        maskClosable={false}
        className="rounded-t-2xl "
        style={{
          borderTopLeftRadius: 20,
          borderTopRightRadius: 20,
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 10,
          width: "95%",
          height: "97%",
          transform: "translate(10px, 0px)",
          transition: "all 0.3s ease",
        }}
      >
        {/* styles={{ body: { marginBottom: "8rem" } }} */}
        <h1 className="text-3xl text-[#303445] obviously tracking-tighter">
          #{transaction?.id}
        </h1>
        <Transaction transaction={transaction} onModel onClick={() => {}} />
        <Recipt
          transaction={transaction}
          userId={userId}
          userName={userName}
          lineItems={lineItems}
          ref={contentRef}
        />
        <div className="text-center mb-8">
          <Button
            type="primary"
            shape="round"
            icon={<img src={DownloadIcon} alt="Download icon" />}
            className="bg-[#B78600] h-10 px-4 w-2/3"
            onClick={handleDownloadPdf}
          >
            <p className="text-xs">{t("pointsHistory.download")}</p>
          </Button>
        </div>
        <div className="text-center mb-4">
          <p className="playfair italic text-[#222222] w-2/3 mx-auto">
            {t("pointsHistory.rating")}{" "}
          </p>
        </div>
        <div className="flex justify-center mb-6">
          {ratings.map((val, index) => (
            <img
              key={index}
              src={val}
              alt="ratings icon"
              width={40}
              onClick={() => handleRatings(index)}
              style={ratingStyle(index)}
            />
          ))}
        </div>
        <div className="flex justify-center mb-10">
          <img src={Down} alt="down icon" onClick={onClose} />
        </div>
      </Drawer>
    </>
  );
}
